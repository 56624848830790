<template>
  <v-container class="py-4">
    <v-row class="justify-content-center">
      <v-col>
        <v-progress-linear
          indeterminate
          color="green"
          v-show="loading"
        ></v-progress-linear>
        <h1 class="text-center"> Сообщения </h1>
      </v-col>
    </v-row>
    <v-row class="justify-content-center">
      <v-col class="text-center">
        <h3>Для ближайших</h3>
        <v-textarea
          solo
          name="input-7-4"
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row class="justify-content-center">
      <v-col class="text-center">
        <h3>Для недоступных</h3>
        <v-textarea
          solo
          name="input-7-4"
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row class="justify-content-center">
      <v-col class="text-center">
        <v-btn>Сохранить</v-btn>
      </v-col>
      <v-col class="text-center">
        <v-btn>Отмена</v-btn>
      </v-col>
      <v-col class="text-center">
        <v-btn :to="{ name: 'Delivery' }">Назад</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from 'vue'
export default Vue.extend(
  {
    data () {
      return {
        loading: false
      }
    }
  }
)
</script>

<style>

</style>
