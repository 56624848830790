<template>
  <div class="inputWrap" style="width: 250px;display: flex;">
    <v-icon class="mr-2" style="font-size:50px" v-on:click="change(-1)">keyboard_arrow_left</v-icon>
    <v-menu ref="menu" :close-on-content-click="true" v-model="menu" :nudge-top="100"
        offset-y min-width="150px">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            :id="pickerInputId"
            style="width: 150px;"
            solo
            v-model="visibleDate"
            readonly
            v-bind="attrs"
            v-on="on"
            hide-details
            flat
          ></v-text-field>
        </template>
        <v-date-picker v-if="unit !== 'HOUR' && unit !== 'MINUTE'"
            first-day-of-week=1
            :id="pickerId"
            reactive
            show-current
            ref="picker"
            v-model="computedDate"
            no-title
            :width="width"
            :type="type"
            :min="min"
            :max="max"
            >
        </v-date-picker>
        <v-time-picker v-if="(unit === 'HOUR' || unit === 'MINUTE') && menu"
          no-title
          ref="timepicker"
          v-model="computedDate"
          format="24hr"
          @click:hour="closeHourPicker($event)"
        ></v-time-picker>
    </v-menu>
    <v-icon class="ml-2" style="font-size:50px" v-on:click="change(1)">keyboard_arrow_right</v-icon>
  </div>
</template>

<script>
export default {
  props: {
    unit: {
      type: String
    },
    type: {
      type: String
    },
    width: {
      type: String
    },
    min: {
      type: String || null
    },
    max: {
      type: String || null
    },
    year: {
      type: String || null
    },
    month: {
      type: String || null
    }
  },
  data: () => ({
    dialog: false,
    date: null,
    menu: false,
    modal: false,
    monthName: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь']
  }),
  mounted () {
    if (this.unit === 'YEAR') {
      this.date = new Date().toISOString().substr(0, 4)
    }
    if (this.unit === 'MONTH') {
      this.date = new Date().toISOString().substr(5, 2)
    }
    if (this.unit === 'DAY') {
      this.date = new Date().toISOString() // нужна полная дата для компонента
    }
    if (this.unit === 'HOUR') {
      this.date = '12:00'
    }
    if (this.unit === 'MINUTE') {
      this.date = '00:00'
    }
  },
  watch: {
    menu (val) {
      if (this.unit === 'YEAR') {
        val && this.$nextTick(() => (this.$refs.picker.activePicker = 'YEAR'))
      }
      if (this.unit === 'MONTH') {
        val && this.$nextTick(() => (this.$refs.picker.activePicker = 'MONTH'))
      }
      if (this.unit === 'MINUTE') {
        val && this.$nextTick(() => (this.$refs.timepicker.selecting = 2)) // 1 - hour, 2 - minutes, 3 - seconds
      }
    },
    date (val) {
      if (this.unit === 'DAY') {
        val = val.substr(8, 2)
      }
      if (this.unit === 'MONTH' && val.length === 1) {
        val = '0' + val
      }
      if (this.unit === 'HOUR') {
        val = val.substr(0, 2)
      }
      if (this.unit === 'MINUTE') {
        val = val.substr(3, 5)
      }
      this.$emit('changeFromChild', { unit: this.unit, val: val })
    },
    year (val) {
      if (this.unit === 'DAY') {
        this.date = val + this.date.substr(4, 19)
      }
    },
    month (val) {
      if (this.unit === 'DAY') {
        this.date = this.date.substr(0, 5) + val + this.date.substr(7, 19)
      }
    }
  },
  computed: {
    visibleDate () {
      let result
      if (this.unit === 'YEAR') {
        result = this.date
      }
      if (this.unit === 'MONTH') {
        result = this.monthName[this.date - 1]
      }
      if (this.unit === 'DAY') {
        result = this.date ? this.date.substr(8, 2) : null
      }
      if (this.unit === 'HOUR') {
        result = this.date ? this.date.substr(0, 2) : null
      }
      if (this.unit === 'MINUTE') {
        result = this.date ? this.date.substr(3, 2) : null
      }
      return result
    },
    computedDate: {
      get: function () {
        return this.date
      },
      set: function (newValue) {
        if (this.unit === 'YEAR') {
          this.date = newValue.slice(0, -8)
        }
        if (this.unit === 'MONTH') {
          this.date = newValue.slice(-2)
        }
        if (this.unit === 'DAY') {
          this.date = newValue
        }
        if (this.unit === 'HOUR') {
          this.date = newValue
        }
        if (this.unit === 'MINUTE') {
          this.date = newValue
        }
      }
    },
    pickerId () {
      return 'picker' + this.unit
    },
    pickerInputId () {
      return 'pickerInput' + this.unit
    }
  },
  methods: {
    closeHourPicker (v) {
      v = v < 10 ? '0' + v : v
      this.date = v + ':00'
      this.menu = false
    },
    change (direction) {
      let min, max
      if (this.unit === 'YEAR') {
        min = Number(this.min)
        max = Number(this.max)
      } else if (this.unit === 'MONTH') {
        min = 1
        max = 12
      }
      if (this.unit === 'DAY') {
        const newDateToAssign = this.addDays(this.date, direction)
        if (newDateToAssign.toISOString().substr(5, 2) === this.date.substr(5, 2)) {
          this.date = newDateToAssign.toISOString()
        }
      } else if (this.unit === 'HOUR') {
        const dt = new Date()
        dt.setHours(Number(this.visibleDate) + direction)
        const newDate = new Date(dt.getTime() - (dt.getTimezoneOffset() * 60000)).toISOString().substr(11, 2)
        this.date = newDate + ':00'
      } else if (this.unit === 'MINUTE') {
        const newDate = new Date()
        direction = direction * 5
        newDate.setMinutes(Number(this.visibleDate) + direction)
        this.date = '00:' + newDate.toISOString().substr(14, 2)
      } else {
        const newDate = Number(this.date) + Number(direction)
        if (newDate >= min && newDate <= max) {
          this.date = newDate.toString()
        }
      }
    },
    addDays (date, days) {
      const result = new Date(date)
      result.setDate(result.getDate() + days)
      return result
    }
  }
}
</script>
