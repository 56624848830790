





































































import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import LoadingBar from '@/components/partials/components/LoadingBar.vue'
import Modal from '@/components/food/foodOrdersArchive/Modal.vue'
import { getFoodOrdersByPeriodAndStatusApi, updateFoodOrderStatusUrlApi } from '@/store/orders/api-requests'

export type AllTypes = {
  loading: boolean;
  statuses: any;
  headers: any;
  data: any;
  filteredData: any;
  checkbox: boolean;
  refreshDate: any;
  menuDateFrom: boolean;
  menuDateTo: boolean;
  dateFrom: any;
  dateTo: any;
  dialog: boolean;
  page: number;
  itemsPerPage: number;
}

export default Vue.extend({
  components: {
    LoadingBar,
    Modal
  },
  created () {
    setInterval(() => this.refresh(), 5000)
  },
  data (): AllTypes {
    return {
      page: 1,
      itemsPerPage: 20,
      loading: false,
      statuses: [
        { id: 7, name: 'Выполнен', color: 'successStatus' }, // todo состыковать id с полученным и запрашивать по id, взять из FoodOrder
        { id: 3, name: 'Отменен', color: 'dangerStatus' },
        { id: 10, name: 'Отменен', color: 'dangerStatus' }
      ],
      headers: [
        { text: 'Номер', align: 'center', value: 'id', sortable: false },
        { text: 'Дата / время', align: 'center', value: 'creationDate', sortable: false },
        { text: 'Сектор / ряд / место', align: 'center', value: 'place', sortable: false },
        { text: 'Оператор', align: 'center', value: 'operator', sortable: false }
      ],
      data: [],
      filteredData: [],
      checkbox: true,
      refreshDate: '...',
      menuDateFrom: false,
      menuDateTo: false,
      dateFrom: new Date().toISOString().substr(0, 10),
      // dateFrom: '2021-01-01',
      dateTo: new Date().toISOString().substr(0, 10),
      dialog: false
    }
  },
  methods: {
    showCurrentDateItems (el: any) {
      this.dateFrom = el.substr(0, 10)
      this.dateTo = el.substr(0, 10)
      this.getData()
    },
    showYesterdayItems () {
      const dateFrom = new Date()
      dateFrom.setDate(dateFrom.getDate() - 1)
      this.dateFrom = dateFrom.toISOString().substr(0, 10)
      const dateTo = new Date()
      dateTo.setDate(dateTo.getDate() - 1)
      this.dateTo = dateTo.toISOString().substr(0, 10)
      this.getData()
    },
    showTodayItems () {
      const dateFrom = new Date()
      // dateFrom.setDate(dateFrom.getDate() - 1)
      const dateTo = new Date()
      // dateTo.setDate(dateTo.getDate() + 1)
      this.dateFrom = dateFrom.toISOString().substr(0, 10)
      this.dateTo = dateTo.toISOString().substr(0, 10)
      this.getData()
    },
    nextPage () {
      if (this.page + 1 <= this.numberOfPages) this.page += 1
    },
    prewPage () {
      if (this.page - 1 >= 1) this.page -= 1
    },
    getInitialSearchTime () {
      const date = this.getNowTime()
      // this.dateFrom = date.toISOString().substr(0, 10)
      this.dateFrom = '2021-01-01'
      const date2 = new Date()
      date2.setTime(date2.getTime() + (27 * 60 * 60 * 1000))
      this.dateTo = date2.toISOString().substr(0, 10)
    },
    getNowTime () {
      const date = new Date()
      date.setTime(date.getTime() + (3 * 60 * 60 * 1000))
      return date
    },
    refresh () {
      const date = this.getNowTime()
      const y = date.toISOString().substr(0, 10).replace(/-/g, '.')
      const d = date.toISOString().substr(11, 8)
      this.refreshDate = y + ' ' + d
    },
    itemRowBackground: function (item: any) {
      return this.statuses.find((st: any) => st.id === Number(item.status)).color
      // console.log(item.status)
      // return 1
    },
    async getData () {
      this.loading = true
      let result
      try {
        result = await getFoodOrdersByPeriodAndStatusApi({ dateFrom: this.dateFrom, dateTo: this.dateTo, status: 7 })
        const result2 = await getFoodOrdersByPeriodAndStatusApi({ dateFrom: this.dateFrom, dateTo: this.dateTo, status: 3 })
        const result3 = await getFoodOrdersByPeriodAndStatusApi({ dateFrom: this.dateFrom, dateTo: this.dateTo, status: 10 })
        result = result.concat(result2, result3)
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
        if (result) {
          this.data = JSON.parse(JSON.stringify(result))
          this.data = this.data.map((el: any) => {
            el.place = 'Без доставки '
            el.operator = ' '
            if (el.orderDetail.length > 0) {
              if (Number(el.orderDetail[0].deliveryTypeId) === 1) {
                el.place = el.orderDetail[0].deliveryPlace
              }
              if (el.orderDetail[0].foodCourtId) {
                el.operator = el.orderDetail[0].foodCourtId
              }
            }
            return el
          })
        }
      }
    },
    openModal () {
      this.dialog = true
    },
    closeModal (ev: any) {
      this.dialog = false
    },
    goToOrder (id: any) {
      this.$router.push({ name: 'FoodOrder', params: { id: id } })
    },
    back () {
      this.$router.push({ name: 'FoodEnter' })
    }
  },
  mounted () {
    this.getInitialSearchTime()
    this.getData()
  },
  computed: {
    ...mapGetters({ user: 'user/user' }),
    numberOfPages (): any {
      return Math.ceil(this.data.length / this.itemsPerPage)
    }
  }
})
