/* eslint-disable @typescript-eslint/no-unused-vars */

import { ActionContext, ActionTree, GetterTree, MutationTree } from 'vuex'
import { ADD_ADVITEM_TO_STACK } from './mutations'
import { RootState } from '@/store/state'
import { AdvItemsState } from './types'
import { show as InformationShow } from './api-requests'
import { parseAxiosError } from '@/services/api'

type InformationContext = ActionContext<AdvItemsState, RootState>

const initialState: AdvItemsState = {
  advItems: []
}

const getters: GetterTree<AdvItemsState, RootState> = {
  //
}

const mutations: MutationTree<AdvItemsState> = {
  [ADD_ADVITEM_TO_STACK] (state: AdvItemsState, informationResponse) {
    state.advItems = informationResponse
  }
}

const actions: ActionTree<AdvItemsState, RootState> = {
  async show ({ commit, dispatch, state }: InformationContext) {
    try {
      const result = await InformationShow()
      commit(ADD_ADVITEM_TO_STACK, result)
      return result
    } catch (error) {
      return Promise.reject(parseAxiosError(error))
    }
  }
}

export const advertisement = {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions
}
