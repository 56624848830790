
















































import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import LoadingBar from '@/components/partials/components/LoadingBar.vue'
import GoBackButton from '@/components/partials/components/GoBackButton.vue'
import { affichesearch } from '@/store/affiche/affiche/api-requests'
import { AffichePost } from '@/store/affiche/affiche/api-types'
import { UpdateTopicItemPriceType } from '@/store/pricelist/api-types'
import { updateTopicItemPriceApi } from '@/store/pricelist/api-requests'
import Swal from 'sweetalert2'

export type BaseData = {
  loading: boolean;
  name: string;
  comment: string;
  description: string;
  price: string | number;
  weight: string | number;
  result: any;
}

export default Vue.extend({
  components: {
    GoBackButton,
    LoadingBar
  },
  data () {
    return {
      loading: false,
      name: '',
      comment: '',
      description: '',
      price: '',
      weight: '',
      result: {}
    }
  },
  props: {
    id: [Number, String],
    topicId: [Number, String]
  },
  computed: {
    ...mapGetters({ user: 'user/user' })
  },
  methods: {
    async getItem () {
      const affichePost: AffichePost = {
        id: Number(this.id),
        topicId: Number(this.topicId)
      }
      this.loading = true
      let result
      try {
        result = await affichesearch(affichePost)
      } catch (error) {
        console.log(error)
      } finally {
        if (result) {
          this.loading = false
          const res = JSON.parse(JSON.stringify(result)) // cancel response type
          this.name = res.topicItem.name
          this.comment = res.topicItem.comment.caption[0].str
          this.description = res.topicItem.comment.description[0].str
          this.price = res.topicItem.price
          this.weight = res.topicItem.weight
        }
      }
    },
    async updateItem () {
      const updateObj: UpdateTopicItemPriceType = {
        id: Number(this.id),
        price: this.price,
        weight: this.weight
      }
      this.loading = true
      let result
      try {
        result = await updateTopicItemPriceApi(updateObj)
      } catch (error) {
        console.log(error)
        Swal.fire('Ошибка', error.message, 'error')
      } finally {
        if (result) {
          console.log(result)
          this.loading = false
          Swal.fire('Успешно сохранено!', '', 'success')
        }
      }
    }
  },
  mounted () {
    if (this.id && this.topicId) {
      this.getItem()
    }
  }
})
