




































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import VueSimplemde from 'vue-simplemde'
import { markdownToHtml } from '@/services/markdown-to-html'
import Swal from 'sweetalert2'
import moment from 'moment'
import { AfficheItem, CrawlerInfo, ProductTag } from '@/store/affiche/affiche/types'
import { AffichePost, RubricPost, FilePost, ItemsPost, AffichesUpdatePost, AffichesCopyPost } from '@/store/affiche/affiche/api-types'
import { affichecopy as AfficheCopy, getitems, affichesearch as Affichesearch, rubricsshow as RubricsShow, affichecreate as AfficheCreate, afficheupdate as AfficheSave } from '@/store/affiche/affiche/api-requests'
import { savefileNew as saveFileNew } from '@/services/fileUpload'

export type BaseData = {
    textIconNumber: number;
    textIconNumbers: any;
    toggleModalSelectItemsToCopy: boolean;
    toggleModalSelectInputs: boolean;
    affichesCopyPost: AffichesCopyPost;
    searchItems: string;
    selectedItems: any;
    mobButtonHead1: boolean;
    mobButtonHead2: boolean;
    mobButtonHead3: boolean;
    rubrickType: any;
    dateType: any;
    affisheSignatureType: any;
    affisheDateType: any;
    infoSignatureType: any;
    infoDateType: any;
    showTagsPanel: boolean;
    loading: boolean;
    loadingRub: boolean;
    loadingSAP: boolean;
    loadingItem: boolean;
    loadingItems: any;
    afficheSearchPost: AffichePost;
    itemsPost: ItemsPost;
    afficheItem: AfficheItem;
    headersAfficheItems: any;
    crawlerInfo: CrawlerInfo[];
    crawlerInfoLocal: CrawlerInfo;
    file1: any;
    file2: any;
    file3: any;
    rubricks: any;
    afficheItems: any;
    afficheItemsFiltered: any;
    tags: any;
    selectedRubrik: string;
    textItem: any;
    wcBool: any;
    publicBool: any;
    buttonType: any;
    rubricPost: RubricPost;
    filePost: FilePost;
    crawlerInfoRubrick: string;
    showDate: string;
    afficheItemFile1: any;
    afficheItemFile2: any;
    afficheItemFile3: any;
    afficheItemFileCnahge1: boolean;
    afficheItemFileCnahge2: boolean;
    afficheItemFileCnahge3: boolean;
    afficheItemFileColor1: string;
    afficheItemFileColor2: string;
    afficheItemFileColor3: string;
    imageUrl1: any;
    imageUrl2: any;
    imageUrl3: any;
    visiblePictureLandscape: boolean;
    visiblePicturePortrait: boolean;
    visiblePictureNavigation: boolean;
    visibleTopicName: boolean;
    visibleDate: boolean;
    visibleLinkToBuy: boolean;
    visibleTextItem: boolean;
    visibleComment: boolean;
    visibleTags: boolean;
    visibleAffisheSignature: boolean;
    visibleAffisheDate: boolean;
    visibleInfoSignature: boolean;
    visibleInfoDate: boolean;
    afficheBtnVisible: boolean;
    itemTagAdd: ProductTag;
    section: any;
    wasChanges: any;
    categoryid: number;
    deletedAffishe: boolean;
}

export default Vue.extend({
  components: {
    VueSimplemde
  },
  props: {
    id: {
      type: Number
    },
    topicid: {
      type: Number
    },
    categoryidprop: {
      type: Number
    }
  },
  data (): BaseData {
    return {
      categoryid: 0,
      textIconNumber: 0,
      textIconNumbers: [],
      toggleModalSelectItemsToCopy: false,
      toggleModalSelectInputs: false,
      selectedItems: [],
      searchItems: '',
      section: 'true',
      wasChanges: false,
      mobButtonHead1: true,
      mobButtonHead2: false,
      mobButtonHead3: false,
      loading: false,
      loadingRub: false,
      loadingSAP: false,
      loadingItem: false,
      loadingItems: false,
      showTagsPanel: false,
      crawlerInfoRubrick: 'Не известно',
      rubricks: [],
      afficheItems: [],
      afficheItemsFiltered: [],
      headersAfficheItems: [
        { text: 'Наименование', value: 'name' }
      ],
      affichesCopyPost: {
        topicItems: [],
        copyItems: {
          rubricInfo: false,
          dateInfo: false,
          option: false,
          files: false,
          names: false,
          tags: false,
          comments: false,
          textIcons: false
        }
      },
      tags: [],
      selectedRubrik: 'Концерт',
      showDate: '',
      afficheSearchPost: { id: 0, topicId: 0 },
      itemsPost: { categoryId: null, useProductTags: false },
      rubricPost: { id: 0, includeTopicItems: false, includeEmpty: true },
      filePost: { name: '', image: null },
      afficheItem: {
        id: null,
        name: '',
        date: null,
        dateComment: null,
        signature: null,
        shortName: '',
        firstName: '',
        middleName: '',
        topicName: '',
        hidden: 1,
        hot: false,
        linkToBuy: '',
        option: 1,
        topicId: 1,
        topicItemId: null,
        productComments: [{
          id: 0,
          topComment: '',
          bodyComment: '',
          topComment2: '',
          bodyComment2: '',
          productId: 0,
          removed: null
        }],
        productFiles: [],
        productTags: [],
        productTextIcons: [],
        description: '',
        useSignature: 0,
        useDateComment: 0,
        useShortName: 0,
        useAfficheSignature: 0,
        afficheSignature: '',
        useAfficheDate: 0,
        afficheDate: '',
        useInfoSignature: 0,
        infoSignature: '',
        useInfoDate: 0,
        infoDate: '',
        removed: null
      },
      crawlerInfo: [],
      crawlerInfoLocal: {
        id: 0,
        productId: 0,
        description: '',
        fileId: 0,
        topicId: 0,
        removed: null,
        file: {
          creationDate: '',
          fileTypeId: 0,
          host: null,
          id: null,
          name: null,
          removed: null,
          slug: null,
          thumbnail: null,
          thumbnailSlug: null
        }
      },
      afficheItemFile1: null,
      afficheItemFile2: null,
      afficheItemFile3: null,
      afficheItemFileCnahge1: false,
      afficheItemFileCnahge2: false,
      afficheItemFileCnahge3: false,
      afficheItemFileColor1: 'normal',
      afficheItemFileColor2: 'normal',
      afficheItemFileColor3: 'normal',
      imageUrl1: '',
      imageUrl2: '',
      imageUrl3: '',
      file1: null,
      file2: null,
      file3: null,
      textItem: '',
      wcBool: true,
      publicBool: false,
      buttonType: [
        { id: 1, name: 'Информационная' },
        { id: 2, name: 'Покупка kassir.ru' },
        { id: 3, name: 'Продажа' },
        { id: 4, name: 'Покупка каток' },
        { id: 5, name: 'Билеты каток' },
        { id: 6, name: 'Навигация' },
        { id: 7, name: 'AR' }
      ],
      rubrickType: [
        { id: 0, name: 'Раздел' },
        { id: 1, name: 'Текст' },
        { id: 2, name: 'Вес' }
      ],
      dateType: [
        { id: 0, name: 'Дата' },
        { id: 1, name: 'Текст' },
        { id: 2, name: 'Цена' }
      ],
      affisheSignatureType: [
        { id: 0, name: 'Откл.' },
        { id: 1, name: 'Текст' }
      ],
      affisheDateType: [
        { id: 0, name: 'Откл.' },
        { id: 1, name: 'Текст' }
      ],
      infoSignatureType: [
        { id: 0, name: 'Откл.' },
        { id: 1, name: 'Текст' }
      ],
      infoDateType: [
        { id: 0, name: 'Откл.' },
        { id: 1, name: 'Текст' }
      ],
      visiblePictureLandscape: true,
      visiblePicturePortrait: true,
      visiblePictureNavigation: false,
      visibleTopicName: true,
      visibleDate: true,
      visibleComment: true,
      visibleAffisheSignature: true,
      visibleAffisheDate: true,
      visibleInfoSignature: true,
      visibleInfoDate: true,
      visibleTags: true,
      visibleLinkToBuy: true,
      visibleTextItem: true,
      afficheBtnVisible: false,
      deletedAffishe: true,
      itemTagAdd: {
        id: null,
        productId: null,
        tagId: null,
        removed: null
      }
    }
  },
  computed: {
    ...mapGetters({ user: 'user/user' }),
    ...mapGetters({ alltags: 'tags/tags' })
  },
  methods: {
    ...mapActions('tags', { tagsShow: 'show' }),
    async loadTags () {
      try {
        const result = await this.tagsShow()
        this.tags = JSON.parse(JSON.stringify(result))
      } catch (error) {
        Swal.fire('Ошибка загрузки тэгов', error.message, 'error')
        console.log(error)
      }
    },
    async loadAfficheItems () {
      this.loadingItems = true
      try {
        const result = await getitems(this.itemsPost)
        this.afficheItems = JSON.parse(JSON.stringify(result))
        if (this.afficheItem.description) {
          this.searchItems = this.afficheItem.description.substring(0, 10)
        }
        this.afficheItemsFilter()
      } catch (error) {
        Swal.fire('Ошибка загрузки объектов для копирования', error.message, 'error')
        console.log(error)
      } finally {
        this.loadingItems = false
      }
    },
    async loadRubricks (categoryId: number) {
      this.loadingRub = true
      console.log('Загружаем рубрики')
      try {
        this.rubricPost.id = categoryId
        const result = await RubricsShow(this.rubricPost)
        const resFull = JSON.parse(JSON.stringify(result))
        this.rubricks = resFull[0].topics
        this.loadTags()
      } catch (error) {
        Swal.fire('Ошибка загрузки рубрик', error.message, 'error')
        console.log(error)
      } finally {
        if (this.id && this.topicid) {
          this.loadItem()
        }
        this.loadingRub = false
      }
    },
    async saveImage (file: any, imageType: any) {
      this.loading = true
      try {
        const formData = new FormData()
        formData.append('name', file.name)
        formData.append('image', file)
        formData.append('fileTypeId', imageType)
        const result = await saveFileNew(formData)
        const afficheItemFile = this.afficheItem.productFiles.find((el: any) => el.typeId === imageType)
        if (afficheItemFile) {
          afficheItemFile.fileId = result.id
          afficheItemFile.file = result
        }
        Swal.fire('Успешно сохранено!', '', 'success')
      } catch (error) {
        Swal.fire('Ошибка сохранения картинки', error.message, 'error')
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    async loadItem () {
      this.loadingItem = true
      try {
        console.log('Загружаем афишу')
        const result = await Affichesearch(this.afficheSearchPost)
        this.afficheItem = JSON.parse(JSON.stringify(result.topicItem))
        this.crawlerInfo = JSON.parse(JSON.stringify(result.crawlerInfo))
        this.getTextIcons()
        if (this.afficheItem.topicId != null) {
          this.selectedRubrik = this.rubricks.find((el: any) => el.id === this.afficheItem.topicId).name
          if (this.afficheItem.removed == null) {
            this.deletedAffishe = false
          }
        } else {
          this.deletedAffishe = true
        }
        if (this.afficheItem.date === null) {
          if (this.afficheItem.dateComment != null) {
            this.showDate = this.afficheItem.dateComment
          }
        } else {
          const dateTemp = new Date(this.afficheItem.date).toJSON().split('T')[0].split('-')
          this.showDate = dateTemp[2] + '.' + dateTemp[1] + '.' + dateTemp[0]
        }
        if (this.crawlerInfo.length > 0) {
          this.crawlerInfoLocal = this.crawlerInfo[0]
          if (this.afficheItem.topicId != null) {
            this.crawlerInfoRubrick = this.rubricks.find((el: any) => el.id === this.crawlerInfoLocal.topicId).name
          }
        }
        if (this.afficheItem.productFiles.find((el: any) => el.typeId === 1)) {
          this.afficheItemFile1 = this.afficheItem.productFiles.find((el: any) => el.typeId === 1)
          this.imageUrl1 = this.afficheItemFile1.file.slug
        } else {
          this.afficheItemFile1 = null
          this.afficheItem.productFiles.push({ id: null, fileId: 0, productId: this.afficheItem.id, removed: null, typeId: 1, file: null })
        }
        if (this.afficheItem.productFiles.find((el: any) => el.typeId === 2)) {
          this.afficheItemFile2 = this.afficheItem.productFiles.find((el: any) => el.typeId === 2)
          this.imageUrl2 = this.afficheItemFile2.file.slug
        } else {
          this.afficheItemFile2 = null
          this.afficheItem.productFiles.push({ id: null, fileId: 0, productId: this.afficheItem.id, removed: null, typeId: 2, file: null })
        }
        if (this.afficheItem.productFiles.find((el: any) => el.typeId === 3)) {
          this.afficheItemFile3 = this.afficheItem.productFiles.find((el: any) => el.typeId === 3)
          this.imageUrl3 = this.afficheItemFile3.file.slug
        } else {
          this.afficheItemFile3 = null
          this.afficheItem.productFiles.push({ id: null, fileId: 0, productId: this.afficheItem.id, removed: null, typeId: 3, file: null })
        }
        if (this.afficheItem.productComments.length === 0) {
          this.afficheItem.productComments.push(
            {
              id: 0,
              topComment: '',
              bodyComment: '',
              topComment2: '',
              bodyComment2: '',
              productId: 0,
              removed: null
            })
        }
        if (this.afficheItem.productComments[0].topComment === null) this.afficheItem.productComments[0].topComment = ''
        if (this.afficheItem.productComments[0].topComment2 === null) this.afficheItem.productComments[0].topComment2 = ''
        if (this.afficheItem.productComments[0].bodyComment === null) this.afficheItem.productComments[0].bodyComment = ''
        if (this.afficheItem.productComments[0].bodyComment2 === null) this.afficheItem.productComments[0].bodyComment2 = ''
        this.afficheItem.hidden === null || this.afficheItem.hidden === 0 ? this.publicBool = true : this.publicBool = false
      } catch (error) {
        Swal.fire('Ошибка загрузки объекта', error.message, 'error')
        console.log(error)
      } finally {
        this.loadingItem = false
        this.buttonTypeChange()
        this.loading = false
      }
    },
    async saveAffichePost (silentSave: boolean) {
      this.loadingSAP = true
      try {
        if (this.afficheSearchPost.id === 0) {
          const result = await AfficheCreate(this.afficheItem)
          this.afficheSearchPost.id = result.id
          this.afficheSearchPost.topicId = result.topicId
        } else {
          const afficheItemsPost: AffichesUpdatePost = { topicItems: [] }
          afficheItemsPost.topicItems.push(this.afficheItem)
          await AfficheSave(afficheItemsPost)
        }
        if (silentSave) Swal.fire('Успешно сохранено!', '', 'success')
        this.wasChanges = false
      } catch (error) {
        Swal.fire('Ошибка сохранения объекта', error.message, 'error')
        console.log(error)
      } finally {
        this.loadingSAP = false
        if (this.afficheItem.topicId) {
          this.afficheSearchPost.topicId = this.afficheItem.topicId
          if (silentSave) this.loadItem()
        }
      }
    },
    getTextIcons () {
      this.textIconNumbers = []
      this.textIconNumber = 0
      if (this.afficheItem.productTextIcons != null) {
        this.afficheItem.productTextIcons.forEach((element: any) => {
          if (element.removed === null) {
            this.textIconNumbers.push(360 - this.textIconNumber - (element.text.length - 1) * 10)
            this.textIconNumber = this.textIconNumber + 30 + (element.text.length - 1) * 10
          }
        })
      }
    },
    mobBuutonHeadCheck (number: number) {
      if (number === 1) {
        this.mobButtonHead1 = true
        this.mobButtonHead2 = false
        this.mobButtonHead3 = false
      } else if (number === 2) {
        this.mobButtonHead1 = false
        this.mobButtonHead2 = true
        this.mobButtonHead3 = false
      } else if (number === 3) {
        this.mobButtonHead1 = false
        this.mobButtonHead2 = false
        this.mobButtonHead3 = true
      }
    },
    rubrikChange (item: any) {
      this.wasChanges = true
      if (this.afficheItem.topicId != null) {
        this.selectedRubrik = this.rubricks.find((el: any) => el.id === item).name
      }
    },
    changeDate () {
      this.wasChanges = true
      if (this.afficheItem.date !== null && this.afficheItem.date !== '') {
        const dateTemp = new Date(this.afficheItem.date).toJSON().split('T')[0].split('-')
        this.showDate = dateTemp[2] + '.' + dateTemp[1] + '.' + dateTemp[0]
      }
    },
    beforeSaveAfficheValidate () {
      if (this.afficheItem.name) {
        const str = this.afficheItem.name.replace(/\s+/g, '')
        if (str.length) {
          return true
        }
      }
      Swal.fire({
        title: 'Ошибка!',
        text: 'Заполните обязательные* поля!',
        confirmButtonText: 'Ок'
      })
      return false
    },
    saveAffiche () {
      const valid: boolean = this.beforeSaveAfficheValidate()
      if (valid) {
        Swal.fire({
          title: 'Уверены, что хотите сохранить изменения ?',
          showDenyButton: true,
          showCancelButton: true,
          cancelButtonText: 'После сохранения применить изменения для других',
          confirmButtonText: 'Сохранить',
          cancelButtonColor: '#1976d2',
          denyButtonText: 'Отменить'
        }).then((result) => {
          if (result.isConfirmed) {
            this.saveAffichePost(true)
          } else if (result.isDenied) {
            Swal.fire('Отменено', '', 'info')
          } else if (result.isDismissed) {
            this.saveAffichePost(false)
            this.openModalSelectItemsToCopy()
          }
        })
      }
    },
    onFileChange1 (file: any) {
      this.wasChanges = true
      if (!file) {
        return
      }
      const reader = new FileReader()
      reader.onload = (e: any) => {
        this.imageUrl1 = e.target.result
      }
      reader.readAsDataURL(file)
      this.afficheItemFileColor1 = 'error'
      this.afficheItemFileCnahge1 = true
    },
    onFileChange2 (file: any) {
      this.wasChanges = true
      if (!file) {
        return
      }
      const reader = new FileReader()
      reader.onload = (e: any) => {
        this.imageUrl2 = e.target.result
      }
      reader.readAsDataURL(file)
      this.afficheItemFileColor2 = 'error'
      this.afficheItemFileCnahge2 = true
    },
    onFileChange3 (file: any) {
      this.wasChanges = true
      if (!file) {
        return
      }
      const reader = new FileReader()
      reader.onload = (e: any) => {
        this.imageUrl3 = e.target.result
      }
      reader.readAsDataURL(file)
      this.afficheItemFileColor3 = 'error'
      this.afficheItemFileCnahge3 = true
    },
    saveFile (item: any, imageType: number) {
      this.wasChanges = true
      if (item.type.indexOf('image') === -1) {
        Swal.fire('ОШИБКА', 'Не изображение', 'error')
      } else {
        if (item.size > 5242880) {
          Swal.fire('ОШИБКА', 'Размер изображения не должен превышать 5Мб', 'error')
        }
        Swal.fire({
          title: 'Уверены, что хотите загрузить новое изображение ?',
          text: 'После загрузки изображения не забудьте сохранить изменения.',
          showDenyButton: true,
          confirmButtonText: 'Сохранить',
          denyButtonText: 'Отменить'
        }).then((result) => {
          if (result.isConfirmed) {
            this.saveImage(item, imageType)
          } else if (result.isDenied) {
            Swal.fire('Отменено', '', 'info')
          }
        })
      }
    },
    markdownConvert (text: string) {
      const markText = markdownToHtml(text)
      return markText.substring(3, markText.length - 4)
    },
    publicChange (item: any) {
      this.wasChanges = true
      item ? this.afficheItem.hidden = null : this.afficheItem.hidden = 1
    },
    addItem () {
      this.wasChanges = true
      if (this.textItem !== '') {
        this.afficheItem.productTextIcons.push({ id: null, productId: this.afficheItem.id, text: this.textItem, removed: null })
        this.textItem = ''
      }
      this.getTextIcons()
    },
    delItem (item: any) {
      this.wasChanges = true
      item.removed = moment(Date.now()).format('DD-MM-YYYY HH:mm:ss')
      this.getTextIcons()
    },
    addTag () {
      this.wasChanges = true
      this.showTagsPanel = !this.showTagsPanel
    },
    selectTag (id: any) {
      this.wasChanges = true
      const item = this.afficheItem.productTags.find((el: any) => String(el.tagId) === String(id))
      if (item) {
        if (item.removed === null) {
          item.removed = moment(Date.now()).format('DD-MM-YYYY HH:mm:ss')
        } else {
          item.removed = null
        }
      } else {
        this.itemTagAdd.productId = String(this.afficheItem.id)
        this.itemTagAdd.tagId = String(id)
        const copy = Object.assign({}, this.itemTagAdd)
        this.afficheItem.productTags.push(copy)
      }
    },
    delTag (item: any) {
      this.wasChanges = true
      item.removed = moment(Date.now()).format('DD-MM-YYYY HH:mm:ss')
    },
    getTagName (id: any) {
      if (this.tags.length > 0) {
        return this.tags.find((el: any) => String(el.id) === String(id)).value
      }
      return ''
    },
    chipClass (id: any) {
      if (this.afficheItem.productTags.length > 0) {
        if (this.afficheItem.productTags.find((el: any) => String(el.tagId) === String(id) && el.removed === null)) {
          return 'primary--text v-chip--active'
        }
      }
      return ''
    },
    checkTag (id: any) {
      let result = false
      if (this.afficheItem.productTags.length > 0) {
        if (this.afficheItem.productTags.find((el: any) => String(el.tagId) === String(id) && el.removed === null)) {
          result = true
        }
      }
      return result
    },
    back () {
      if (this.wasChanges) {
        Swal.fire({
          title: 'Были произведены изменения, сохранить перед выходом?',
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: 'Сохранить',
          denyButtonText: 'Выйти без сохранения',
          cancelButtonText: 'Отмена'
        }).then((result) => {
          if (result.isConfirmed) {
            this.saveAffiche()
          } else if (result.isDenied) {
            const categoryId = this.categoryid.toString()
            this.$router.push({ name: 'AffichesCatalog', params: { categoryid: categoryId } })
          }
        })
      } else {
        const categoryId = this.categoryid.toString()
        this.$router.push({ name: 'AffichesCatalog', params: { categoryid: categoryId } })
      }
    },
    buttonTypeChange () {
      this.checkVisibleElemens(this.afficheItem.option)
    },
    checkVisibleElemens (option: any) {
      switch (option) {
        case 1: {
          this.afficheBtnVisible = false
          this.visiblePictureLandscape = true
          this.visiblePicturePortrait = true
          this.visiblePictureNavigation = false
          this.visibleTopicName = true
          this.visibleDate = true
          this.visibleComment = true
          this.visibleTags = true
          this.visibleLinkToBuy = false
          this.visibleTextItem = true
          this.visibleAffisheSignature = true
          this.visibleAffisheDate = true
          this.visibleInfoSignature = true
          this.visibleInfoDate = true
          break
        }
        case 2: {
          this.afficheBtnVisible = true
          this.visiblePictureLandscape = true
          this.visiblePicturePortrait = true
          this.visiblePictureNavigation = false
          this.visibleTopicName = true
          this.visibleDate = true
          this.visibleComment = true
          this.visibleTags = true
          this.visibleLinkToBuy = true
          this.visibleTextItem = true
          this.visibleAffisheSignature = true
          this.visibleAffisheDate = true
          this.visibleInfoSignature = true
          this.visibleInfoDate = true
          break
        }
        case 3: {
          this.afficheBtnVisible = false
          this.visiblePictureLandscape = true
          this.visiblePicturePortrait = true
          this.visiblePictureNavigation = false
          this.visibleTopicName = true
          this.visibleDate = false
          this.visibleComment = true
          this.visibleTags = true
          this.visibleLinkToBuy = false
          this.visibleTextItem = true
          this.visibleAffisheSignature = false
          this.visibleAffisheDate = false
          this.visibleInfoSignature = false
          this.visibleInfoDate = false
          break
        }
        case 4: {
          this.afficheBtnVisible = false
          this.visiblePictureLandscape = true
          this.visiblePicturePortrait = true
          this.visiblePictureNavigation = false
          this.visibleTopicName = true
          this.visibleDate = false
          this.visibleComment = false
          this.visibleTags = true
          this.visibleLinkToBuy = false
          this.visibleTextItem = false
          this.visibleAffisheSignature = false
          this.visibleAffisheDate = false
          this.visibleInfoSignature = false
          this.visibleInfoDate = false
          break
        }
        case 5: {
          this.afficheBtnVisible = false
          this.visiblePictureLandscape = true
          this.visiblePicturePortrait = true
          this.visiblePictureNavigation = false
          this.visibleTopicName = true
          this.visibleDate = false
          this.visibleComment = false
          this.visibleTags = true
          this.visibleLinkToBuy = false
          this.visibleTextItem = false
          this.visibleAffisheSignature = false
          this.visibleAffisheDate = false
          this.visibleInfoSignature = false
          this.visibleInfoDate = false
          break
        }
        case 6: {
          if (this.mobButtonHead3) {
            this.mobButtonHead1 = true
            this.mobButtonHead3 = false
          }
          this.afficheBtnVisible = false
          this.visiblePictureLandscape = true
          this.visiblePicturePortrait = false
          this.visiblePictureNavigation = true
          this.visibleTopicName = true
          this.visibleDate = true
          this.visibleComment = false
          this.visibleTags = true
          this.visibleLinkToBuy = false
          this.visibleTextItem = false
          this.visibleAffisheSignature = false
          this.visibleAffisheDate = false
          this.visibleInfoSignature = false
          this.visibleInfoDate = false
          break
        }
        case 7: {
          this.afficheBtnVisible = false
          this.visiblePictureLandscape = true
          this.visiblePicturePortrait = true
          this.visiblePictureNavigation = false
          this.visibleTopicName = true
          this.visibleDate = false
          this.visibleComment = true
          this.visibleTags = true
          this.visibleLinkToBuy = false
          this.visibleTextItem = true
          this.visibleAffisheSignature = false
          this.visibleAffisheDate = false
          this.visibleInfoSignature = false
          this.visibleInfoDate = false
          break
        }
      }
    },
    openModalSelectItemsToCopy () {
      this.toggleModalSelectItemsToCopy = true
      this.loadAfficheItems()
    },
    closeModals () {
      this.toggleModalSelectItemsToCopy = false
      this.toggleModalSelectInputs = false
      this.selectedItems = []
      this.afficheItems = []
      this.affichesCopyPost.copyItems = {
        rubricInfo: false,
        dateInfo: false,
        option: false,
        files: false,
        names: false,
        tags: false,
        comments: false,
        textIcons: false
      }
    },
    saveSelectedItemsToCopy () {
      if (this.selectedItems.length === 0) {
        this.closeModals()
      } else {
        this.toggleModalSelectItemsToCopy = false
        this.toggleModalSelectInputs = true
      }
    },
    afficheItemsFilter () {
      this.afficheItemsFiltered = this.afficheItems.filter((el: any) => el.description.toUpperCase().startsWith(this.searchItems.toUpperCase()) && el.id !== this.afficheItem.id)
    },
    clearSearhAffiche () {
      this.searchItems = ''
      this.afficheItemsFilter()
    },
    async saveSelectInputsToSelectItems () {
      this.loading = true
      this.selectedItems.forEach((element: any) => {
        if (this.affichesCopyPost.copyItems.rubricInfo) {
          element.useSignature = this.afficheItem.useSignature
          element.topicId = this.afficheItem.topicId
          element.signature = this.afficheItem.signature
        }
        if (this.affichesCopyPost.copyItems.dateInfo) {
          element.date = this.afficheItem.date
          element.dateComment = this.afficheItem.dateComment
          element.useDateComment = this.afficheItem.useDateComment
        }
        if (this.affichesCopyPost.copyItems.option) {
          element.option = this.afficheItem.option
        }
        if (this.affichesCopyPost.copyItems.files) {
          element.productFiles = this.afficheItem.productFiles
        }
        if (this.affichesCopyPost.copyItems.names) {
          element.shortName = this.afficheItem.shortName
          element.name = this.afficheItem.name
          element.middleName = this.afficheItem.middleName
          element.firstName = this.afficheItem.firstName
        }
        if (this.affichesCopyPost.copyItems.tags) {
          element.productTags = this.afficheItem.productTags
        }
        if (this.affichesCopyPost.copyItems.comments) {
          element.productComments = this.afficheItem.productComments
        }
        if (this.affichesCopyPost.copyItems.textIcons) {
          element.productTextIcons = this.afficheItem.productTextIcons
        }
      })

      try {
        this.selectedItems.forEach((element: any) => {
          this.affichesCopyPost.topicItems.push(element)
        })
        await AfficheCopy(this.affichesCopyPost)
        Swal.fire('Успешно сохранено!', '', 'success')
        this.wasChanges = false
      } catch (error) {
        Swal.fire('Ошибка сохраниния информации на афиши', error, 'error')
        console.log(error)
      } finally {
        this.loading = false
        this.loadItem()
        this.closeModals()
      }
    },
    getTextIconLeftStyle (index: any) {
      return 'left: ' + this.textIconNumbers[index] + 'px;'
    }
  },
  mounted () {
    if (this.id && this.topicid) {
      this.categoryid = this.categoryidprop
      this.afficheSearchPost.id = this.id
      this.afficheSearchPost.topicId = this.topicid
      this.loadRubricks(this.categoryid)
    } else {
      if (this.categoryidprop) {
        this.categoryid = this.categoryidprop
        this.loadRubricks(this.categoryid)
        this.deletedAffishe = false
      } else {
        this.back()
      }
    }
  }
})
