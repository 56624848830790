





































































































































































































import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import LoadingBar from '@/components/partials/components/LoadingBar.vue'
import { getPeriodByOrderTypeApi } from '@/store/orders/api-requests'

export default Vue.extend({
  components: {
    LoadingBar
  },
  data () {
    return {
      loading: false,
      menuDateFrom: false,
      menuDateTo: false,
      menuDateFilter: false,
      itogSum: 0,
      dateFrom: new Date().getFullYear() + '-01-01',
      dateTo: new Date().toISOString().substr(0, 10),
      orderTypes: [
        { id: 3, name: 'Еда' },
        { id: 1, name: 'Каток' },
        { id: 0, name: 'Нет' }
      ],
      statuses: [
        { id: 0, name: 'Все' },
        { id: '0', name: 'Новый' },
        { id: '1', name: 'Оплачен' },
        { id: '6', name: 'Отменен' },
        { id: '10', name: 'Ошибка' }
      ],
      slideOrderTypes: [
        { orderType: 0, name: 'Все' },
        { orderType: '0', name: 'Шкафчики' },
        { orderType: '1', name: 'Каток' }
      ],
      selectedOrderType: 0,
      recievedOrderType: 0,
      orders: [],
      idFilterValue: '',
      slideOrderTypeFilterValue: 0,
      createdDateFilterValue: null,
      sumFilterValue: null,
      phoneFilterValue: null,
      statusFilterValue: 0,
      orderNumberFilterValue: null,

      transactionNumberFilterValue: null,
      filteredOrders: [],
      headers: [
        { text: 'Номер', align: 'center', value: 'id', sortable: false },
        { text: 'Услуга', align: 'center', value: 'orderProduct', sortable: false },
        { text: 'Дата/время', value: 'creationDate', sortable: true },
        { text: 'Сумма', value: 'sum', sortable: false },
        { text: 'Телефон', value: 'phone', sortable: false },
        { text: 'Статус', value: 'status', sortable: false },
        { text: 'Номер B2Pay', value: 'orderNumber', sortable: false },
        { text: 'Транзакция B2Pay', value: 'bankTransaction', sortable: false }
      ],
      sortBy: 'creationDate',
      sortDesc: true
    }
  },
  methods: {
    blurInput () {
      (this.$refs.createdDateInputRef as HTMLElement).blur()
    },
    filterAll () {
      let filteredItems = JSON.parse(JSON.stringify(this.orders))
      if (this.idFilterValue !== '') {
        filteredItems = filteredItems.filter((el: any) => el.id.includes(this.idFilterValue))
      }
      if (this.slideOrderTypeFilterValue) {
        filteredItems = filteredItems.filter((el: any) => el.orderType === this.slideOrderTypeFilterValue)
      }
      if (this.createdDateFilterValue) {
        filteredItems = filteredItems.filter((el: any) => this.createdDateFilterValue === el.creationDate.substr(0, 10))
      }
      if (this.sumFilterValue) {
        filteredItems = filteredItems.filter((el: any) => el.sum === this.sumFilterValue)
      }
      if (this.phoneFilterValue) {
        filteredItems = filteredItems.filter((el: any) => el.phone.includes(this.phoneFilterValue))
      }
      if (this.statusFilterValue) {
        filteredItems = filteredItems.filter((el: any) => el.status === this.statusFilterValue)
      }
      if (this.orderNumberFilterValue) {
        filteredItems = filteredItems.filter((el: any) => el.orderNumber === this.orderNumberFilterValue)
      }
      if (this.transactionNumberFilterValue) {
        filteredItems = filteredItems.filter((el: any) => el.bankTransaction === this.transactionNumberFilterValue)
      }

      this.itogSum = filteredItems.reduce((sum: any, current: any) => {
        return Number(Number(sum).toFixed(2)) + Number(Number(current.sum).toFixed(2))
      }, 0)
      this.filteredOrders = filteredItems
    },
    async show () {
      this.loading = true
      let result
      try {
        result = await getPeriodByOrderTypeApi({ dateFrom: this.dateFrom, dateTo: this.dateTo, orderType: this.selectedOrderType })
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
        if (result) {
          let orders = JSON.parse(JSON.stringify(result))
          orders = orders.filter((el: any) => el.status !== 100)
          orders.map((el: any) => {
            el.id = el.id.toString()
            el.status = el.status.toString()
            el.orderProduct = el.orderType === 0 ? 'Шкафчики' : 'Каток'
            if (el.orderType === 0) { // шкафчики
              el.sum = el.sum / 100
            } else if (el.orderType === 1) { // каток
              el.sum = Number(el.sum) / 100
            } else { // еда
              el.sum = el.sum / 100
            }
            el.sum = el.sum.toString()
            el.orderType = el.orderType.toString()
            el.phone = el.phone ? el.phone.toString() : ''
            return el
          })
          this.orders = orders
          this.filteredOrders = orders
          this.recievedOrderType = this.selectedOrderType
        } else {
          this.orders = []
        }
        this.idFilterValue = ''
        this.slideOrderTypeFilterValue = 0
        this.createdDateFilterValue = null
        this.sumFilterValue = null
        this.phoneFilterValue = null
        this.statusFilterValue = 0
        this.orderNumberFilterValue = null
        this.filterAll()
      }
    },
    selectHistory (item: any) {
      this.$router.push({ name: 'OrderHistory', params: { id: item } })
    },
    selectСonsist (item: any) {
      this.$router.push({ name: 'OrderConsist', params: { id: item } })
    },

    computedStatus (status: any): string {
      if (status === '0') {
        status = 'Новый'
      } else if (status === '1') {
        status = 'Оплачен'
      } else if (status === '2') {
        status = 'Собирается'
      } else if (status === '3') {
        status = 'Отмена оплаты со стороны банка'
      } else if (status === '5') {
        status = 'На этапе доставки'
      } else if (status === '6') {
        status = 'Отменён'
      } else if (status === '7') {
        status = 'Доставлен'
      } else if (status === '8') {
        status = 'Не доставлен'
      } else if (status === '10') {
        status = 'Ошибка'
      }
      return status
    }
  },
  mounted () {
    if (this.user.person.userType === '20') {
      this.orderTypes = [
        { id: 3, name: 'Еда' }
      ]
      this.selectedOrderType = 3
      this.recievedOrderType = 3
    } else {
      console.log(this.user.person.userType)
      this.orderTypes = [
        { id: 3, name: 'Еда' },
        { id: 1, name: 'Каток' }
      ]
      this.selectedOrderType = 1
      this.recievedOrderType = 1
    }
    this.show()
  },
  computed: {
    ...mapGetters({ user: 'user/user' }),
    computedHeaders (): any {
      return this.recievedOrderType !== 1 ? this.headers.filter((th: any) => th.text !== 'Услуга') : this.headers
    }
  }
})
