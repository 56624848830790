import axios, { setAuthorizationHeader } from '@/services/axios'
import { AxiosResponse } from 'axios'
import { UpdateOrCreateAdvertisement } from './types'
import { GetRemovedAdvertisements, GetAllAdvertisements, UpdateRemoveAdvertisements } from './api-types'

const getItems = 'advertisements'
const createItem = 'advertisements/create'
const updateItem = 'advertisements/update'
const updateRemoveItems = 'advertisements/delete'

export const show = async () => {
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(getItems)
  return result.data.data
}

export const getItemsWithFilter = async (filter: GetRemovedAdvertisements | GetAllAdvertisements) => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(getItems, filter)
  return result.data.data
}

export const create = async (item: UpdateOrCreateAdvertisement): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(createItem, item)
  return result.data.data
}

export const update = async (item: UpdateOrCreateAdvertisement): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(updateItem, item)
  return result.data.data
}

export const updateRemoved = async (items: UpdateRemoveAdvertisements): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(updateRemoveItems, items)
  return result.data.data
}
