




























































import Vue from 'vue'
import FormContainer from '@/components/partials/forms/inputs/FormContainer.vue'
import { mapActions } from 'vuex'
type postForm = {
  oldPhone: string | null;
  newPhone: string | null;
  confirmationCodePhone: string | null;
  confirmationCodeSms: string | null;
}
type BaseData = {
  postForm: postForm;
};
export default Vue.extend({
  components: {
    FormContainer
  },
  data (): BaseData {
    return {
      postForm: {
        oldPhone: null,
        newPhone: null,
        confirmationCodePhone: null,
        confirmationCodeSms: null
      }
    }
  },
  methods: {
    ...mapActions('user', { userLogin: 'login' }),
    handleSubmit () {
      console.log(this.postForm)
    }
  }
})
