








import Vue from 'vue'
export default Vue.extend({
  data () {
    return {
      pageMessage: ''
    }
  },
  props: {
    message: {
      type: String,
      required: true
    }
  },
  mounted () {
    this.pageMessage = this.message
  }
})
