




























































import Vue from 'vue'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { UserLoginPost } from '@/store/user/api-types'
import { ServerError } from '@/services/api'
import FormContainer from '@/components/partials/forms/inputs/FormContainer.vue'
import SubmitButton from '@/components/partials/forms/inputs/SubmitButton.vue'
import { mapActions } from 'vuex'
type BaseData = {
  userLoginPost: UserLoginPost;
  isLoading: boolean;
  showPassword: boolean;
  valid: boolean;
  usernameRules: any;
  passwordRules: any;
};
export default Vue.extend({
  components: {
    FormContainer,
    SubmitButton
  },
  data (): BaseData {
    return {
      userLoginPost: {
        username: '',
        password: ''
      },
      isLoading: false,
      showPassword: false,
      valid: false,
      usernameRules: [
        (v: any) => !!v || 'Введите логин',
        (v: any) => v.length >= 3 || 'Логин должен быть не менее 3 символов'
      ],
      passwordRules: [
        (v: any) => !!v || 'Введите пароль',
        (v: any) => v.length >= 3 || 'Пароль должен быть не менее 3 символов'
      ]
    }
  },
  methods: {
    ...mapActions('user', { userLogin: 'login' }),
    ...mapActions('snackbar', { setSnackbar: 'setSnackbar' }),
    async handleSubmit () {
      if (!this.valid) {
        return
      }
      this.isLoading = true
      try {
        await this.userLogin(this.userLoginPost)

        // Clear inputs
        this.userLoginPost = {
          username: '',
          password: ''
        }
        // Push to home
        this.$router.push({ name: 'Home' })
      } catch (error) {
        const e = error as ServerError
        if (e && (e.error || e.errors)) {
          //
        }
        this.setSnackbar({
          showing: true,
          text: 'Ошибка. Повторите попытку.',
          timeout: 3000
        })
      } finally {
        this.isLoading = false
      }
    }
  },
  mixins: [validationMixin],
  validations: {
    userLoginPost: {
      username: {
        required
      },
      password: {
        required
      }
    }
  }
})
