/* eslint-disable @typescript-eslint/no-unused-vars */

import { ActionContext, ActionTree, GetterTree, MutationTree } from 'vuex'
import { ADD_TAGS_TO_STACK } from './mutations'
import { RootState } from '@/store/state'
import { Tag, TagsState } from './types'
import { getitems as InformationShow, tagcreate as InformationAdd } from './api-requests'
import { parseAxiosError } from '@/services/api'

type InformationContext = ActionContext<TagsState, RootState>

const initialState: TagsState = {
  tags: []
}

const getters: GetterTree<TagsState, RootState> = {
  tags (state: TagsState, rootState: RootState): Tag[] {
    return state.tags
  }
}

const mutations: MutationTree<TagsState> = {
  [ADD_TAGS_TO_STACK] (state: TagsState, informationResponse: Tag[]) {
    state.tags = informationResponse
  }
}

const actions: ActionTree<TagsState, RootState> = {
  async show ({ commit, dispatch, state }: InformationContext): Promise<Tag[]> {
    try {
      const result = await InformationShow()
      commit(ADD_TAGS_TO_STACK, result)
      return result
    } catch (error) {
      return Promise.reject(parseAxiosError(error))
    }
  }
}

export const tags = {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions
}
