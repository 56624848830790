import axios, { setAuthorizationHeader } from '@/services/axios'
import { AxiosResponse } from 'axios'
import { GetCategoryItemsType, GetCategoryItemType, UpdateItemType, GetPriceHistoryType, UpdateTopicItemPriceType } from './api-types'

const getCategoryItemsUrl = 'topicitems'
const getItemUrl = 'locker/price/show'
const updateItemUrl = 'locker/price/update'
const createItemUrl = 'locker/price/add'
const iikoGetProductsUrl = '/iiko/getproducts'
const getCategotiesTopicsUrl = 'categories/topics'
const getTemplatesUrl = 'discounttemplate'
const getTemplateByIdUrl = 'discounttemplate/getbyid'
const getPriceHistoryUrl = 'product/price'
const updateTopicItemPriceUrl = 'topicitems/updateprice'

export const getCategoryItemsApi = async (searchObj: GetCategoryItemsType) => {
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(getCategoryItemsUrl, searchObj)
  return result.data.data
}

export const getCategoryItemApi = async (searchObj: GetCategoryItemType) => {
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(getItemUrl, searchObj)
  return result.data.data
}

export const updateItemApi = async (updateObj: UpdateItemType) => {
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(updateItemUrl, updateObj)
  return result.data.data
}

export const createItemApi = async (createObj: any) => {
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(createItemUrl, createObj)
  return result.data.data
}

export const iikoGetProductsApi = async () => {
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(iikoGetProductsUrl)
  return result.data.data
}

export const getCategotiesTopicsApi = async () => {
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(getCategotiesTopicsUrl)
  return result.data.data
}

export const getTemplatesUrlApi = async () => {
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(getTemplatesUrl)
  return result.data.data
}

export const getTemplateByIdApi = async (idObj: any) => {
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(getTemplateByIdUrl, idObj)
  return result.data.data
}

export const getPriceHistoryApi = async (obj: GetPriceHistoryType) => {
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(getPriceHistoryUrl, obj)
  return result.data.data
}

export const updateTopicItemPriceApi = async (obj: UpdateTopicItemPriceType) => {
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(updateTopicItemPriceUrl, obj)
  return result.data.data
}
