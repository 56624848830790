































































































































































import Vue from 'vue'
import Swal from 'sweetalert2'
import { mapGetters, mapActions } from 'vuex'
import { CreateTag as CreateTagType, RemoveTag as RemoveTagType } from '@/store/tags/api-types'
import { tagremove as TagRemove, tagcreate as TagCreate, tagitemscreate as TagItemsCreate, tagitemsdelete as TagItemsDelete, getProductsOfTag } from '@/store/tags/api-requests'
import { getitems as GetItems } from '@/store/affiche/affiche/api-requests'
import { show as сategoriesShow } from '@/store/affiche/categories/api-requests'

export type BaseData = {
  loading: boolean;
  allTags: any;
  tagRemovePost: RemoveTagType;
  marker: boolean;
  newTagInputValue: string;
  createTagObj: CreateTagType;
  productsOfTag: any;
  topicItems: any;
  selectedTopicItem: any;
  currentTagId: number;
  toggleDialog: boolean;
  toggleDialogAddProduct: boolean;
  allRubrics: any;
  selectedRubric: any;
  allProductsOfRubric: any;
  selectedProductOfRubric: any;
  initialFormValues: any;
  initialFormItemsToCompare: any;
  itemsTagsCreateObj: any;
  itemsTagsDeleteObj: any;
  search: string;
}
export default Vue.extend({
  data (): BaseData {
    return {
      loading: false,
      allTags: [],
      tagRemovePost: {
        id: 0,
        value: '',
        removed: null
      },
      marker: true,
      newTagInputValue: '',
      createTagObj: {
        id: null,
        value: ''
      },
      productsOfTag: [],
      topicItems: [],
      selectedTopicItem: null,
      currentTagId: 0,
      toggleDialog: false,
      toggleDialogAddProduct: false,
      allRubrics: [],
      selectedRubric: null,
      allProductsOfRubric: [],
      selectedProductOfRubric: null,
      itemsTagsCreateObj: {
        items: [],
        tagId: null
      },
      itemsTagsDeleteObj: {
        ids: [],
        removed: 1
      },
      initialFormItemsToCompare: [],
      initialFormValues: {
        items: [],
        tagId: null
      },
      search: ''
    }
  },
  computed: {
    ...mapGetters({ user: 'user/user' }),
    ...mapGetters({ tags: 'tags/tags' }),
    tagsHeaders () {
      return [
        { text: 'Тег', align: 'start', sortable: true, value: 'value' },
        { text: '', align: 'end', sortable: false }
      ]
    },
    computedAllProductsOfRubric (): any {
      return this.allProductsOfRubric.filter((el: any) => {
        return !this.productsOfTag.find((el2: any) => el2.productName === el.name)
      })
    }
  },
  methods: {
    ...mapActions('tags', { tagsShow: 'show' }),
    async loadTags () {
      this.loading = true
      try {
        const result = await this.tagsShow()
        this.allTags = JSON.parse(JSON.stringify(result))
        console.log(this.allTags)
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
        this.loadTopicItems()
      }
    },
    deleteItem (item: any) {
      Swal.fire({
        title: 'Уверены, что хотите удалить ?',
        showDenyButton: true,
        confirmButtonText: 'Удалить',
        denyButtonText: 'Отменить'
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteTagItemPost(item)
        } else if (result.isDenied) {
          Swal.fire('Отменено', '', 'info')
        }
      })
    },
    async deleteTagItemPost (item: any) {
      this.loading = true
      try {
        this.tagRemovePost.id = item.id
        this.tagRemovePost.value = item.value
        this.tagRemovePost.removed = 1
        const result = await TagRemove(this.tagRemovePost)
        item.removed = 1
        Swal.fire('Удалено!', '', 'success')
      } catch (error) {
        Swal.fire('Ошибка', error, 'error')
        console.log(error)
      } finally {
        this.filterOnlyRemovedNull()
        this.loading = false
      }
    },
    toggleMarker () { // input focus
      this.marker = !this.marker
    },
    confirmNewTagInputValue () {
      if (this.newTagInputValue.length > 1) {
        this.createTag()
        this.clearNewTagInputValue()
      } else {
        this.openPopup('Введите текст тега!', 'warning')
      }
    },
    clearNewTagInputValue () {
      this.newTagInputValue = ''
    },
    async createTag () {
      let result
      try {
        this.createTagObj.value = this.newTagInputValue
        result = await TagCreate(this.createTagObj)
      } catch (error) {
        this.openPopup(error, 'warning')
      } finally {
        this.openPopup('Добавлен новый тег!', 'success', false)
        this.allTags.push({ id: result.id, customerId: result.customerId, removed: result.removed, value: result.value })
      }
    },
    openPopup (popupText: string, status: any, showBtn = true) {
      const Popup = Swal.mixin({
        toast: true,
        position: 'bottom',
        timer: 3000,
        showConfirmButton: showBtn
      })
      Popup.fire(popupText, '', status)
    },
    filterOnlyRemovedNull () {
      this.allTags = this.allTags.filter((el: any) => el.removed === null)
    },
    async getAllProductsWithSelectedTag (tagId: number) {
      const result = await getProductsOfTag(tagId)
      const productsOfTag = result.map((el: any) => {
        return { bindId: el.id, productName: el.product.name }
      })
      this.productsOfTag = productsOfTag
      this.initialFormValues.items = productsOfTag
    },
    async loadTopicItems () { // delete
      const topicItemsRaw = await GetItems({ categoryId: 1 })
      this.topicItems = JSON.parse(JSON.stringify(topicItemsRaw))
    },
    openModal (tag: any) {
      this.currentTagId = tag.id
      this.getAllProductsWithSelectedTag(tag.id)
      this.toggleDialog = true
    },
    async deleteTagProduct (id: any) {
      const unbindProductFromTagObj = {
        ids: [id],
        removed: 1
      }
      let result
      try {
        result = await TagItemsDelete(unbindProductFromTagObj)
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
        if (result) {
          console.log(result)
          this.getAllProductsWithSelectedTag(this.currentTagId)
        }
      }
    },
    closeForm () {
      this.toggleDialog = false
      this.itemsTagsCreateObj.items = []
      this.itemsTagsCreateObj.tagId = null

      this.itemsTagsDeleteObj.ids = []
    },
    logger (el: any) {
      console.log(el)
    },
    async openModalAddProduct (item: any) {
      this.selectedRubric = null
      this.selectedProductOfRubric = null
      // make a request and return sections
      // this.getAllProductsWithSelectedTag(item.id)
      // filter products that already has this tag
      this.toggleDialogAddProduct = true
      this.allRubrics = await сategoriesShow()
    },
    async selectRubric (rubricId: any) {
      this.allProductsOfRubric = await GetItems({ categoryId: rubricId })
    },
    async addProductToTagFormSave () {
      const obj = {
        items: [this.selectedProductOfRubric],
        tagId: this.currentTagId
      }
      let result
      try {
        result = await TagItemsCreate(obj)
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
        if (result) {
          console.log(result)
          this.toggleDialogAddProduct = false
          this.getAllProductsWithSelectedTag(this.currentTagId)
        }
      }
    }
  },
  mounted () {
    this.loadTags()
    this.filterOnlyRemovedNull()
    // console.log(this.$root.$store.getters)
  }
})
