<template>
  <v-container class="py-4">
    <LoadingBar :loading="loading"></LoadingBar>
    <v-row class="justify-content-center">
      <div class="col-12">
        <GoBackButton></GoBackButton>
      </div>
      <v-col>
        <h1 class="text-center"> История цен </h1>
      </v-col>
    </v-row>
    <v-row class="justify-content-center">
      <v-col>
        <v-data-table
          dense
          :headers="priceListHistoryHeaders"
          :items="items"
          item-key="id"
          class="elevation-1"
        >
          <template v-slot:item="{ item }">
            <tr :class="items.indexOf(item) === 0 ? 'font-weight-bold' : ''">
                <td>{{item.name}}</td>
                <td>{{item.price === 0 ? '-' : item.price}}</td>
                <td>{{item.timestamp}}</td>
                <td>{{item.ikko}}</td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import LoadingBar from '@/components/partials/components/LoadingBar.vue'
import GoBackButton from '@/components/partials/components/GoBackButton.vue'
import { getPriceHistoryApi } from '@/store/pricelist/api-requests'
export default Vue.extend({
  components: {
    GoBackButton,
    LoadingBar
  },
  data () {
    return {
      loading: false,
      items: []
    }
  },
  props: {
    id: [Number, String]
  },
  computed: {
    ...mapGetters({ user: 'user/user' }),
    priceListHistoryHeaders () {
      return [
        { text: 'Наименование', value: 'name', sortable: false },
        { text: 'Цена', value: 'price', sortable: false },
        { text: 'Дата / Время', value: 'timestamp', sortable: false },
        { text: 'ID IKKO', value: 'ikko', sortable: false }
      ]
    }
  },
  methods: {
    async getItem () {
      this.loading = true
      let result
      try {
        result = await getPriceHistoryApi({ productId: this.id })
      } catch (error) {
        console.log(error)
      } finally {
        if (result) {
          if (result[0].productPriceHistory) {
            const items = result[0].productPriceHistory.map(item => {
              item.name = result[0].name
              item.ikko = isNaN(result[0].login) ? '-' : result[0].login
              item.price = Number(item.price)
              return item
            })
            this.items = items.reverse() // todo + bold current value
            this.loading = false
          }
        }
      }
    }
  },
  mounted () {
    this.getItem()
  }
})
</script>
