export const typeColorsInitial = [
  { from: 0, to: 5, color: 3 },
  { from: 6, to: 9, color: 2 },
  { from: 10, to: 0, color: 1 }
]
/**
 * settingType
 * value - обновление страницы
 * value2 - цвета
 * value3 - отображение вкладки
 */
type Settings = {
  refreshTime: number;
  timeColors: any[];
  prepairForDeliveryTabVisibility: boolean;
}
export const mapSettings = (items: any) => {
  const result: Settings = {
    refreshTime: 5,
    timeColors: [],
    prepairForDeliveryTabVisibility: false
  }
  items.forEach((el: any) => {
    if (el.settingType === 1) {
      result.refreshTime = el.value * 60000 + el.value2 * 1000
    }
    if (el.settingType === 2) {
      result.timeColors.push({ from: el.value, to: el.value2, color: el.value3 })
    }
    if (el.settingType === 3) {
      result.prepairForDeliveryTabVisibility = Boolean(el.value)
    }
  })
  // console.log(result)
  return result
}

export const getTimeToCompare = (minutes: any) => {
  let dt: Date | string = new Date()
  dt = new Date(dt.getTime() - (dt.getTimezoneOffset() * 60000)).toISOString().substr(0, 19)
  return new Date(new Date(Date.parse(dt)).getTime() - Number(minutes) * 60000)
}

export const getTimeStatusClass = (creationDate: any, timeColors: any = typeColorsInitial) => {
  // console.log(timeColors)
  let result = ''
  timeColors.forEach((statusEl: any) => {
    const from: any = statusEl.from ? getTimeToCompare(statusEl.from) : null
    const to: any = statusEl.to ? getTimeToCompare(statusEl.to) : null
    if (from && to) {
      if (Date.parse(creationDate) < Date.parse(from) &&
      Date.parse(creationDate) > Date.parse(to)) {
        result = 'timestatus' + statusEl.color
      }
    } else if (from === null) {
      if (Date.parse(creationDate) > Date.parse(to)) {
        result = 'timestatus' + statusEl.color
      }
    } else if (to === null) {
      if (Date.parse(creationDate) < Date.parse(from)) {
        result = 'timestatus' + statusEl.color
      }
    }
  })
  return result
}
