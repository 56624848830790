import axios, { setAuthorizationHeader } from '@/services/axios'
import { AxiosResponse } from 'axios'
import { Information } from './types'
import { InformationPost } from './api-types'

const informationsShow = 'informations'
const informationsUpdate = 'informations/update'

export const show = async (): Promise<Information[]> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: Information[];
  }> = await axios.post(informationsShow)
  return result.data.data
}

export const update = async (informations: InformationPost): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(informationsUpdate, informations)
  return result.data.data
}
