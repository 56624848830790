

















import Vue from 'vue'
import { v4 as uuidv4 } from 'uuid'
type BaseData = {
  id: string;
}
export default Vue.extend({
  components: {
  },
  data (): BaseData {
    return {
      id: uuidv4()
    }
  },
  methods: {
    update (event: Event) {
      const target = event.target as HTMLTextAreaElement
      this.$emit('input', target.value)
    }
  },
  props: {
    isLoading: {
      required: true,
      type: Boolean
    },
    loadingText: {
      required: true,
      type: String
    },
    name: {
      required: true,
      type: String
    },
    text: {
      required: true,
      type: String
    },
    disabled: {
      required: true,
      type: Boolean
    }
  }
})
