





























import Vue from 'vue'
import GoBackButton from '@/components/partials/components/GoBackButton.vue'
import LoadingBar from '@/components/partials/components/LoadingBar.vue'
import { getOrderHistoryApi } from '@/store/orders/api-requests'
import { OrderHistoryType } from '@/store/orders/types'

export type BaseData = {
  loading: boolean;
  history: any;
  historyItems: any;
  headers: any;
  idProp: number;
}

export default Vue.extend({
  components: {
    GoBackButton,
    LoadingBar
  },
  data (): BaseData {
    return {
      loading: false,
      history: [],
      historyItems: [],
      idProp: 0,
      headers: [
        { text: 'Дата / Время', align: 'start', sortable: false, value: 'timeCreated' },
        { text: 'Статус', align: 'start', sortable: false, value: 'status' }
      ]
    }
  },
  props: {
    id: [Number, String]
  },
  methods: {
    async show () {
      this.loading = true
      let result
      try {
        result = await getOrderHistoryApi({ orderId: this.idProp })
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
        if (result) {
          this.history = JSON.parse(JSON.stringify(result[0]))
          const historyItems = JSON.parse(JSON.stringify(result[0].orderHistory))
          this.historyItems = historyItems.map((el: any) => {
            if (el.status === 0) el.status = 'Новый'
            else if (el.status === 1) el.status = 'Оплачен'
            else if (el.status === 6) el.status = 'Отменен'
            else if (el.status === 10) el.status = 'Ошибка'
            else el.status = '-'
            return el
          })
        } else {
          this.historyItems = []
        }
      }
    }
  },
  mounted () {
    this.idProp = Number(this.id)
    this.show()
  }
})
