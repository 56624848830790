<template>
  <v-container class='py-4'>
    <LoadingBar></LoadingBar>
    <v-row class="justify-content-center">
      <v-col>
        <h1 class="text-center"> Доставка </h1>
      </v-col>
    </v-row>
    <div class="row">
      <div class="col-12 col-md-6 col-lg-7 sliderTrigger" style="position:relative"  >

          <v-img
            :aspect-ratio="21/24"
            :width="imgWidth"
            :src="sectorImageUrl"

          ></v-img>
          <div
          class="sliderContainer">
            <v-slider
              v-model="imgWidth"
              class="align-self-stretch"
              min="300"
              max="1200"
              step="300"
              append-icon="mdi-magnify-plus-outline"
              prepend-icon="mdi-magnify-minus-outline"
              @click:append="zoom(300)"
              @click:prepend="zoom(-300)"
            ></v-slider>
          </div>
      </div>
      <div class="col-12 col-md-6 col-lg-5">
        <div class="row">
          <div class="col d-flex align-center">
            <v-select :loading="loading" v-model="sector" :disabled="loading" :items="sectors" label="Выберите сектор" item-text="name" item-value="id" @change="getSectorById(sector)"></v-select>
            <v-btn style="margin-left:20px" :to="{ name: 'DeliveryMessage' }"> Сообщения </v-btn>
          </div>
        </div>
        <div class="row no-gutters" v-for="rowNumber in numberOfInputRows" :key="rowNumber">
          <div class="col-12 py-0" v-if="rowNumber === 1"><label style="color: #827d7d;">Введите места</label></div>
          <div class="col-2 py-1 pr-1" v-for="colNumber in 6" :key="colNumber" :style="colNumber === 6 ? 'padding-right:0!important' : ''">
            <v-text-field dense :disabled="allRow" hide-details="auto" outlined
            @input="dynamicInputOnChange(rowNumber+'_'+colNumber, $event, rowNumber)"
            v-model="tempDynamicInputsModel[rowNumber+'_'+colNumber]"
            ></v-text-field>
          </div>
        </div>
        <div class="row no-gutters" v-if="addDiapasonMode">
          <div class="col-12 py-1" style="display: flex; align-items: center; border: 1px solid rgb(132, 139, 177);
    padding: 0 30px;">
            <span>От</span>
            <v-text-field dense v-model="addDiapasonFrom"
            style="width:10px;margin:0 30px 0 20px"
            :disabled="allRow" hide-details="auto" outlined></v-text-field>
            <span>До</span>
            <v-text-field dense v-model="addDiapasonTo"
            style="width:10px;margin:0 30px 0 20px"
            :disabled="allRow" hide-details="auto" outlined ></v-text-field>
            <v-btn small :disabled="allRow" @click="addDiapason()" class="ml-5">Ок</v-btn>
          </div>
        </div>
        <div class="row justify-end">
          <div class="col-12 text-right">
            <v-btn small v-if="numberOfInputRows > 1"  @click="deleteDynamicInputsRow()">-</v-btn>
            <v-btn small @click="addDynamicInputsRow()" class="ml-5">Добавить места +6</v-btn>
            <v-btn small @click="addDiapasonModeToggle()" class="ml-5">Добавить диапазон</v-btn>
          </div>
        </div>
        <div class="row justify-end">
          <div class="col-12 d-flex align-center">
            <v-select :disabled="allRow"
            v-model="createDelivery.deliveryType"
            :items="deliveryTypes"
             item-value="id" item-text="name" label='Выберите тип доставки'></v-select>
            <v-checkbox class="ml-3" v-model="allRow" label="Весь ряд" @click="allRowToggle()"></v-checkbox>
          </div>
        </div>
        <div class="row mt-0 justify-end">
          <div class="col-12">
            <v-btn :loading="loading" @click="validateBeforeCreate()">Добавить</v-btn>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <v-data-table
          v-model="selectedRows"
          :headers="rowsHeaders"
          :items="computedRows"
          :single-select="false"
          item-key="id"
          show-select
          class="elevation-1"
          @item-selected="showSelectedRows()"
        >
          <template v-slot:[`item.action`]="{item}">
            <v-btn :loading="loading" @click="clearRow(item.id)">Очистить</v-btn>
          </template>
        </v-data-table>
      </div>
    </div>
  </v-container>
</template>
<style>
  .container {
    max-width: 1200px;
  }
  th .v-data-table__checkbox.v-simple-checkbox{
    display:none;
  }
  .sliderContainer{
    opacity: 0;
    position:absolute;top:40px;right:0;width:200px;display:block;background:#fff;height: 30px;
    border-radius: 25px;
    padding: 0px 6px;
  }
  .sliderTrigger:hover > .sliderContainer{
    opacity: 1;
  }
</style>
<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import LoadingBar from '@/components/partials/components/LoadingBar.vue'
import { getsectors, getsectorbyid, createdelivery, deletedelivery } from '@/store/delivery/api-requests'
import Swal from 'sweetalert2'
export default Vue.extend({
  components: {
    LoadingBar
  },
  computed: {
    ...mapGetters({
      user: 'user/user'
    }),
    computedRows () {
      return this.rows.map((row) => {
        if (row.deliveryType === 1) {
          row.deliver = 'Весь ряд'
        } else {
          const left = []
          const right = []
          const deliver = []
          row.seats.forEach((seat) => {
            if (!seat.removed && seat.deliveryType === 0) {
              deliver[deliver.length] = seat.name
            } else if (!seat.removed && seat.deliveryType === 1) {
              left[left.length] = seat.name
            } else if (!seat.removed && seat.deliveryType === 2) {
              right[right.length] = seat.name
            }
          })
          row.left = left
          row.right = right
          row.deliver = deliver
        }
        return row
      })
    }
  },
  data () {
    return {
      sectors: [],
      sector: 1,
      loading: false,
      allRow: false,
      deliveryTypes: [
        { id: 0, name: 'Доставить' },
        { id: 1, name: 'Влево' },
        { id: 2, name: 'Вправо' }
      ],
      sectorImageUrl: 'https://static.mobile.glolime.ru/storage/images/75/sectors/017e7a3e0e4cbf4663954fa4c2c0392079.jpg',
      rowsHeaders: [
        { text: 'Ряд', align: 'start', value: 'name' },
        { text: 'Доставить', value: 'deliver' },
        { text: 'Влево', value: 'left' },
        { text: 'Вправо', value: 'right' },
        { text: 'Действие', value: 'action' }
      ],
      rows: [],
      selectedRows: [],
      createDelivery: {
        rowIds: [],
        seatNames: [],
        deliveryType: 0
      },
      createDeliveryInitial: {
        rowIds: [],
        seatNames: [],
        deliveryType: 0
      },
      imgWidth: 900,
      numberOfInputRows: 1,
      dynamicInputs: [],
      warningSelectionContainsAllRowRows: [],
      addDiapasonMode: false,
      addDiapasonFrom: null,
      addDiapasonTo: null,
      tempDynamicInputsModel: []
    }
  },
  methods: {
    zoom (val) {
      this.imgWidth = this.imgWidth + val
    },
    addDiapason () {
      if (isNaN(Number(this.addDiapasonFrom)) || isNaN(Number(this.addDiapasonTo))) {
        alert('error!')
      }
      this.$nextTick(() => {
        const diapasonValues = []
        for (let i = Number(this.addDiapasonFrom); i <= this.addDiapasonTo; i++) {
          diapasonValues.push(i)
        }
        const customValues = []
        this.dynamicInputs.forEach((el) => {
          if (el.value.trim() !== '') {
            customValues.push(Number(el.value))
          }
        })
        this.tempDynamicInputsModel = []
        this.numberOfInputRows = 1
        const uniqueArr = Array.from(new Set(customValues.concat(diapasonValues)))
        uniqueArr.sort(function (a, b) {
          return a - b
        })
        let row = 1
        let col = 1
        uniqueArr.forEach((el, i) => {
          i++
          this.tempDynamicInputsModel[row + '_' + col] = el
          if (i % 6 === 0) {
            row++
            col = 1
            this.addDynamicInputsRow()
          } else {
            col++
          }
        })
        // console.log(this.tempDynamicInputsModel)
        this.addDiapasonMode = false
      })
    },
    addDiapasonModeToggle () {
      this.addDiapasonFrom = null
      this.addDiapasonTo = null
      this.addDiapasonMode = !this.addDiapasonMode
    },
    addDynamicInputsRow () {
      this.numberOfInputRows++
    },
    deleteDynamicInputsRow () {
      this.dynamicInputs = this.dynamicInputs.filter((el) => el.row !== this.numberOfInputRows)
      this.numberOfInputRows--
    },
    dynamicInputOnChange (id, value, rowNumber) {
      this.$nextTick(() => {
        const isset = this.dynamicInputs.find((el) => el.id === id)
        if (!isset) {
          this.dynamicInputs.push({ id: id, value: value, row: rowNumber })
        } else {
          isset.value = value
        }
      })
    },
    showSelectedRows () {
      this.$nextTick(() => {
        // console.log(this.selectedRows)
      })
    },
    allRowToggle () {
      this.$nextTick(() => {
        if (this.allRow) {
          this.createDelivery.deliveryType = 3
        } else {
          this.createDelivery.deliveryType = 0
        }
      })
    },
    async getSectors () {
      let result
      try {
        result = await getsectors()
      } catch (error) {
        console.log(error)
      } finally {
        if (result) {
          this.sectors = JSON.parse(JSON.stringify(result))
        }
      }
    },
    async validateBeforeCreate () {
      if (!this.selectedRows.length) {
        Swal.fire({
          title: 'Ряд не выбран',
          text: 'Выберите ряд или ряды, чтобы продолжить',
          confirmButtonText: 'Ок'
        })
      } else {
        const allRowsCount = this.selectedRows.filter((el) => el.deliveryType === 1)// 1 - весь ряд в таблице
        if (allRowsCount.length && this.createDelivery.deliveryType !== 3) { // 3 - весь ряд в фильре
          Swal.fire({
            title: 'Чтобы изменить ряд, помеченный как "Весь ряд" нажмите "очистить"',
            text: 'Применить изменения к остальным выбранным рядам?',
            showDenyButton: true,
            confirmButtonText: 'Да',
            denyButtonText: 'Отмена'
          }).then((result) => {
            if (result.isConfirmed) {
              console.log(result)
              this.sendCreate()
            }
          })
        } else if (this.tempDynamicInputsModel === [] && this.createDelivery.deliveryType !== 3) {
          Swal.fire({
            title: 'Места не указаны',
            text: 'Укажите места, чтобы продолжить',
            confirmButtonText: 'Ок'
          })
        } else {
          this.sendCreate()
        }
      }
    },
    async sendCreate () {
      const seatNames = Object.keys(this.tempDynamicInputsModel).map(k => this.tempDynamicInputsModel[k].toString())
      this.createDelivery.seatNames = seatNames
      const selectedRows = this.selectedRows.map((el) => el.id)
      this.createDelivery.rowIds = selectedRows

      let result1
      try {
        result1 = await createdelivery(this.createDelivery)
      } catch (error) {
        console.log(error)
      } finally {
        if (result1) {
          this.getSectorById(this.sector)
        }
      }
    },
    async clearRow (el) {
      const idsArr = [el]
      let result
      try {
        result = await deletedelivery({ rowIds: idsArr })
      } catch (error) {
        console.log(error)
      } finally {
        this.getSectorById(this.sector)
      }
    },
    async getSectorById (id) {
      this.loading = true
      let result
      try {
        result = await getsectorbyid(id)
        this.$nextTick(() => {
          this.numberOfInputRows = 0
        })
      } catch (error) {
        console.log(error)
      } finally {
        if (result) {
          this.sectorImageUrl = result.sectorImageUrl
          this.rows = result.rows
          // refresh all:
          this.$nextTick(() => {
            this.numberOfInputRows = 1
          })
          this.selectedRows = []
          this.createDelivery = Object.assign({}, this.createDeliveryInitial)
          this.allRow = false
          this.dynamicInputs = []
          this.dynamicInputs.length = 0
          this.loading = false
          this.tempDynamicInputsModel = []
        }
      }
    }
  },
  mounted () {
    this.getSectors()
    this.getSectorById(1)
    // console.log(this.$root.$store.getters)
  }
})
</script>
