




















































































import Vue from 'vue'
import { orderinfo as orderInfo, sendqr as sendQr } from '@/store/locker/api-requests'
import { OrderInfoPost, SendQrPost } from '@/store/locker/api-types'
export default Vue.extend({
  data () {
    return {
      loading: false,
      rules: [
        (value: any) => !!value || 'Не должно быть пустым.',
        (value: any) => (!isNaN(value)) || 'Должно быть число'
      ],
      orderId: null,
      valid: false,
      status: -1,
      sendEmailFlag: false,
      orderInfoPostObject: {
        id: null,
        orderNumber: null,
        email: null,
        phone: null
      }
    }
  },
  components: {
  },
  methods: {
    async orderSearch () {
      if (this.valid) {
        if (this.orderId !== null) {
          const orderInfoPost: OrderInfoPost = { ...this.orderInfoPostObject, id: this.orderId }
          this.loading = true
          try {
            const result = await orderInfo(orderInfoPost)
            this.status = result.status
          } catch (error) {
            console.log(error)
          } finally {
            this.loading = false
          }
        }
      }
    },
    async sendEmail () {
      const sendQrPost: SendQrPost = { id: this.orderId }
      this.loading = true
      try {
        const result = await sendQr(sendQrPost)
        this.sendEmailFlag = result
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    back () {
      this.$router.push({ name: 'UserOrder' })
    }
  }
})
