import axios, { setAuthorizationHeader } from '@/services/axios'
import { AxiosResponse } from 'axios'
import { FoodSettingsGetPost } from './api-types'

const getUrl = 'foodmarketsettings'
const saveUrl = 'foodmarketsettings/save'

export const getFoodSettingsApi = async () => {
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(getUrl)
  return result.data.data
}

export const getFoodSettings = async (item: FoodSettingsGetPost) => {
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(getUrl, item)
  return result.data.data
}

export const updateOrCreateFoodSettingsApi = async (item: any): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(saveUrl, { settings: item })
  return result.data.data
}
