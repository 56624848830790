

































































































































































import Vue from 'vue'
import Swal from 'sweetalert2'
import { mapGetters, mapActions } from 'vuex'
import { ItemsPost, AffichePost, AffichesUpdateOrderPost } from '@/store/affiche/affiche/api-types'
import { getitems as GetItems, afficheremove as AfficheRemove, afficheupdateOrder as AfficheupdateOrder } from '@/store/affiche/affiche/api-requests'
import { FoodSettingsGetPost, FoodSettingSavePost } from '@/store/foodSettings/api-types'
import { getFoodSettings, updateOrCreateFoodSettingsApi } from '@/store/foodSettings/api-requests'

export type BaseData = {
  itemPost: ItemsPost;
  arhive: boolean;
  loadingCat: boolean;
  loadingSel: boolean;
  loadingDel: boolean;
  infoFood: boolean;
  infoFoodLoading: boolean;
  categories: any;
  affiches: any;
  topics: any;
  topicSelect: string;
  publishedSelect: number;
  hotSelect: number;
  afficheRemovePost: AffichePost;
  affichesUpdateOrderPost: AffichesUpdateOrderPost;
  selectedCategory: number;
  publishedTypes: any;
  hotTypes: any;
  foodSettingsGetPost: FoodSettingsGetPost;
  foodSettingSavePost: FoodSettingSavePost;
}
export default Vue.extend({
  components: {
  },
  props: {
    categoryid: {
      type: String
    }
  },
  data (): BaseData {
    return {
      topics: [],
      topicSelect: 'Все',
      publishedSelect: 0,
      hotSelect: 0,
      infoFood: true,
      infoFoodLoading: false,
      selectedCategory: 0,
      itemPost: {
        categoryId: 0
      },
      afficheRemovePost: {
        id: 0,
        topicId: 0
      },
      affichesUpdateOrderPost: {
        id: 0,
        order: 0,
        published: null,
        hot: null
      },
      arhive: false,
      loadingCat: false,
      loadingSel: false,
      loadingDel: false,
      categories: [
        { id: 0, name: 'Не разобранные' }
      ],
      affiches: [],
      publishedTypes: [
        { id: 0, name: 'Все' },
        { id: 1, name: 'Опубликован' },
        { id: 2, name: 'Не опубликован' }
      ],
      hotTypes: [
        { id: 0, name: 'Все' },
        { id: 1, name: 'На главной' },
        { id: 2, name: 'Нет на главной' }
      ],
      foodSettingsGetPost: {
        settingType: 4
      },
      foodSettingSavePost: {
        settingType: 4,
        value: 0
      }
    }
  },
  computed: {
    ...mapGetters({ user: 'user/user' }),
    ...mapGetters({ afficeCategories: 'categories/categories' }),
    affichesHeaders () {
      return [
        { text: '№', align: 'start', sortable: true, value: 'order' },
        { text: 'Название', align: 'start', sortable: true, value: 'name' },
        {
          text: 'Раздел',
          align: 'start',
          sortable: true,
          value: 'topic.name',
          filter: (value: any) => {
            if (this.topicSelect !== 'Все') {
              if (value === this.topicSelect) {
                return value
              }
            } else {
              return value
            }
          }
        },
        { text: 'Есть описание', value: 'description', sortable: false },
        { text: 'Есть картинка', value: 'picture', sortable: false },
        {
          text: 'Публикация',
          value: 'published',
          sortable: true,
          filter: (value: any) => {
            if (this.publishedSelect !== 0) {
              if (value === true && this.publishedSelect === 1) {
                return true
              } else if (value === false && this.publishedSelect === 2) {
                return true
              }
              return false
            } else {
              return true
            }
          }
        },
        {
          text: 'На главной',
          value: 'hot',
          sortable: true,
          filter: (value: any) => {
            if (this.hotSelect !== 0) {
              if (value === true && this.hotSelect === 1) {
                return true
              } else if (value === false && this.hotSelect === 2) {
                return true
              }
              return false
            } else {
              return true
            }
          }
        },
        { text: '', value: 'delete' }
      ]
    }
  },
  methods: {
    ...mapActions('categories', { categoriesShow: 'show' }),
    async loadFoodSetting () {
      this.infoFoodLoading = true
      try {
        const result = await getFoodSettings(this.foodSettingsGetPost)
        if (result[0].value === 1) this.infoFood = false
      } catch (error) {
        console.log(error)
      } finally {
        this.infoFoodLoading = false
      }
    },
    async saveFoodSetting (foodSettingSavePost: FoodSettingSavePost) {
      this.infoFoodLoading = true
      try {
        const post = []
        post.push(foodSettingSavePost)
        const result = await updateOrCreateFoodSettingsApi(post)
        console.log(result)
      } catch (error) {
        console.log(error)
        this.infoFood = !this.infoFood
      } finally {
        this.infoFoodLoading = false
      }
    },
    async loadCategories (categoryId: any) {
      this.loadingCat = true
      try {
        await this.categoriesShow()
        this.afficeCategories.forEach((element: any) => {
          this.categories.push({ id: element.id, name: element.name })
        })
        this.selectCategory(categoryId)
      } catch (error) {
        console.log(error)
      } finally {
        this.loadingCat = false
      }
    },
    async selectCategory (id: number) {
      this.loadingSel = true
      try {
        this.itemPost.categoryId = id
        this.itemPost.arhive = this.arhive
        const result = await GetItems(this.itemPost)
        this.affiches = JSON.parse(JSON.stringify(result))
      } catch (error) {
        console.log(error)
      } finally {
        this.filterOnlyRemovedNull()
        this.loadingSel = false
        this.selectedCategory = id
      }
    },
    async deleteItemPost (item: any) {
      this.loadingDel = true
      try {
        this.afficheRemovePost.id = item.id
        this.afficheRemovePost.topicId = item.topic.id
        await AfficheRemove(this.afficheRemovePost)
        item.removed = 1
        Swal.fire('Удалено!', '', 'success')
      } catch (error) {
        let errorText = ''
        if (typeof (error) === 'string') {
          errorText = error
        }
        Swal.fire('Ошибка обновления данных', errorText, 'error')
        console.log(error)
      } finally {
        this.filterOnlyRemovedNull()
        this.loadingDel = false
      }
    },
    async AfficheupdateOrder (affichesUpdateOrderPost: AffichesUpdateOrderPost) {
      this.loadingDel = true
      try {
        await AfficheupdateOrder(affichesUpdateOrderPost)
      } catch (error) {
        let errorText = ''
        if (typeof (error) === 'string') {
          errorText = error
        }
        Swal.fire('Ошибка обновления данных', errorText, 'error')
        console.log(error)
      } finally {
        this.filterOnlyRemovedNull()
        this.loadingDel = false
      }
    },
    deleteItem (item: any) {
      Swal.fire({
        title: 'Уверены, что хотите удалить ?',
        showDenyButton: true,
        confirmButtonText: 'Удалить',
        denyButtonText: 'Отменить'
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteItemPost(item)
        } else if (result.isDenied) {
          Swal.fire('Отменено', '', 'info')
        }
      })
    },
    /**
     * @param type
     * 1 - order
     * 2 - published
     * 3 - hot
     */
    itemSaveHotOrderPublish (item: any, type: number) {
      this.affichesUpdateOrderPost.id = item.id
      this.affichesUpdateOrderPost.order = item.order
      this.affichesUpdateOrderPost.published = null
      this.affichesUpdateOrderPost.hot = null
      if (type === 2) {
        this.affichesUpdateOrderPost.published = item.published
      } else if (type === 3) {
        this.affichesUpdateOrderPost.hot = item.hot
      }
      this.AfficheupdateOrder(this.affichesUpdateOrderPost)
    },
    changeArhive () {
      this.selectCategory(this.selectedCategory)
    },
    selectAffiche (item: any) {
      this.$router.push({ name: 'Affiche', params: { id: item } })
    },
    selectTopic (item: any) {
      this.topicSelect = this.topics.find((el: any) => el.id === item).name
    },
    selectPublished (item: any) {
      this.publishedSelect = this.publishedTypes.find((el: any) => el.id === item).id
    },
    selectHot (item: any) {
      this.hotSelect = this.hotTypes.find((el: any) => el.id === item).id
    },
    /**
     * filter deleted affiches
     * build topics from affiches
     * sort affiches by id, order
     */
    filterOnlyRemovedNull () {
      this.topics = [{ id: 0, name: 'Все' }]
      this.affiches = this.affiches.filter((el: any) => el.removed === null)
      this.affiches.forEach((element: any) => {
        if (!this.topics.find((el: any) => el.id === element.topic.id)) {
          this.topics.push({ id: element.topic.id, name: element.topic.name })
        }
      })
      this.affiches = this.sort(this.affiches, 'id', 'desc')
      this.affiches = this.sort(this.affiches, 'order', 'desc')
    },
    sort (data: any, key: string, order: string) {
      data.sort((a: any, b: any) => {
        if (a[key] < b[key]) {
          return order === 'asc' ? 1 : -1
        }
        if (a[key] > b[key]) {
          return order === 'desc' ? 1 : -1
        }
        return 0
      })
      return data
    },
    changeBlock () {
      if (this.infoFood) {
        this.foodSettingSavePost.value = 0
      } else {
        this.foodSettingSavePost.value = 1
      }
      this.saveFoodSetting(this.foodSettingSavePost)
    }
  },
  mounted () {
    this.loadFoodSetting()
    this.topics.push({ id: 0, name: 'Все' })
    let categoryId = (this.$props.categoryid === undefined) ? 0 : parseInt(this.$props.categoryid)
    if (this.user.person.userType === '20') {
      categoryId = 5
      this.categories = []
    }
    this.loadCategories(categoryId)
  }
})
