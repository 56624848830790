






















































































































import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import { catsave, checkfordeletetopic } from '@/store/productSections/api-requests'
import LoadingBar from '@/components/partials/components/LoadingBar.vue'
import Swal from 'sweetalert2'

export type BaseData = { // todo replace
  selectedItem: number;
  items: any;
  postForm: any;
  dialog: any;
  dialogTopicName: string;
  dialogState: string;
  editTopicItemId: number | null;
  loading: boolean;
}

export default Vue.extend({

  data (): BaseData {
    return {
      selectedItem: 0,
      items: [],
      postForm: [],
      dialog: false,
      dialogTopicName: '',
      dialogState: 'create',
      editTopicItemId: null,
      loading: false
    }
  },
  components: {
    LoadingBar
  },
  computed: {
    ...mapGetters({ user: 'user/user' }),
    ...mapGetters({ productSections: 'productSections/productSections' })
  },
  methods: {
    ...mapActions('productSections', { productSectionsShow: 'show' }),
    async getItems () {
      let result
      try {
        result = await this.productSectionsShow()
      } catch (error) {
        console.log(error)
      } finally {
        if (result) {
          console.log(result)
          const unsortedItems = JSON.parse(JSON.stringify(result))
          this.items = this.sortItems(unsortedItems)
        }
        this.loading = false
      }
    },
    getSortedData (data: any, prop: string, isAsc: boolean) {
      return data.sort((a: any, b: any) => {
        return (a[prop] < b[prop] ? -1 : 1) * (isAsc ? 1 : -1)
      })
    },
    sortItems (unsortedItems: any) {
      unsortedItems.map((product: any) => {
        product.topics = this.getSortedData(product.topics, 'order', true)
        return product
      })

      return this.getSortedData(unsortedItems, 'order', true)
    },
    openDialog (item?: any) {
      this.dialog = true
      if (item) {
        this.dialogState = 'update'
        this.dialogTopicName = item.name
        this.editTopicItemId = item.id
      } else {
        this.dialogState = 'create'
        this.dialogTopicName = ''
        this.editTopicItemId = null
      }
    },
    createTopic () {
      let unremovedItemsOrderCount = 0
      this.items[this.selectedItem].topics.forEach((el: any) => {
        if (!el.removed) {
          unremovedItemsOrderCount++
        }
      })
      const newItems = this.items
      newItems[this.selectedItem].topics.push({
        id: null,
        name: this.dialogTopicName,
        order: unremovedItemsOrderCount + 1,
        items: []
      })
      this.dialog = false
      console.log(newItems)
      this.saveItems(newItems)
    },
    editTopic () {
      this.items[this.selectedItem].topics.map((topic: any) => {
        if (topic.id === this.editTopicItemId) {
          topic.name = this.dialogTopicName
        }
        return topic
      })
      this.dialog = false
      this.saveItems(this.items)
    },
    deleteTopic (id: number) {
      const items = this.items
      const item = items[this.selectedItem].topics.find((topic: any) => topic.id === id)
      item.order = 999999
      item.removed = new Date().toISOString().substr(0, 10)
      this.items = this.sortItems(items)
      this.reassignOrder()
      Swal.fire('Раздел удален!', '', 'success')
    },
    reassignOrder () {
      const items = JSON.parse(JSON.stringify(this.items))
      items[this.selectedItem].topics.forEach((topic: any, i: number) => {
        topic.order = i
        return topic
      })
      this.saveItems(items)
    },
    moveElement (clickEvent: Event, i: number, direction: number, nested?: boolean) {
      clickEvent.preventDefault()
      clickEvent.stopPropagation()
      const modifiedItems = JSON.parse(JSON.stringify(this.items))
      const indexB = i + direction
      const swap = (input: any, indexA: any, indexB: any) => {
        const elem1Order = input[indexA].order
        input[indexA].order = input[indexB].order
        input[indexB].order = elem1Order
        return input
      }
      let items
      if (nested) {
        const arrOfNestedItems = modifiedItems[this.selectedItem].topics
        const unsortedItems = swap(arrOfNestedItems, i, indexB)
        modifiedItems[this.selectedItem].topics = this.getSortedData(unsortedItems, 'order', true)
        items = modifiedItems
      } else {
        const unsortedItems = swap(modifiedItems, i, indexB)
        items = this.getSortedData(unsortedItems, 'order', true)
        this.selectedItem = indexB
      }
      this.saveItems(items)
    },
    async saveItems (categoriesWithTopics: any) {
      this.loading = true
      let result
      try {
        result = await catsave({ categories: categoriesWithTopics })
      } catch (error) {
        console.log(error)
      } finally {
        if (result) {
          console.log(result)
          this.getItems()
        }
      }
    },
    async checkForDeleteTopic (id: number) {
      let result
      try {
        result = await checkfordeletetopic(id)
      } catch (error) {
        if (error.response) {
          Swal.fire('ОШИБКА', error.response.data.errorMessage, 'error')
        }
      } finally {
        if (result) {
          this.deleteConfirm(id)
        }
      }
    },
    deleteConfirm (id: number) {
      Swal.fire({
        title: 'Вы уверены, что хотите удалить раздел?',
        text: 'Эта опреация необратима!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Да, удалить!',
        cancelButtonText: 'Отмена'
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteTopic(id)
        }
      })
    }
  },
  mounted () {
    this.getItems()
  }
})
