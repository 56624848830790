<template>
  <v-container class="py-4">
    <LoadingBar :loading="loading"></LoadingBar>
    <v-row class="justify-content-center">
      <v-col cols="6"
        align="end">
        <h1 class="text-end"> Цены </h1>
      </v-col>
      <v-col cols="2">
        <v-select
          :items="computedRubrics"
          v-model="categorySelected"
          @change="getItems($event)"
          style="width: 120px;"
          item-value="id"
          item-text="name"
        >
        </v-select>
      </v-col>
    </v-row>
    <div class="row">
      <v-col cols="2" v-if="categorySelected === 5">
        <v-btn v-show="false" @click="iikoGetProducts()">Обновить</v-btn>
      </v-col>
      <v-col cols="2" v-if="categorySelected === 2">
        <v-btn :to="{ name: 'SlideAddPrice', params: { categoryId: categorySelected }}">Добавить</v-btn>
      </v-col>
    </div>
    <v-row class="justify-content-center" v-if="categorySelected === 5">
      <v-col>
        <v-data-table
          dense
          :headers="priceListFoodHeaders"
          :items="computedMainItems"
          item-key="id"
          class="elevation-1"
        >
          <template v-slot:[`body.prepend`]>
            <tr>
              <th>
                <v-select :items="computedTopicList" style="width: 120px; margin: 0 auto;" item-value="id" item-text="name" :value="0" @change="filterByTopic($event)"></v-select>
              </th>
              <th text-align="center">
                <v-text-field style="width: 120px; margin: 0 auto;" @keyup="filterByName($event)"></v-text-field>
              </th>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td text-align="center">
                <v-text-field style="width: 80px; margin: 0 auto;" @keyup="filterByIiko($event)"></v-text-field>
              </td>
            </tr>
          </template>
          <template v-slot:[`item.name`]="{item}">
            <div class="text-center">
              <v-menu>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ item.name }}
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item>
                    <v-list-item-title><v-btn :to="{ name: 'PriceEdit', params: { id: item.id, topicId: item.topic.id }}">Изменить</v-btn></v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title><v-btn :to="{ name: 'PriceHistory', params: { id: item.id }}">История</v-btn></v-list-item-title>
                  </v-list-item>
                  <!-- <v-list-item>
                    <v-list-item-title><v-btn :to="{ name: 'PriceBallance', params: { id: item.id }}">Остатки</v-btn></v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title><v-btn :to="{ name: 'PriceDop', params: { id: item.id }}">Допы</v-btn></v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title><v-btn :to="{ name: 'PriceFoto', params: { id: item.id }}">Фото</v-btn></v-list-item-title>
                  </v-list-item> -->
                </v-list>
              </v-menu>
            </div>
          </template>
          <template v-slot:[`item.dop`]="{item}">
            <v-checkbox
              disabled
              v-model="item.dop"
              ></v-checkbox>
          </template>
          <template v-slot:[`item.picture`]="{item}">
            <v-checkbox
              disabled
              v-model="item.picture"
              ></v-checkbox>
          </template>
          <template v-slot:[`item.published`]="{item}">
            <v-checkbox
              disabled
              v-model="item.published"
              ></v-checkbox>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row class="justify-content-center" v-if="categorySelected === 2">
      <v-col>
        <v-data-table
          dense
          :headers="priceListSlideHeaders"
          :items="computedMainItems"
          item-key="id"
          class="elevation-1"
          :hide-default-header="true"
        >
          <template v-slot:header="{ }">

            <thead class="v-data-table-header">
              <tr>
                <th class="text-center" style="border-bottom:0">Раздел</th>
                <th class="text-center" style="border-bottom:0">Название услуги</th>
                <th colspan="2" class="text-center parent-header">Цена</th>
                <th colspan="2" class="text-center parent-header">Дисконт</th>
                <th colspan="2" class="text-center parent-header">Шаблон</th>
              </tr>
              <tr>
                <th>
                  <v-select :items="computedTopicList" style="width: 120px; margin: 0 auto;" item-value="id" item-text="name" :value="0" @change="filterByTopic($event)"></v-select>
                </th>
                <th text-align="center">
                  <v-text-field style="width: 120px; margin: 0 auto;" @keyup="filterByName($event)"></v-text-field>
                </th>

                <th class="text-center parent-header">
                  День
                </th>
                <th class="text-center parent-header">
                  Ночь
                </th>
                <th class="text-center parent-header">
                  День
                </th>
                <th class="text-center parent-header">
                  Ночь
                </th>
                <th class="text-center parent-header">
                  День
                </th>
                <th class="text-center parent-header">
                  Ночь
                </th>
              </tr>

            </thead>
          </template>
          <template v-slot:[`item.name`]="{item}">
            <div class="text-center">
              <v-menu>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ item.name }}
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item>
                    <v-list-item-title><v-btn :to="{ name: 'SlideAddPrice', params: { id: item.id, topicName: item.topicName }}">Изменить</v-btn></v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title><v-btn :to="{ name: 'SlideHistory', params: { id: item.id }}">История</v-btn></v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </template>
          <template v-slot:[`item.discDay`]="{item}">
            {{item.discDay}}
          </template>

          <template v-slot:[`item.dop`]="{item}">
            <v-checkbox
              disabled
              v-model="item.dop"
              ></v-checkbox>
          </template>
          <template v-slot:[`item.foto`]="{item}">
            <v-checkbox
              disabled
              v-model="item.foto"
              ></v-checkbox>
          </template>
          <template v-slot:[`item.menu`]="{item}">
            <v-checkbox
              disabled
              v-model="item.menu"
              ></v-checkbox>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import LoadingBar from '@/components/partials/components/LoadingBar.vue'
import { getCategoryItemsApi, iikoGetProductsApi } from '@/store/pricelist/api-requests'
import { show as getCategoriesApi } from '@/store/affiche/categories/api-requests'
import Swal from 'sweetalert2'
export default Vue.extend({
  props: {
    categorySelectedFromRoute: {
      type: Number,
      require: false
    }
  },
  components: {
    LoadingBar
  },
  data () {
    return {
      categoryItems: [],
      loading: false,
      categorySelected: 0,
      rubrics: [],
      topicFilter: null,
      nameFilter: null,
      iikoFilter: null
    }
  },
  computed: {
    ...mapGetters({ user: 'user/user' }),
    priceListFoodHeaders () {
      return [
        { text: 'Раздел', align: 'center', value: 'section', sortable: false },
        { text: 'Наименование', value: 'name', sortable: false },
        { text: 'Выход', value: 'weight', sortable: false },
        { text: 'Цена', value: 'price', sortable: false },
        { text: 'Допы', value: 'dop', sortable: false },
        { text: 'Фото', value: 'picture', sortable: false },
        { text: 'Наличие в меню', value: 'published', sortable: false },
        { text: 'ID IIKO', value: 'iiko', sortable: false }
      ]
    },
    priceListSlideHeaders () {
      return [
        { text: 'Раздел', align: 'center', value: 'topicName', sortable: false },
        { text: 'Наименование\nуслуги', align: 'center', value: 'name', sortable: false },
        { text: 'День', value: 'dayPrice', align: 'center', sortable: false },
        { text: 'Ночь', value: 'nightPrice', align: 'center', sortable: false },
        { text: 'День', value: 'discDay', align: 'center', sortable: false },
        { text: 'Ночь', value: 'discNight', align: 'center', sortable: false },
        { text: 'День', value: 'discDayTempl', align: 'center', sortable: false },
        { text: 'Ночь', value: 'discNightTempl', align: 'center', sortable: false }
      ]
    },
    computedTopicList () {
      const arr = this.categoryItems.map((item) => {
        return { name: item.topicName, id: item.topicName }
      })
      arr.unshift({ name: 'Все', id: 0 })
      return arr
    },
    computedMainItems () {
      let topicFiltered = []
      if (this.topicFilter !== 0) {
        topicFiltered = this.categoryItems.filter(el => el.topicName === this.topicFilter)
      } else {
        topicFiltered = this.categoryItems
      }
      if (this.nameFilter) {
        topicFiltered = topicFiltered.filter((item) => {
          return this.nameFilter.toLowerCase().split(' ').every(v => item.name.toLowerCase().includes(v))
        })
      }
      if (this.iikoFilter) {
        topicFiltered = topicFiltered.filter((item) => {
          return this.iikoFilter.split(' ').every(v => item.iiko.includes(v))
        })
      }
      return topicFiltered
    },
    computedRubrics () {
      return this.rubrics.filter(obj => obj.name === 'Каток' || obj.name === 'Еда')
    }
  },
  methods: {
    filterByTopic (value) {
      this.topicFilter = value
    },
    filterByName (ev) {
      this.nameFilter = ev.target.value
    },
    filterByIiko (ev) {
      this.iikoFilter = ev.target.value
    },
    async getCategories () {
      let result
      try {
        result = await getCategoriesApi()
      } catch (error) {
        console.log(error)
      } finally {
        this.rubrics = result
      }
    },
    async getItems (categoryId) {
      if (categoryId) {
        this.loading = true
        this.topicFilter = null
        this.nameFilter = null
        this.iikoFilter = null
        let result
        try {
          result = await getCategoryItemsApi({ categoryId: categoryId, discounts: categoryId === 2 ? true : null, prices: categoryId === 2 ? true : null })
        } catch (error) {
          console.log(error)
        } finally {
          // console.log(result)
          this.loading = false
          if (categoryId === 2) {
            this.computedItemsSlide(result)
          }
          if (categoryId === 5) {
            this.computedItemsFood(result)
          }
        }
      }
    },
    computedItemsSlide (items) {
      const newItems = items.map((item) => {
        // discount
        const discDay = item.productDiscounts.find(el => el.productCut.cutId === '2452')
        if (discDay) {
          // const issetUndeleted =
          item.discDay = discDay.discountItems.find(x => x.removed === null) ? 'да' : '-'
          item.discDayTempl = discDay.discountTemplate ? discDay.discountTemplate.name : '-'
        } else {
          item.discDay = '-'
          item.discDayTempl = '-'
        }
        const discNight = item.productDiscounts.find(el => el.productCut.cutId === '2453')
        if (discNight) {
          item.discNight = discNight.discountItems.find(x => x.removed === null) ? 'да' : '-'
          item.discNightTempl = discNight.discountTemplate ? discNight.discountTemplate.name : '-'
        } else {
          item.discNight = '-'
          item.discNightTempl = '-'
        }
        // prices
        item.dayPrice = '-'
        item.nightPrice = '-'
        const priceDay = item.productCuts.find(el => el.cutId === '2452')
        if (priceDay) {
          item.dayPrice = Number(priceDay.marketProductCutPrices[0].price) > 0 ? Number(priceDay.marketProductCutPrices[0].price) : '-'
        }
        const priceNight = item.productCuts.find(el => el.cutId === '2453')
        if (priceNight) {
          item.nightPrice = Number(priceNight.marketProductCutPrices[0].price) > 0 ? Number(priceNight.marketProductCutPrices[0].price) : '-'
        }

        return item
      })
      this.categoryItems = newItems
      this.filterByTopic(0)
    },
    computedItemsFood (items) {
      // items.push({ topicName: 'tt', name: 'tt', weight: 235, price: 777, dop: true, picture: true, published: true, login: 2222 }) // to test
      const newItems = items.map((item) => {
        item.section = item.topicName
        item.dop = '-'
        item.iiko = isNaN(item.login) ? '-' : item.login
        return item
      })
      this.categoryItems = newItems
      this.filterByTopic(0)
    },
    // ***********FOOD************
    async iikoGetProducts () {
      let result
      try {
        result = await iikoGetProductsApi()
      } catch (error) {
        console.log(error)
      } finally {
        console.log(result)
        if (result === true) {
          this.notify('Обновление успешно произведено')
        } else {
          this.notify('Ошибка обновления')
        }
      }
    },
    notify (text) {
      Swal.fire({
        title: text,
        confirmButtonText: 'Ок'
      })
    }
  },
  mounted () {
    this.getCategories()
    if (this.categorySelectedFromRoute !== undefined) {
      this.categorySelected = this.categorySelectedFromRoute
    } else {
      this.categorySelected = 2
    }
    if (this.user.person.userType === '20') {
      this.categorySelected = 5
    }

    this.getItems(this.categorySelected)
  },
  beforeRouteEnter (to, from, next) {
    let category
    if (from.name === 'SlideHistory' || from.name === 'SlideAddPrice') {
      category = 2
    }
    if (from.name === 'PriceHistory') {
      category = 5
    }
    to.params.categorySelectedFromRoute = category
    next()
  }
})
</script>
