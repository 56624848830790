<template>
  <v-container class="py-4">
    <LoadingBar :loading="loading"></LoadingBar>
    <v-row class="justify-content-center">
      <div class="col-">
        <v-btn @click="goBack()" class="mb-4">
          <v-icon>mdi-keyboard-backspace</v-icon>
          Назад
        </v-btn>
      </div>
      <div class="col-12">
        <h1 v-if="propid === null" class="text-center"> Добавление услуги </h1>
        <h1 v-else class="text-center"> Изменение услуги </h1>
      </div>
    </v-row>
    <v-row class="justify-content-center">
      <v-col align="center">
        <div class="d-inline-flex align-center">
          Название <span style="color:red;margin-left:5px">*</span>
          <v-text-field style="width: 200px;margin-left:20px" v-model="itemObject.name"></v-text-field>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col align="center">
        <div class="d-inline-flex align-center">
          Раздел  <span style="color:red;margin-left:5px">*</span>
          <v-text-field v-if="propid !== null"
          style="width: 200px;margin-left:20px" disabled :value="topicName"></v-text-field>
          <v-select
            v-if="propid === null"
            :items="categoryTopics"
            style="width: 200px;margin-left:20px"
            item-value="id"
            item-text="name"
            v-model="itemObject.topicId"
          >
          </v-select>
        </div>
      </v-col>
    </v-row>
    <v-row class="justify-content-center">
      <v-col align="center">
        <div class="d-inline-flex align-center">

          <v-radio-group v-model="itemObject.settingId6" row class="ml-5">
            <v-radio
              label="Продажа шкафчиков"
              value="0"
            ></v-radio>
            <v-radio
              label="Продажа катания"
              value="1"
            ></v-radio>
            <v-radio
              label="Разовая услуга"
              value="2"
            ></v-radio>
          </v-radio-group>
        </div>
      </v-col>
    </v-row>
    <v-row class="justify-content-center">
      <v-col align="center">
        <div class="d-inline-flex align-center">
          Ед.измерения:
          <v-radio-group v-model="itemObject.settingId5" row class="ml-5">
            <v-radio
              label="Сеанс"
              value="0"
            ></v-radio>
            <v-radio
              label="Час"
              value="1"
            ></v-radio>
          </v-radio-group>
        </div>
      </v-col>
    </v-row>
    <v-row class="justify-content-center" v-if="itemObject.settingId5 == 1">
      <v-col align="center">
        <div class="d-inline-flex align-center">
          Привязывать к сеансу катания
          <v-radio-group v-model="itemObject.settingId7" row class="ml-5" @change="changeSettingId7()">
            <v-radio
              label="Да"
              value="1"
            ></v-radio>
            <v-radio
              label="Нет"
              value="0"
            ></v-radio>
          </v-radio-group>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="itemObject.settingId5 != 1 || (itemObject.settingId5 == 1 && itemObject.settingId7 == 1)" class="justify-content-center">
      <v-col align="center">
        <div class="d-inline-flex align-center">
          <div class="mr-5">Начало:</div>
          <div class="flex-direction-column">
            <v-checkbox class="mt-0"
              v-model="itemObject.settingId2"
              label="По началу сеанса"
            ></v-checkbox>
            <v-checkbox class="mt-0"
              v-model="itemObject.settingId3"
              label="За 10 минут"
            ></v-checkbox>
            <v-checkbox class="mt-0"
              v-model="itemObject.settingId4"
              label="За 20 минут"
            ></v-checkbox>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="itemObject.settingId5 == 1 && itemObject.settingId7 == 0" class="justify-content-center">
      <v-col align="center">
        <div v-if="loadingShedule" class="d-inline-flex align-center">
          <v-progress-circular
            :size="70"
            :width="7"
            color="green"
            indeterminate
          ></v-progress-circular>
        </div>

        <div v-else class="d-inline-flex align-center">
          <div class="mr-5">Расписание:</div>
          <div class="flex-direction-column">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-center">
                      День недели
                    </th>
                    <th class="text-center">
                      Диапазон
                    </th>
                    <th class="text-center">
                      Особая дата
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="item in shedules">
                    <tr v-if="item.date == null && item.status == 1" :key="item.id">
                      <td>{{ getDayName(item.day) }}</td>
                      <td>{{ formatTime(item.startPeriod) }} - {{ formatTime(item.endPeriod) }}</td>
                      <td>
                        <span v-if="shedules.find(el => el.day == item.day && el.date != null && el.status == 1)">Есть</span>
                        <span v-else>Нет</span>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </template>
            </v-simple-table>
            <v-btn @click="routeToShedule(itemObject.id)">Настроить расписание</v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row justify="center" >
      <v-col cols="8">
        <v-tabs @change="tabChange($event)">
          <v-tab>День</v-tab>
          <v-tab>Ночь</v-tab>
<!-- DAY -->
          <v-tab-item class="py-5">
            <v-card class="mx-2 px-2">
              <div class="row align-center">
                <div class="col-8 d-flex align-center">
                  <div>
                    Розн. цена <span style="color:red">*</span>
                  </div>
                  <v-text-field
                  style="max-width:120px;margin-left:20px"
                  type="number"
                  v-model="dayPrice"
                  ></v-text-field>
                  <div>
                    руб.
                  </div>
                </div>
                <div class="col-4 d-flex justify-content-center">
                  <v-checkbox
                    v-model="dayDiscountFlag"
                    label="Дисконт"
                  ></v-checkbox>
                </div>
              </div>
              <v-row v-if="dayDiscountFlag">
                <v-col>
                  <div class="d-inline-flex align-center">
                    Вид:
                    <v-radio-group v-model="dayType" row class="ml-5">
                      <v-radio
                        label="%"
                        value="1"
                      ></v-radio>
                      <v-radio
                        label="По кол-ву"
                        value="2"
                      ></v-radio>
                    </v-radio-group>
                  </div>
                </v-col>
                <v-col align="right">
                  <div class="d-inline-flex align-center">
                    <v-select
                      :items="applyToVarians"
                      style="width: 120px;margin-left:20px"
                      item-value="id"
                      item-text="name"
                      v-model="dayApplyTo"
                    >
                    </v-select>
                  </div>
                </v-col>
              </v-row>
              <v-row v-if="dayDiscountFlag">
                <v-col>
                  <SlideAddPriceItems :id="2452" :items="dayDiscountItems" :sendFatherUpdatedItems="handleUpdateItems"></SlideAddPriceItems>
                </v-col>
              </v-row>
              <div class="row" v-if="dayDiscountFlag">
                <div class="col-6">

                  <v-btn @click="openModal('load')">Загрузить из шаблона</v-btn>
                </div>
                <div class="col-6 text-right">
                  <v-btn @click="preSaveTemplateValidate()">Сохранить в шаблон</v-btn>
                </div>
              </div>
            </v-card>
          </v-tab-item>
<!-- NIGHT7 -->
          <v-tab-item class="py-5">
            <v-card class="mx-2 px-2">
                <div class="row align-center">
                  <div class="col-8 d-flex align-center">
                    <div>
                      Розн. цена
                    </div>
                    <v-text-field
                    style="max-width:120px;margin-left:20px"
                    type="number"
                    v-model="nightPrice"
                     ></v-text-field>
                    <div>
                      руб.
                    </div>
                  </div>
                  <div class="col-4 d-flex justify-content-center">
                    <v-checkbox
                      v-model="nightDiscountFlag"
                      label="Дисконт"
                    ></v-checkbox>
                  </div>
                </div>
                <v-row v-if="nightDiscountFlag">
                  <v-col>
                    <div class="d-inline-flex align-center">
                      Вид:
                      <v-radio-group v-model="nightType" row class="ml-5">
                        <v-radio
                          label="%"
                          value="1"
                        ></v-radio>
                        <v-radio
                          label="По кол-ву"
                          value="2"
                        ></v-radio>
                      </v-radio-group>
                    </div>
                  </v-col>
                  <v-col align="right">
                    <div class="d-inline-flex align-center">
                      <v-select
                        :items="applyToVarians"
                        style="width: 120px;margin-left:20px"
                        item-value="id"
                        item-text="name"
                        v-model="nightApplyTo"
                      >
                      </v-select>
                    </div>
                  </v-col>
                </v-row>
                <v-row v-if="nightDiscountFlag">
                  <v-col>
                    <SlideAddPriceItems :id="2453" :items="nightDiscountItems" :sendFatherUpdatedItems="handleUpdateItems"></SlideAddPriceItems>
                  </v-col>
                </v-row>

                <div class="row" v-if="nightDiscountFlag">
                  <div class="col-6">

                    <v-btn @click="openModal('load')">Загрузить из шаблона</v-btn>
                  </div>
                  <div class="col-6 text-right">
                    <v-btn @click="preSaveTemplateValidate()">Сохранить в шаблон</v-btn>
                  </div>
                </div>
            </v-card>
          </v-tab-item>
        </v-tabs>

      </v-col>
    </v-row>
    <v-row>
      <v-col align="center">
        <v-btn @click="preSaveValidate()">Сохранить</v-btn>
      </v-col>
    </v-row>
<!-- MODAL -->
    <v-dialog
      v-model="toggleModal"
      max-width="500px"
      persistent
    >
      <v-card>
        <v-container v-if="modalContentState === 1">
          <v-card-title>
            Выберите шаблон
          </v-card-title>
          <div v-if="!templates" style="text-align:center">Нет доступных шаблонов</div>
          <v-data-table v-if="templates"
            :headers="templatesHeader"
            :items="templates"
            :items-per-page="5"
            class="elevation-1"
            item-key="id"
          >
            <template v-slot:[`item`]="{ item }">
              <tr
                @click="selectTemplate(item)"
                :class="selectedTemplate.id === item.id ? 'grey' : ''"
              >
                <td class="text-xs-right">{{ item.name }}</td>
                <td class="text-xs-right">{{ item.type }}</td>
              </tr>
            </template>
          </v-data-table>
          <v-card-actions style="justify-content: flex-end;">
            <v-btn v-if="templates"
              color="primary"
              @click="assignTemplate(selectedTemplate.id)"
            >
              Загрузить
            </v-btn>
            <v-btn
              color="primary"
              text
              @click="closeModal()"
            >
              Отмена
            </v-btn>
          </v-card-actions>
        </v-container>

        <v-container v-if="modalContentState === 2">
          <v-card-title>
            Сохранение шаблона
          </v-card-title>
          <v-card-subtitle>Введите название для нового шаблона</v-card-subtitle>
          <v-text-field v-model="newTemplateName"></v-text-field>
          <v-card-actions style="justify-content: flex-end;">
            <v-btn
              color="primary"
              @click="saveTemplate()"
            >
              Ок
            </v-btn>
            <v-btn
              color="primary"
              text
              @click="closeModal()"
            >
              Отмена
            </v-btn>
          </v-card-actions>
        </v-container>

      </v-card>
    </v-dialog>
<!-- MODAL -->
  </v-container>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import LoadingBar from '@/components/partials/components/LoadingBar.vue'
import { getitems as getTopicsApi } from '@/store/affiche/affiche/api-requests'
import { getCategoryItemApi, updateItemApi, createItemApi, getCategotiesTopicsApi, getTemplatesUrlApi, getTemplateByIdApi } from '@/store/pricelist/api-requests'
import { show as getSheduleItems } from '@/store/shedules/api-requests'
import SlideAddPriceItems from '@/components/pricelist/SlideAddPriceItems'
import { create as saveTemplateApi } from '@/store/discountTemplates/api-requests'
import Swal from 'sweetalert2'
import { DaysWeek } from '@/types/vue-enums'

export default Vue.extend({
  components: {
    LoadingBar,
    SlideAddPriceItems
  },
  data () {
    return {
      itemObject: {
        id: null,
        name: null,
        topic: null,
        settingId2: true,
        settingId3: true,
        settingId4: true,
        settingId5: '0',
        settingId6: '0',
        settingId7: '0'
      },
      templatesHeader: [
        { text: 'Название', value: 'name' },
        { text: 'Вид', value: 'type' }
      ],
      shedules: [],
      selectedTemplate: { id: 0 },
      toggleModal: false,
      modalContentState: 1,
      tab: 0,
      propid: null,
      loading: false,
      loadShedule: false,
      loadingShedule: false,
      applyToVarians: [
        { id: 0, name: 'Ко всем' },
        { id: 1, name: 'Ко всем кроме первого' },
        { id: 2, name: 'Отдельно по диапазону' }
      ],
      discountsHeader: [
        { text: 'От', value: 'limitFrom' },
        { text: 'До', value: 'limitTo' },
        { text: 'Цена', value: 'price' }
      ],
      categoryTopics: [],
      rawRroductSetting: [],
      checkbox: null,
      day: null,
      night: null,
      dayDiscountFlag: false,
      nightDiscountFlag: false,
      dayPrice: null,
      nightPrice: null,
      dayType: '1',
      dayApplyTo: 0,
      nightType: '1',
      nightApplyTo: 0,
      dayDiscountItems: [],
      nightDiscountItems: [],
      activeTab: 0,
      newTemplateName: null,
      dayDiscountTemplateId: null,
      nightDiscountTemplateId: null,
      templates: []
    }
  },
  props: {
    id: [Number, String],
    topicName: {
      type: String,
      required: false
    },
    categoryId: {
      type: Number,
      required: false
    }
  },
  computed: {
    ...mapGetters({ user: 'user/user' })
  },
  methods: {
    preSaveTemplateValidate () {
      let filteredDiscountItems = []
      if (this.activeTab === 0) {
        filteredDiscountItems = this.dayDiscountItems.filter(Boolean)
      } else if (this.activeTab === 1) {
        filteredDiscountItems = this.nightDiscountItems.filter(Boolean)
      }
      if (filteredDiscountItems.length < 1) {
        Swal.fire({
          title: 'Ошибка!',
          text: 'Добавьте скидки, чтобы сохранить',
          confirmButtonText: 'Ок'
        })
      } else {
        this.openModal('save')
      }
    },
    async saveTemplate () {
      const saveTemplateObject = {}
      let cut
      if (this.activeTab === 0) {
        cut = 'day'
        const filteredDiscountItems = this.dayDiscountItems.filter(Boolean)
        saveTemplateObject.name = this.newTemplateName
        saveTemplateObject.price = this.dayPrice
        saveTemplateObject.type = this.dayType
        saveTemplateObject.applyTo = this.dayApplyTo
        saveTemplateObject.templateItems = filteredDiscountItems
      } else if (this.activeTab === 1) {
        cut = 'night'
        const filteredDiscountItems = this.nightDiscountItems.filter(Boolean)
        saveTemplateObject.name = this.newTemplateName
        saveTemplateObject.price = this.nightPrice
        saveTemplateObject.type = this.nightType
        saveTemplateObject.applyTo = this.nightApplyTo
        saveTemplateObject.templateItems = filteredDiscountItems
      }
      let result
      try {
        result = await saveTemplateApi(saveTemplateObject)
      } catch (error) {
        console.log(error)
      } finally {
        this.newTemplateName = null
        if (cut === 'day') {
          this.dayDiscountTemplateId = result
        } else if (cut === 'night') {
          this.nightDiscountTemplateId = result
        }
      }
      this.closeModal()
    },
    tabChange (ev) {
      this.activeTab = ev
    },
    openModal (type) {
      this.modalContentState = type === 'load' ? 1 : 2
      this.toggleModal = true
    },
    closeModal () {
      this.toggleModal = false
    },
    selectTemplate (selectedTemplate) {
      this.selectedTemplate = selectedTemplate
      console.log(selectedTemplate)
    },
    async assignTemplate (id) {
      let result
      try {
        result = await getTemplateByIdApi({ id: id })
      } catch (error) {
        console.log(error)
      } finally {
        const template = result[0]
        if (this.activeTab === 0) {
          const dayDiscountItemsToRemove = this.dayDiscountItems.map(el => {
            el.removed = 1
            return el
          })
          this.dayType = template.type.toString()
          this.dayApplyTo = template.applyTo
          this.dayDiscountTemplateId = template.id
          const templateItems = template.templateItems.map((el) => {
            delete el.discountTemplateId
            el.id = null
            // el.removed = null
            return el
          })
          this.dayDiscountItems = [...templateItems, ...dayDiscountItemsToRemove]
        } else if (this.activeTab === 1) {
          const nightDiscountItemsToRemove = this.nightDiscountItems.map(el => {
            el.removed = 1
            return el
          })
          this.nightType = template.type.toString()
          this.nightApplyTo = template.applyTo
          this.nightDiscountTemplateId = template.id
          const templateItems = template.templateItems.map((el) => {
            delete el.discountTemplateId
            el.id = null
            // el.removed = null
            return el
          })
          this.nightDiscountItems = [...templateItems, ...nightDiscountItemsToRemove]
        }
        this.toggleModal = false
      }
    },
    async getTopics () {
      let result
      try {
        result = await getCategotiesTopicsApi({ categoryId: 2 })
      } catch (error) {
        console.log(error)
      } finally {
        if (result) {
          result.forEach((cat) => {
            if (cat.id === this.categoryId) {
              this.categoryTopics = cat.topics
            }
          })
        }
      }
    },
    async getItem (id) {
      this.loading = true
      let result
      try {
        result = await getCategoryItemApi({ id: id })
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
        if (result) {
          const item = result[0]
          this.itemObject.id = item.id
          this.itemObject.name = item.name
          this.rawRroductSettings = item.productSettings

          item.productSettings.forEach(el => {
            if (el.settingTypeId === 5 || el.settingTypeId === 6 || el.settingTypeId === 7) {
              this.itemObject['settingId' + el.settingTypeId] = el.value
            } else {
              let value
              if (el.value === 'true') {
                value = true
              } else {
                value = false
              }
              this.itemObject['settingId' + el.settingTypeId] = value
            }
          })

          item.productCuts.forEach(el => {
            if (el.cutId === 2452) {
              this.day = el
              this.dayDiscountFlag = el.productDiscount.length > 0
              this.dayPrice = el.marketProductCutPrices[0].price
              if (this.dayDiscountFlag) {
                this.dayDiscountTemplateId = el.productDiscount[0].discountTemplateId
                this.dayType = el.productDiscount[0].type.toString() // this.dayType = '2'
                this.dayApplyTo = el.productDiscount[0].applyTo // this.dayApplyTo = 2
                if (el.productDiscount[0].discountItems.length) {
                  el.productDiscount[0].discountItems.forEach((el, i) => {
                    el.price = Number(el.price) / 100
                    this.dayDiscountItems[i] = el
                  })
                }
              }
            } else if (el.cutId === 2453) {
              this.night = el
              this.nightDiscountFlag = el.productDiscount.length > 0
              this.nightPrice = el.marketProductCutPrices[0].price
              if (this.nightDiscountFlag) {
                this.nightDiscountTemplateId = el.productDiscount[0].discountTemplateId
                this.nightType = el.productDiscount[0].type.toString()
                this.nightApplyTo = el.productDiscount[0].applyTo
                if (el.productDiscount[0].discountItems.length) {
                  el.productDiscount[0].discountItems.forEach((el, i) => {
                    el.price = Number(el.price) / 100
                    this.nightDiscountItems[i] = el
                  })
                }
              }
            }
          })

          if (this.itemObject.settingId5 === '1' && this.itemObject.settingId7 === '0') {
            this.sheduleLoad(this.itemObject.id)
          }
        }
      }
    },
    changeSettingId7 () {
      if (this.itemObject.settingId7 === '0' && !this.loadShedule) {
        this.sheduleLoad(this.itemObject.id)
      }
    },
    async sheduleLoad (productId) {
      this.loadingShedule = true
      try {
        const result = await getSheduleItems({ productId: productId })
        this.shedules = this.sortShedule(result)
      } catch (error) {
        console.log(error.message)
      } finally {
        this.loadShedule = true
        this.loadingShedule = false
      }
    },
    sortShedule (shedules) {
      return shedules.sort(function compare (a, b) {
        if (a.day < b.day) return -1
        if (a.day > b.day) return 1
        return 0
      })
    },
    resetTemplateId () {
      if (this.activeTab === 0) {
        this.dayDiscountTemplateId = null
      } else if (this.activeTab === 1) {
        this.nightDiscountTemplateId = null
      }
    },
    convertPrice (arr) {
      return arr.map((el) => {
        if (el) {
          el.price = el.price * 100
        }
        return el
      })
    },
    validateDiscountItemsIsset (items) {
      let danger = false
      const filteredDiscountItems = items.filter(Boolean)
      if (filteredDiscountItems.length) {
        const issetUndeleted = filteredDiscountItems.find(x => x.removed === null)
        if (!issetUndeleted) {
          danger = true
        }
      } else {
        danger = true
      }
      return danger
    },
    validateDiscountItemsPercent (items, type) {
      let danger = false
      if (type === '1') {
        const issetOverPercent = items.find(el => el.price > 100)
        if (issetOverPercent) {
          danger = true
        }
      }
      return danger
    },
    preSaveValidate () {
      let dangerState = false
      const dangerTexts = []
      if (!this.itemObject.name) {
        dangerState = true
        dangerTexts.push('Добавьте название<br>')
      }
      if (this.categoryId && !this.itemObject.topicId) {
        dangerState = true
        dangerTexts.push('Выберите раздел<br>')
      }
      if (!this.dayPrice && !this.nightPrice) {
        dangerState = true
        dangerTexts.push('Добавьте цену<br>')
      }
      if (this.dayDiscountFlag) {
        if (this.validateDiscountItemsIsset(this.dayDiscountItems)) {
          dangerState = true
          dangerTexts.push('Добавьте варианты дисконта на вкладке "День" или уберите чекбокс "Дисконт"<br>')
        } else if (this.validateDiscountItemsPercent(this.dayDiscountItems, this.dayType)) {
          dangerState = true
          dangerTexts.push('Процент скидки не может быть больше 100 на вкладке "День"<br>')
        }
      }
      if (this.nightDiscountFlag) {
        if (this.validateDiscountItemsIsset(this.nightDiscountItems)) {
          dangerState = true
          dangerTexts.push('Добавьте варианты дисконта на вкладке "Ночь" или уберите чекбокс "Дисконт"<br>')
        } else if (this.validateDiscountItemsPercent(this.nightDiscountItems, this.nightType)) {
          dangerState = true
          dangerTexts.push('Процент скидки не может быть больше 100 на вкладке "Ночь"<br>')
        }
      }
      if (dangerState) {
        Swal.fire({
          title: 'Ошибка!',
          html: dangerTexts.join(''),
          confirmButtonText: 'Ок'
        })
      } else {
        this.saveWholeItem()
      }
    },
    async saveWholeItem () {
      const saveObject = {}
      saveObject.id = this.id
      saveObject.name = this.itemObject.name
      saveObject.topicId = this.itemObject.topicId

      const set2 = {
        settingTypeId: 2,
        value: this.itemObject.settingId2
      }
      const set3 = {
        settingTypeId: 3,
        value: this.itemObject.settingId3
      }
      const set4 = {
        settingTypeId: 4,
        value: this.itemObject.settingId4
      }
      const set5 = {
        settingTypeId: 5,
        value: this.itemObject.settingId5
      }
      const set6 = {
        settingTypeId: 6,
        value: this.itemObject.settingId6
      }
      const set7 = {
        settingTypeId: 7,
        value: this.itemObject.settingId7
      }
      const productSettings = []
      productSettings.push(set2, set3, set4, set5, set6, set7)

      saveObject.productSettings = productSettings

      const productCuts = []
      if (this.day !== null || this.dayPrice || this.dayDiscountItems.length > 0) {
        const day = {
          cutId: 2452,
          price: Number(this.dayPrice) * 10000,
          discountFlag: this.dayDiscountFlag,
          productDiscount: []
        }
        const dayDiscountItems = this.convertPrice(JSON.parse(JSON.stringify(this.dayDiscountItems)))
        const productDiscountItem = {
          type: this.dayType,
          applyTo: this.dayApplyTo,
          discountItems: dayDiscountItems,
          discountTemplateId: this.dayDiscountTemplateId
        }
        day.productDiscount.push(productDiscountItem)
        productCuts.push(day)
      }
      if (this.night !== null || this.nightPrice || this.nightDiscountItems.length > 0) {
        const night = {
          cutId: 2453,
          price: Number(this.nightPrice) * 10000,
          discountFlag: this.nightDiscountFlag,
          productDiscount: []
        }

        const nightDiscountItems = this.convertPrice(JSON.parse(JSON.stringify(this.nightDiscountItems)))
        const productDiscountItem = {
          type: this.nightType,
          applyTo: this.nightApplyTo,
          discountItems: nightDiscountItems,
          discountTemplateId: this.nightDiscountTemplateId
        }
        night.productDiscount.push(productDiscountItem)
        productCuts.push(night)
      }

      saveObject.productCuts = productCuts
      let result
      let swallThen
      if (this.id) {
        try {
          result = await updateItemApi(saveObject)
        } catch (error) {
          console.log(error)
        } finally {
          console.log(result)
          swallThen = Swal.fire('Успешно сохранено!', '', 'success')
        }
      } else {
        try {
          result = await createItemApi(saveObject)
        } catch (error) {
          console.log(error)
        } finally {
          swallThen = Swal.fire('Успешно сохранено!', '', 'success')
        }
      }
      swallThen.then((result) => {
        if (result.isConfirmed) {
          this.$router.push({ name: 'PriceList' })
        }
      })
    },
    handleUpdateItems (itemsFromChild) {
      if (this.activeTab === 0) {
        this.dayDiscountItems = Object.assign([], itemsFromChild)
      } else if (this.activeTab === 1) {
        this.nightDiscountItems = Object.assign([], itemsFromChild)
      }
      this.resetTemplateId()
    },
    async getTempaltes () {
      let result
      try {
        result = await getTemplatesUrlApi()
      } catch (error) {
        console.log(error)
      } finally {
        this.templates = result
      }
    },
    formatTime (time) {
      return time.slice(0, -3)
    },
    getDayName (day) {
      return DaysWeek[day].toString()
    },
    routeToShedule (productId) {
      this.$router.push({ name: 'Shedule', params: { id: productId.toString() } })
    },
    goBack () {
      this.$router.push({ name: 'PriceList' })
    }
  },
  mounted () {
    console.log(this.categoryId)
    console.log(this.topicName)
    if (this.categoryId === undefined && this.id === undefined) {
      this.$router.go(-1)
    }
    if (this.categoryId) {
      this.getTopics()
    }
    if (this.id) {
      this.propid = this.id
      this.getItem(this.id)
    }
    this.getTempaltes()
  }
})
</script>
