<template>
  <v-container class="py-4">
    <LoadingBar :loading="loading"></LoadingBar>
    <v-row class="justify-content-center">
      <div>
        <v-btn @click="goBack()" class="mb-4">
          <v-icon>mdi-keyboard-backspace</v-icon>
          Назад
        </v-btn>
      </div>
      <div class="col-12">
        <h1 class="text-center"> Настроить диапазоны на дату ({{ item.date }}) </h1>
      </div>
    </v-row>
    <v-row class="justify-content-center">
      <v-col align="center">
        Доступный период
        <v-menu
          ref="menu1"
          v-model="menu1"
          :close-on-content-click="false"
          :nudge-right="40"
          :return-value.sync="item.startPeriod"
          transition="scale-transition"
          offset-y
          max-width="300px"
          min-width="300px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="item.startPeriod"
              prepend-icon="mdi-clock-time-four-outline"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-time-picker
            v-if="menu1"
            format="24hr"
            v-model="item.startPeriod"
            full-width
            @click:minute="$refs.menu1.save(item.startPeriod)"
          ></v-time-picker>
        </v-menu>
        <v-menu
          ref="menu2"
          v-model="menu2"
          :close-on-content-click="false"
          :nudge-right="40"
          :return-value.sync="item.endPeriod"
          transition="scale-transition"
          offset-y
          max-width="300px"
          min-width="300px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="item.endPeriod"
              prepend-icon="mdi-clock-time-four-outline"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-time-picker
            v-if="menu2"
            format="24hr"
            v-model="item.endPeriod"
            full-width
            @click:minute="$refs.menu2.save(item.endPeriod)"
          ></v-time-picker>
        </v-menu>
      </v-col>
      <v-col align="center">
        Дискретность
        <v-radio-group v-model="item.discreteness">
        <v-radio value="15" label="15 мин."></v-radio>
        <v-radio value="30" label="30 мин."></v-radio>
        <v-radio value="60" label="1 час"></v-radio>
    </v-radio-group>
      </v-col>
      <v-col align="right">
      </v-col>
    </v-row>
    <v-row class="justify-content-center">
      <v-col>
        <div class="flex-direction-column">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center">
                    С
                  </th>
                  <th class="text-center">
                    По
                  </th>
                  <th class="text-center">
                    Цена
                  </th>
                  <th class="text-center">
                    Действия
                  </th>
                </tr>
              </thead>
              <tbody>
                <template v-for="item in item.productScheduleItems">
                  <tr v-if="item.date == null && item.status == 1" :key="item.indexItem">
                    <td align="center">{{ item.startRange }}</td>
                    <td align="center">{{ item.endRange }}</td>
                    <td align="center">
                      <label v-if="item.cutId == 2452">День</label>
                      <label v-if="item.cutId == 2453">Ночь</label>
                    </td>
                    <td align="center">
                      <v-btn @click="editRange(item.indexItem)">
                        <v-icon dark>
                          {{ icons.mdiPencil }}
                        </v-icon>
                      </v-btn>
                      <v-btn @click="delRange(item.indexItem)">
                        <v-icon dark>
                          {{ icons.mdiCloseCircleOutline }}
                        </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </template>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </v-col>
    </v-row>
    <v-row class="justify-content-center">
      <v-col align="center">
        <v-menu
          ref="menu3"
          v-model="menu3"
          :close-on-content-click="false"
          :nudge-right="40"
          :return-value.sync="time3"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="time3"
              prepend-icon="mdi-clock-time-four-outline"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-time-picker
            v-if="menu3"
            v-model="time3"
            format="24hr"
            full-width
            @click:minute="$refs.menu3.save(time3)"
          ></v-time-picker>
        </v-menu>
      </v-col>
      <v-col align="center">
        <v-menu
          ref="menu4"
          v-model="menu4"
          :close-on-content-click="false"
          :nudge-right="40"
          :return-value.sync="time4"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="time4"
              prepend-icon="mdi-clock-time-four-outline"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-time-picker
            v-if="menu4"
            v-model="time4"
            format="24hr"
            full-width
            @click:minute="$refs.menu4.save(time4)"
          ></v-time-picker>
        </v-menu>
      </v-col>
      <v-col align="center">
        <v-select
          v-model="selectedItem"
          :items="selectItems"
          item-text="name"
          item-value="id"
        ></v-select>
      </v-col>
      <v-col align="center">
        <v-btn @click="addRange(itemId)">
          {{ nameButton }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="justify-content-center">
      <v-col align="center">
        <v-btn @click="saveItem()">
          Сохранить
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import LoadingBar from '@/components/partials/components/LoadingBar.vue'
import { show as getSheduleItems, sheduleSave } from '@/store/shedules/api-requests'
import { DaysWeek } from '@/types/vue-enums'
import { mdiPencil, mdiCloseCircleOutline } from '@mdi/js'
export default Vue.extend({
  components: {
    LoadingBar
  },
  data () {
    return {
      icons: {
        mdiPencil,
        mdiCloseCircleOutline
      },
      loading: false,
      shedules: [],
      menu1: false,
      menu2: false,
      menu3: false,
      menu4: false,
      time3: null,
      time4: null,
      itemId: null,
      indexItem: 0,
      nameButton: 'Добавить',
      selectItems: [
        { id: 2452, name: 'День' },
        { id: 2453, name: 'Ночь' }
      ],
      selectedItem: 2452
    }
  },
  props: {
    item: {
      require: true
    }
  },
  computed: {
    ...mapGetters({ user: 'user/user' })
  },
  methods: {
    async sheduleLoad (productId) {
      this.loading = true
      try {
        this.shedules = await getSheduleItems({ productId: productId })
      } catch (error) {
        console.log(error.message)
        this.goBack()
      } finally {
        this.loading = false
      }
    },
    async sheduleSave () {
      this.loading = true
      try {
        this.shedules = await sheduleSave({ item: this.item })
      } catch (error) {
        console.log(error.message)
      } finally {
        this.loading = false
        this.$router.push({ name: 'SheduleDate', params: { item: this.item } })
      }
    },
    formatTime (time) {
      return time.slice(0, -3)
    },
    getDayName (day) {
      return DaysWeek[day].toString()
    },
    saveItem () {
      this.sheduleSave()
    },
    addRange () {
      if (this.itemId != null) {
        this.item.productScheduleItems.find(el => el.indexItem === this.itemId).startRange = this.time3
        this.item.productScheduleItems.find(el => el.indexItem === this.itemId).endRange = this.time4
        this.item.productScheduleItems.find(el => el.indexItem === this.itemId).cutId = this.selectedItem
      } else {
        if (this.time3 != null && this.time4 != null) {
          this.item.productScheduleItems.push(
            {
              id: null,
              indexItem: this.indexItem++,
              productScheduleId: this.item.id,
              startRange: this.time3,
              status: 1,
              cutId: this.selectedItem,
              endRange: this.time4
            }
          )
        }
      }
      this.nameButton = 'Добавить'
      this.itemId = null
      this.time3 = null
      this.time4 = null
    },
    editRange (indexItem) {
      this.nameButton = 'Изменить'
      this.itemId = indexItem
      this.time3 = this.item.productScheduleItems.find(el => el.indexItem === indexItem).startRange
      this.time4 = this.item.productScheduleItems.find(el => el.indexItem === indexItem).endRange
      this.selectedItem = this.item.productScheduleItems.find(el => el.indexItem === indexItem).cutId
    },
    delRange (indexItem) {
      this.item.productScheduleItems.find(el => el.indexItem === indexItem).status = 2
    },
    goBack () {
      console.log('goBack')
      if (this.item) {
        this.$router.push({ name: 'SheduleDate', params: { item: this.item } })
      } else {
        this.$router.push({ name: 'PriceList' })
      }
    }
  },
  mounted () {
    if (this.item) {
      if (this.item.startPeriod.length > 5) {
        this.item.discreteness = this.item.discreteness.toString()
        this.item.startPeriod = this.formatTime(this.item.startPeriod)
        this.item.endPeriod = this.formatTime(this.item.endPeriod)
        this.item.productScheduleItems.forEach(element => {
          element.startRange = this.formatTime(element.startRange)
          element.endRange = this.formatTime(element.endRange)
          element.indexItem = this.indexItem++
        })
      }
    } else {
      this.goBack()
    }
  }
})
</script>
