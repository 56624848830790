import axios, { setAuthorizationHeader } from '@/services/axios'
import { AxiosResponse } from 'axios'
import { Item, AfficheItem } from './types'
import { ItemsPost, AffichePost, RubricPost, AfficheResponse, AffichesUpdatePost, AffichesCopyPost, AffichesUpdateOrderPost } from './api-types'

const getItems = 'topicitems'
const afficheRemove = 'topicitems/delete'
const afficheSearch = 'topicitems/search'
const afficheCreate = 'topicitems/create'
const afficheUpdate = 'topicitems/update'
const afficheUpdateOrder = 'topicitems/updateorder'
const afficheCopy = 'topicitems/copy'
const rubricsShow = 'topics/findbycategory'
const saveFile = 'files/create'

export const getitems = async (itemsPost: ItemsPost): Promise<Item[]> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: Item[];
  }> = await axios.post(getItems, itemsPost)
  return result.data.data
}

export const rubricsshow = async (rubricPost: RubricPost): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(rubricsShow, rubricPost)
  return result.data.data
}

export const afficheremove = async (afficheRemovePost: AffichePost): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(afficheRemove, afficheRemovePost)
  return result.data.data
}

export const affichesearch = async (afficheSearchPost: AffichePost): Promise<AfficheResponse> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: AfficheResponse;
  }> = await axios.post(afficheSearch, afficheSearchPost)
  return result.data.data
}

export const affichecreate = async (afficheItem: AfficheItem): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(afficheCreate, afficheItem)
  return result.data.data
}

export const afficheupdate = async (afficheItem: AffichesUpdatePost): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(afficheUpdate, afficheItem)
  return result.data.data
}

export const afficheupdateOrder = async (afficheItem: AffichesUpdateOrderPost): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(afficheUpdateOrder, afficheItem)
  return result.data.data
}

export const affichecopy = async (affichesCopyPost: AffichesCopyPost): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(afficheCopy, affichesCopyPost)
  return result.data.data
}

export const savefile = async (filePost: any): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(saveFile, filePost)
  return result.data.data
}
