



















































import Vue from 'vue'
import { history as lockerHistory } from '@/store/locker/api-requests'
import { LockerHistoryPost } from '@/store/locker/api-types'
import { LockerHistory } from '@/store/locker/types'
import { LockerState } from '@/types/vue-enums'
type BaseData = {
  lockerHistory: LockerHistory[];
   loading: boolean;
}
export default Vue.extend({
  components: {
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data (): BaseData {
    return {
      loading: false,
      lockerHistory: []
    }
  },
  methods: {
    async loadHistory (lockerId: string) {
      const lockerHistoryPost: LockerHistoryPost = { id: parseInt(lockerId) }
      this.loading = true
      try {
        this.lockerHistory = await lockerHistory(lockerHistoryPost)
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    getLockerState (item: number) {
      return LockerState[item].toString()
    },
    back () {
      this.$router.push({ name: 'LockerControl', params: { back: 'true' } })
    }
  },
  mounted () {
    if (this.id) {
      this.loadHistory(this.id)
    }
  }
})
