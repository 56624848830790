import axios, { setAuthorizationHeader } from '@/services/axios'
import { AxiosResponse } from 'axios'
import {
  UserLoginPost,
  UserSignupPost,
  UserForgotPost,
  UserResetPost
} from './api-types'
import { UserTokens, Person } from './types'

const routeLogin = '/login'
const routeSignup = '/signup'
const routeForgot = '/forgot'
const routerReset = '/reset'
const routePerson = '/user'

export const login = async (data: UserLoginPost): Promise<UserTokens> => {
  const result: AxiosResponse<{
      access_token: UserTokens['access_token'];
      refresh_token: UserTokens['refresh_token'];
  }> = await axios.post(routeLogin, data)
  return result.data
}

export const getPerson = async (): Promise<Person> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
      id: Person['id'];
      lastName: Person['lastName'];
      firstName: Person['firstName'];
      middleName: Person['middleName'];
      userType: Person['userType'];
  }> = await axios.get(routePerson)
  return result.data
}

export const signup = async (data: UserSignupPost): Promise<number> => {
  const result: AxiosResponse<{
    id: number ;
  }> = await axios.post(routeSignup, data)
  return result.data.id
}

export const forgot = async (data: UserForgotPost): Promise<string> => {
  const result: AxiosResponse<{
    passwordResetToken: string;
  }> = await axios.post(routeForgot, data)
  return result.data.passwordResetToken
}

export const reset = async (data: UserResetPost): Promise<void> => {
  await axios.post(routerReset, data)
  return Promise.resolve()
}
