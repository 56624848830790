<template>
  <v-container class="py-0">
    <LoadingBar :loading="loading"></LoadingBar>
    <div id="topActions">
      <v-row class="justify-content-center">
        <v-col></v-col>
        <v-col>
          <v-btn block @click="takeForDelivery()">Взять на доставку</v-btn>
        </v-col>
        <v-col></v-col>
      </v-row>
    </div>
    <v-row class="d-flex justify-content-center pt-1">
      <div style="width:160px">
        <ul id="listContainer1">
          <li :class="filterDeliveryPlace === null ? 'active listItem' : 'listItem'">
            <a style="font-weight:bold" @click="changeFilter(null)">Все</a>
          </li>
          <li v-for="(item, index) in sectorAnchors" :key="index" :class="filterDeliveryPlace === item ? 'active listItem' : 'listItem'">
            <a style="font-weight:bold" @click="changeFilter(item)">{{ item.slice(0, -1).replace(/;/g, " / ") }}</a>
          </li>
        </ul>
      </div>
      <div style="width: calc(100% - 200px)">
        <CardsContainer
          v-if="settings.length !== []"
          :half="false"
          :componentId="'ready_' + '22222'"
          :itemsContent="'readyForDelivery'"
          :inputTimeColors="settings.timeColors"
          :maxCardRows="5"
          :data="filteredItems"
          :itemAction="'changeSelected'"
          @changeFromChild="changeValue($event)"
          >
        </CardsContainer>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import LoadingBar from '@/components/partials/components/LoadingBar.vue'
import CardsContainer from '@/components/food/foodOrders/CardsContainer.vue'
import { getFoodOrdersByPeriodAndStatusApi, updateFoodOrderStatusUrlApi } from '@/store/orders/api-requests'
import { mapSettings } from '@/store/food/settingsHelper'

export default Vue.extend({
  components: {
    LoadingBar,
    CardsContainer
  },
  data () {
    return {
      selected: [],
      data: [],
      data2: [],
      sectorAnchors: [],
      dataResultAnchors: [],
      dataResultAnchors2: [],
      dateFrom: new Date().getFullYear() + '-01-01',
      dateTo: new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().substr(0, 10),
      loading: false,
      dataResult: [],
      settings: [],
      filteredItems: [],
      filterDeliveryPlace: null,
      refreshIntervalId: null
    }
  },
  methods: {
    changeFilter (value) {
      this.filterDeliveryPlace = value
      this.filterAll()
    },
    filterAll () {
      const filteredItems = JSON.parse(JSON.stringify(this.data2))
      if (this.filterDeliveryPlace) {
        this.filteredItems = filteredItems.filter((el) => el.deliveryPlace === this.filterDeliveryPlace)
      } else {
        this.filteredItems = filteredItems
      }
    },
    async takeForDelivery () {
      const dataForApi = this.selected.map(el => {
        el = { id: el, status: 5 }
        return el
      })
      this.selected.forEach(el => {
        this.data2 = this.data2.filter(el2 => el2.id !== el)
        this.filteredItems = this.filteredItems.filter(el2 => el2.id !== el)
      })
      this.selected = []
      let result
      try {
        result = updateFoodOrderStatusUrlApi({ orders: dataForApi })
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
        this.setAnchors()
      }
    },
    setAnchors () {
      this.sectorAnchors = []
      this.data2.forEach(el => {
        if (el.orderDetail.length > 0) {
          if (Number(el.orderDetail[0].deliveryTypeId) === 1) {
            this.sectorAnchors.push(el.orderDetail[0].deliveryPlace)
          }
        }
      })
      this.sectorAnchors = this.sectorAnchors.filter((v, i) => this.sectorAnchors.indexOf(v) === i)
      if (this.sectorAnchors.length > 0) {
        const issetAnchor = this.sectorAnchors.indexOf(this.filterDeliveryPlace)
        if (issetAnchor === -1 && this.filterDeliveryPlace !== null) {
          this.filterDeliveryPlace = this.sectorAnchors[0]
          this.filterAll()
        }
      } else {
        this.filterDeliveryPlace = null
        this.filterAll()
      }
    },
    changeValue (evnt) {
      if (evnt.action === 'changeSelected') {
        this.changeSelected(evnt.val)
      }
    },
    changeSelected (id) {
      const index = this.selected.indexOf(id)
      if (index > -1) {
        this.selected.splice(index, 1)
      } else {
        this.selected.push(id)
      }
      this.data2 = this.data2.map(el => {
        if (el.id === id) {
          el.isSelected = !el.isSelected
        }
        return el
      })
      this.filterAll()
    },
    init (val) {
      // this.dataResultAnchors2 = JSON.parse(JSON.stringify(val))
      val.forEach(el => {
        this.data2.push(el)
      })
      this.filterAll()
    },
    async getData () {
      if (this.$route.name !== 'FoodOrderStateReadyForDelivery') {
        clearInterval(this.refreshIntervalId)
        return false
      }
      console.log('get data ready for delivery')
      this.loading = true
      let result
      try {
        result = await getFoodOrdersByPeriodAndStatusApi({ dateFrom: this.dateFrom, dateTo: this.dateTo, status: 4 })
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
        if (result) {
          this.selected = []
          this.data2 = []
          this.orders = []
          const orders = JSON.parse(JSON.stringify(result))

          orders.map(order => {
            if (order.orderProducts.length) {
              order.data = order.orderProducts.map(el => ({ name: el.product.name, quantity: (+el.quantity) }))
            }
            return order
          })
          this.data = orders
          this.data.map(el => {
            el.data = el.data.filter(subEl => Number(subEl.quantity) !== 0)
            el.isSelected = false
            el.deliveryPlace = null
            el.footer = el.phone ? el.phone : 'Нет тел. номера'
            if (el.orderDetail.length > 0) {
              if (Number(el.orderDetail[0].deliveryTypeId) === 1) {
                // this.sectorAnchors.push(el.orderDetail[0].deliveryPlace)
                el.deliveryPlace = el.orderDetail[0].deliveryPlace
              }
            }
            return el
          })
          // this.sectorAnchors = this.sectorAnchors.filter((v, i) => this.sectorAnchors.indexOf(v) === i)
          // this.filterDeliveryPlace = this.sectorAnchors[0]
          this.data = this.data.filter(el => Number(el.sum) !== 0)
          this.init(this.data)
          this.setAnchors()
        }
      }
    },
    ...mapActions('foodSettings', { getSettingsStore: 'show' }),
    async getSettings () {
      let result
      try {
        result = await this.getSettingsStore()
      } catch (error) {
        console.log(error)
      } finally {
        if (result) {
          this.settings = mapSettings(result)
          this.getData()
          this.refreshIntervalId = setInterval(() => this.getData(), Number(this.settings.refreshTime))
        }
      }
    }
  },
  mounted () {
    this.getSettings()
  },
  destroyed () {
    clearInterval(this.refreshIntervalId)
  },
  computed: {
    ...mapGetters({ user: 'user/user' })
  }
})
</script>
<style scoped>
  #listContainer2{
    height: calc(100vh - 230px);
    display: block;
    overflow-y: scroll;
  }
  .listItem{
    list-style: none;
    padding-top: 7px;
    padding-bottom: 7px;
  }
  .listItem.active{
    background:#cacaca;
  }
</style>
