import Vue from 'vue'
import Vuex from 'vuex'
import { user } from '@/store/user'
import { lockers } from '@/store/locker'
import { snackbar } from '@/store/snackbar'
import { informations } from '@/store/information'
import { categories } from '@/store/affiche/categories'
import { tags } from '@/store/tags'
import { advertisement } from '@/store/advertisement'
import { productSections } from '@/store/productSections'
import { shedules } from '@/store/shedules'
import { foodSettings } from '@/store/foodSettings'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    lockers,
    snackbar,
    informations,
    categories,
    tags,
    advertisement,
    productSections,
    shedules,
    foodSettings
  },
  strict: process.env.NODE_ENV !== 'production'
})
