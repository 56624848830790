<template>
  <v-container fluid>
    <v-data-iterator
      :sort-by="sortBy"
      :sort-desc="itemsContent === 'prep' ? true : false"
      disable-filtering
      :items="computedItems"
      :items-per-page.sync="itemsPerPage"
      :page.sync="page"
      item-key="`${componentId}-${id}`"
      hide-default-footer
    >
      <template v-slot:default="props">
        <v-row v-if="itemsContent === 'new'">
          <v-col
            v-for="item in props.items"
            :key="componentId + componentId + item.id"
            cols="12"
            sm="6"
            md="6"
            lg="6"
          >
          <!-- :class="item.isSelected ? 'selected' : (componentId === 'deli_self1_' || componentId === 'deli_self2_') ? 'deli_self' : ''" -->
            <v-card @click="itemActionInit(item.id)"
            :class="item.isSelected ? `selected ${item.timestatus}` : `${item.timestatus}`"
            >
              <v-card-title class="subheading font-weight-bold pt-2 pb-2">
                <span class="tit1">
                  Заказ {{ item.id }}
                </span>
                <v-spacer></v-spacer>
                {{ item.sum.slice(0, -2) + '.' + item.sum.slice(-2) }} р.
                <v-spacer></v-spacer>
                <span class="tit2">
                  {{ item.footer.substr(5,11).replace('-','.') }}
                </span>
              </v-card-title>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-if="itemsContent !== 'new'">
          <v-col
            v-for="item in props.items"
            :key="componentId + componentId + item.id"
            cols="12"
            :sm="half ? '12' : '6'"
            :md="half ? '6' : '3'"
            :lg="half ? '6' : '3'"
          >
          <!-- :class="item.isSelected ? 'selected' : (componentId === 'deli_self1_' || componentId === 'deli_self2_') ? 'deli_self' : ''" -->
            <v-card @click="itemActionInit(item.id)"
            :class="item.isSelected ? `selected ${item.timestatus}` : `${item.timestatus}`"
            >
              <v-card-title :class="itemsContent === 'delivery' ? 'font-weight-regular text-h6' : 'subheading font-weight-bold'">
                Заказ {{ item.id }}
              </v-card-title>

              <v-divider></v-divider>
              <v-card-subtitle v-if="itemsContent === 'delivery'" style="font-size: 20px; font-weight:600">
                {{ item.subtitle.slice(0, -1).replace(/;/g, " / ") }}
              </v-card-subtitle>
              <v-divider v-if="itemsContent === 'delivery'"></v-divider>

              <v-list dense>
                <v-list-item
                  v-for="(key, index) in item.data"
                  :key="componentId + item.id + index"
                >
                  <div class="row justify-space-between">
                    <div class="col col-auto py-0">
                      <v-list-item-content v-if="$vuetify.breakpoint.md || $vuetify.breakpoint.lg || $vuetify.breakpoint.xl">
                        {{ key['name'].length > 12 ? key['name'].substr(0, 12) + '...' : key['name'] }}
                      </v-list-item-content>
                      <v-list-item-content v-if="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs">
                        {{ key['name'].length > 6 ? key['name'].substr(0, 5) + '...' : key['name'] }}
                      </v-list-item-content>
                    </div>
                    <div class="col col-auto py-0">
                      <v-list-item-content
                        class="align-end"
                      >
                        {{ key['quantity'] }} {{ key['quantity'] ? (itemsContent === 'new' ? 'шт.' : null) : null }}
                      </v-list-item-content>
                    </div>
                  </div>
                </v-list-item>
              </v-list>

              <v-divider></v-divider>
              <div style="padding: 4px 16px;">
                {{ item.footer }}
              </div>
            </v-card>
          </v-col>
        </v-row>
      </template>
      <template v-slot:footer>
        <v-row
          class="mt-2"
          align="center"
          justify="center"
        >
          <v-col style="text-align: right">
            <span
            class="mr-4
            grey--text"
          >
            Страница {{ page }} из {{ numberOfPages }}
          </span>
            <v-btn
              fab
              dark
              color="blue darken-3"
              class="mr-1"
              @click="prewPage"
            >
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn
              fab
              dark
              color="blue darken-3"
              class="ml-1"
              @click="nextPage"
            >
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>
  </v-container>
</template>

<script>
import { getTimeStatusClass } from '@/store/food/settingsHelper'
export default {
  props: {
    componentId: {
      type: String
    },
    itemsContent: {
      type: String
    },
    data: {
      type: Array
    },
    maxCardRows: {
      type: Number || 0
    },
    itemAction: {
      type: String
    },
    half: {
      type: Boolean
    },
    inputTimeColors: {
      type: Array
    }
  },
  watch: {
    data (val) {
      this.items = val
    },
    inputTimeColors (val) {
      this.timeColors = JSON.parse(JSON.stringify(val))
      // console.log(this.timeColors)
    }
  },
  data () {
    return {
      items: [],
      sortBy: 'creationDate',
      page: 1,
      itemsPerPage: 12 // todo get from settings
      // itemsPerPageArray: [4, 8, 12]
      // timeColorStatuses: [
      //   { from: 150, to: null, color: 3 },
      //   { from: 45, to: 150, color: 2 }, // blue
      //   { from: null, to: 55, color: 1 } // green
      // ]
    }
  },
  computed: {
    computedItems () {
      if (this.maxCardRows) {
        return this.items.map((el) => {
          const rowsToAdd = this.maxCardRows - el.data.length
          if (rowsToAdd > 0) {
            for (let i = 0; i < rowsToAdd; i++) {
              el.data.push({ name: '', quantity: null })
            }
          }
          el.timestatus = getTimeStatusClass(el.creationDate, this.timeColors)
          return el
        })
      } else {
        return this.items
      }
    },
    numberOfPages () {
      return Math.ceil(this.items.length / this.itemsPerPage)
    }
  },
  methods: {
    // getTimeStatusClass (creationDate) {
    //   let result = ''
    //   this.timeColorStatuses.forEach((statusEl) => {
    //     const from = statusEl.from ? this.getTimeToCompare(statusEl.from) : null
    //     const to = statusEl.to ? this.getTimeToCompare(statusEl.to) : null
    //     if (from && to) {
    //       if (Date.parse(creationDate) < Date.parse(from) &&
    //       Date.parse(creationDate) > Date.parse(to)) {
    //         result = 'timestatus' + statusEl.color
    //       }
    //     } else if (from === null) {
    //       if (Date.parse(creationDate) > Date.parse(to)) {
    //         result = 'timestatus' + statusEl.color
    //       }
    //     } else if (to === null) {
    //       if (Date.parse(creationDate) < Date.parse(from)) {
    //         result = 'timestatus' + statusEl.color
    //       }
    //     }
    //   })
    //   return result
    // },
    // getTimeToCompare (minutes) {
    //   let dt = new Date()
    //   dt = new Date(dt.getTime() - (dt.getTimezoneOffset() * 60000)).toISOString().substr(0, 19)
    //   return new Date(new Date(Date.parse(dt)).getTime() - Number(minutes) * 60000)
    // },
    itemActionInit (id) {
      this.$emit('changeFromChild', { action: this.itemAction, val: id })
    },
    nextPage () {
      if (this.page + 1 <= this.numberOfPages) this.page += 1
    },
    prewPage () {
      if (this.page - 1 >= 1) this.page -= 1
    }
    // updateItemsPerPage (number) {
    //   this.itemsPerPage = number
    // }
  },
  mounted () {
    this.items = this.data
    // console.log(this.timeColors)
  }
}
</script>
<style lang="scss" scoped>
  .v-card.timestatus3{
    background: #cef5be;
  }
  .v-card.timestatus2{
    background: #a2b5f3;
  }
  .v-card.timestatus1{
    background: #f1b1aa;
  }
  .v-card.selected{
    background: rgb(0 0 0 / 25%);
  }
  .v-card.deli_self{
    background:#feffc5;
  }
  .v-card.color1{
    background:red;
  }
  .v-card__title{
    padding: 0 15px;
  }
  .v-list{
    padding:2px 0;
    background: transparent;
  }
  .v-list-item--dense .v-list-item__content,
  .v-list--dense .v-list-item .v-list-item__content{
    padding: 0;
  }
  .v-list-item--dense, .v-list--dense .v-list-item{
    min-height: 20px;
  }
</style>
