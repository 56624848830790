















































































































































































































import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import VueQrcode from 'vue-qrcode'
import { open as lockersOpen, changeLockerRemovedApi } from '@/store/locker/api-requests'
import { LockersOpenPost } from '@/store/locker/api-types'
import SimpleModal from 'simple-modal-vue'

export default Vue.extend({
  data () {
    return {
      loading: false,
      selected: [] as any,
      tab: null,
      selectedTab: 1,
      deltaTime: 0,
      barcodeName1: '',
      barcodeName2: '',
      barcodeValue1: '',
      barcodeValue2: '',
      visibleModal: false,
      colorActive: '#6CD574',
      color0: '#6CD574',
      color1: '',
      color2: '',
      rangeFilter: 0,
      lockersFiltered: [],
      indeterminate: false,
      allCheckbox: false,
      imgSourceToDownload: ''
    }
  },
  components: {
    VueQrcode,
    SimpleModal
  },
  computed: {
    ...mapGetters({ user: 'user/user' }),
    ...mapGetters({ lockers: 'lockers/lockers' }),
    ...mapGetters({ serverTime: 'lockers/serverTime' })
  },
  methods: {
    makeImgSourceToDownload () {
      this.imgSourceToDownload = (this.$refs.rqcode1 as any).$el.currentSrc
    },
    getOrderStatus1ItemsIds (orderStatus: number) {
      let items = JSON.parse(JSON.stringify(this.lockersFiltered))
      items = items.filter((el: any) => el.orderStatus === orderStatus)
      return items.map((item: any) => item.id)
    },
    arraysDiff (array1: any, array2: any) {
      const difference = array1.filter((item: any) => {
        return JSON.stringify(array2).indexOf(JSON.stringify(item)) === -1
      })
      return difference.length
    },
    toggleRows (checkAll: any, tabId: number) {
      if (checkAll) {
        this.selected = this.getOrderStatus1ItemsIds(tabId)
      } else {
        this.selected = []
      }
      this.indeterminate = false
    },
    checkIndeterminate () {
      if (this.arraysDiff(this.getOrderStatus1ItemsIds(this.selectedTab), this.selected)) {
        this.allCheckbox = false
        if (JSON.parse(JSON.stringify(this.selected)).length) {
          this.indeterminate = true
        } else {
          this.indeterminate = false
        }
      } else {
        this.indeterminate = false
        this.allCheckbox = true
      }
      this.changeSelectors()
    },
    toggleRow (id: any) {
      const elemIndex = this.selected.indexOf(id)
      if (elemIndex > -1) {
        this.selected.splice(elemIndex, 1)
      } else {
        this.selected.push(id)
      }
      this.checkIndeterminate()
    },
    computedTime (timestamp: string) {
      return timestamp ? timestamp.slice(0, -2) + '00' : null
    },
    ...mapActions('lockers', { lockersShow: 'show' }),
    async loadLockers () {
      this.loading = true
      try {
        await this.lockersShow()
        this.lockersFiltered = this.lockers
        this.deltaTime = Date.now() - Date.parse(this.serverTime)
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
        this.applyFilters()
      }
    },
    async openLockers (id?: number) {
      const lockersOpenPost: LockersOpenPost = { ids: [] }
      if (id) {
        lockersOpenPost.ids.push(id)
        this.barcodeName1 = ''
        this.barcodeName2 = ''
      } else {
        const selected = this.selected.filter(Boolean)
        selected.forEach((element: number) => {
          lockersOpenPost.ids.push(element)
        })
      }
      if (lockersOpenPost.ids.length > 0) {
        this.loading = true
        try {
          const result = await lockersOpen(lockersOpenPost)
          if (result.length > 0) {
            if (!id) {
              this.barcodeName1 = result[0].name
            }
            this.barcodeValue1 = result[0].code
            if (result[1]) {
              this.barcodeName2 = result[1].name
              this.barcodeValue2 = result[1].code
            }
          }
          if (this.barcodeValue1 !== '' || this.barcodeValue2 !== '') {
            this.visibleModal = true
            setTimeout(() => this.makeImgSourceToDownload(), 100)
          }
        } catch (error) {
          console.log(error)
        } finally {
          this.loading = false
        }
      }
    },
    selectLockers (direction: number) {
      this.selected = []
      let counter = 1
      let items = JSON.parse(JSON.stringify(this.lockersFiltered))
      items = items.filter((el: any) => el.orderStatus === this.selectedTab)
      for (const element of items) {
        if (element.direction === direction) {
          this.selected.push(element.id)
          counter++
        }
        if (counter > 9) break
      }
      this.checkIndeterminate()
    },
    routeToHistory (lockerId: number) {
      this.$router.push({ name: 'LockerHistory', params: { id: lockerId.toString() } })
    },
    changeSelectors () {
      this.barcodeValue1 = ''
      this.barcodeValue2 = ''
      this.barcodeName1 = ''
      this.barcodeName2 = ''
    },
    applyFilters () {
      if (this.rangeFilter === 0) {
        this.lockersFiltered = this.lockers
      } else {
        this.lockersFiltered = this.lockers.filter((element: any) => element.rack === this.rangeFilter)
      }
    },
    changeRange (range: number) {
      this.selected = []
      this.allCheckbox = false
      this.indeterminate = false
      this.color0 = ''
      this.color1 = ''
      this.color2 = ''
      this.rangeFilter = range
      if (range === 1) {
        this.color1 = this.colorActive
      } else if (range === 2) {
        this.color2 = this.colorActive
      } else {
        this.color0 = this.colorActive
      }
      this.applyFilters()
    },
    changeTab (tabId: number) {
      this.selectedTab = tabId
      this.selected = []
      this.allCheckbox = false
      this.indeterminate = false
      console.log('changeTab ' + this.selectedTab)
    },
    getDateDiff (time: string) {
      const endDate = Date.parse(time)
      const purchaseDate = Date.parse(this.serverTime)
      const diffMs = (purchaseDate - endDate)
      const diffDays = Math.floor(diffMs / 86400000)
      const diffHrs = Math.floor((diffMs % 86400000) / 3600000)
      const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000)
      let result = ''
      if (diffDays > 0) {
        result = diffDays + ' д, '
      }
      result = result + diffHrs + ' : ' + diffMins
      return result
    },
    async changeRemovedStatus (id: number, value: boolean) {
      this.loading = true
      value = !value
      const obj = {
        id: id,
        removed: Number(value)
      }
      let result
      try {
        result = await changeLockerRemovedApi(obj)
      } catch (error) {
        console.log(error)
      } finally {
        if (result) {
          this.loadLockers()
          setTimeout(() => {
            this.loading = false
          }, 3000)
        }
      }
    },
    computedRemoved (value: number) {
      return Boolean(value)
    }
  },
  mounted () {
    this.loadLockers()
  }
})
