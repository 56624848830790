<template>
  <v-container class="py-0">
    <LoadingBar :loading="loading"></LoadingBar>
    <v-row class="justify-content-center mb-3">
      <v-col>
        <v-btn block @click="printCheck()">Напечатать чеки</v-btn>
      </v-col>
      <v-col>
        <v-btn block @click="finishOrderPrepair()">Заказы собраны</v-btn>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-content-center">
      <div style="width:100%">
        <CardsContainer
        v-if="settings !== []"
        :half="false"
        :componentId="'prep1_'"
        :itemsContent="'prep'"
        :inputTimeColors="settings.timeColors"
        :maxCardRows="5" :data="data" :itemAction="'changeSelected'" @changeFromChild="changeValue($event)">
        </CardsContainer>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import LoadingBar from '@/components/partials/components/LoadingBar.vue'
import CardsContainer from '@/components/food/foodOrders/CardsContainer.vue'
import { getFoodOrdersByPeriodAndStatusApi, updateFoodOrderStatusUrlApi } from '@/store/orders/api-requests'
import { mapSettings } from '@/store/food/settingsHelper'
import Swal from 'sweetalert2'

export default Vue.extend({
  components: {
    LoadingBar,
    CardsContainer
  },
  data () {
    return {
      data: [],
      dateFrom: new Date().getFullYear() + '-01-01',
      dateTo: new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().substr(0, 10),
      selected: [],
      loading: false,
      settings: [],
      issetReadyForDelivery: false,
      refreshIntervalId: null
    }
  },
  methods: {
    async getData () {
      if (this.$route.name !== 'FoodOrderStatePrepairing') {
        clearInterval(this.refreshIntervalId)
        return false
      }
      // console.log('get data prep')
      this.loading = true
      let result
      try {
        result = await getFoodOrdersByPeriodAndStatusApi({ dateFrom: this.dateFrom, dateTo: this.dateTo, status: 2 })
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
        if (result) {
          this.selected = []
          this.data = []
          const orders = JSON.parse(JSON.stringify(result))
          orders.map(order => {
            if (order.sum !== 0) {
              if (order.orderProducts.length) {
                order.data = order.orderProducts.map(product => {
                  if (Number(product.product.quantity) !== 0) {
                    product.name = product.product.name
                    product.quantity = (+product.quantity)
                    return product
                  }
                })
              }
              order.isSelected = false
              order.footer = order.creationDate
              return order
            }
          })
          this.data = orders
        }
      }
    },
    printCheck () {
      console.log('printChecks: ' + this.selected)
    },
    async finishOrderPrepair () {
      if (this.selected.length < 1) {
        await Swal.fire({
          title: 'Выберите заказы'
        })
        return false
      }
      const status = this.issetReadyForDelivery ? 4 : 5
      const dataForApi = this.selected.map(el => {
        el = { id: el, status: status }
        return el
      })
      this.selected.forEach(el => {
        this.data = this.data.filter(el2 => el2.id !== el)
      })
      this.selected = []
      // console.log(this.selected)
      let result
      try {
        result = updateFoodOrderStatusUrlApi({ orders: dataForApi })
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    changeValue (evnt) {
      if (evnt.action === 'changeSelected') {
        this.changeSelected(evnt.val)
      }
    },
    changeSelected (id) {
      const index = this.selected.indexOf(id)
      if (index > -1) {
        this.selected.splice(index, 1)
      } else {
        this.selected.push(id)
      }
      this.data = this.data.map(el => {
        if (el.id === id) {
          el.isSelected = !el.isSelected
        }
        return el
      })
    },
    ...mapActions('foodSettings', { getSettingsStore: 'show' }),
    async getSettings () {
      let result
      try {
        result = await this.getSettingsStore()
      } catch (error) {
        console.log(error)
      } finally {
        if (result) {
          this.settings = mapSettings(result)
          this.getData()
          this.refreshIntervalId = setInterval(() => this.getData(), Number(this.settings.refreshTime))
          this.issetReadyForDelivery = this.settings.prepairForDeliveryTabVisibility
          // console.log(typeof this.refreshIntervalId)
        }
      }
    }
  },
  mounted () {
    this.getSettings()
  },
  destroyed () {
    clearInterval(this.refreshIntervalId)
  },
  computed: {
    ...mapGetters({ user: 'user/user' })
  }
})
</script>
