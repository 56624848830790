



















































































import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import OrderEventsMore from '@/components/orders/OrderEventsMore.vue'
import { getSumInPeriodByType, getMoreInPeriodByType } from '@/store/orderevents/api-requests'

interface ResultItem {
  creationDate: string;
  count: number;
  sum?: number | null;
}

export default Vue.extend({
  components: {
    OrderEventsMore
  },
  data () {
    return {
      orderEventsMoreData: null,
      filter: {
        messageType: 1,
        dateFrom: new Date().toISOString().substr(0, 10),
        dateTo: new Date().toISOString().substr(0, 10)
      },
      menuDateFrom: false,
      menuDateTo: false,
      messagesTypes: [
        { id: 1, name: 'SMS' },
        { id: 2, name: 'Push', disabled: true },
        { id: 3, name: 'Telegram', disabled: true }
      ],
      messages: [],
      messagesFiltered: []
    }
  },
  methods: {
    selectMessagesType () {
      return 1
    },
    plusDay (date: any) {
      const newDate = new Date(date)
      newDate.setDate(newDate.getDate() + 1)
      return newDate.toISOString().substr(0, 10)
    },
    async show () {
      const searchObj = {
        messageType: this.filter.messageType,
        dateFrom: this.filter.dateFrom,
        dateTo: this.plusDay(this.filter.dateTo)
      }
      let result
      try {
        result = await getSumInPeriodByType(searchObj)
      } catch (error) {
        console.log(error)
      } finally {
        if (result) {
          const result1 = result.map((obj: any) => ({ ...obj, dateFrom: obj.creationDate, dateTo: obj.creationDate, messageType: this.filter.messageType }))
          this.messagesFiltered = result1
          this.orderEventsMoreData = null
        }
      }
    },
    moreInfoGroup () {
      const searchObj = this.filter
      this.moreInfo(searchObj)
    },
    async moreInfo (item: any) {
      const searchObj = { ...item, dateTo: this.plusDay(item.dateTo) }
      let result
      try {
        result = await getMoreInPeriodByType(searchObj)
      } catch (error) {
        console.log(error)
      } finally {
        if (result) {
          this.orderEventsMoreData = result
        }
      }
    },
    computedGroupSum (items: any) {
      const sum = items.reduce((sum: number, eachSum: ResultItem) => sum + eachSum.count, 0)
      return sum
    }
  },
  mounted () {
    this.filter.dateFrom = new Date().toISOString().substr(0, 4) + '-01-01'
  },
  computed: {
    ...mapGetters({ user: 'user/user' }),
    messagesHeaders () {
      return [
        { text: 'Тип', value: 'messageType', sortable: false },
        { text: 'Дата c', value: 'dateFrom', sortable: false },
        { text: 'Дата по', value: 'dateTo', sortable: false },
        { text: 'Кол-во', value: 'count', sortable: false },
        { text: '', value: 'actions', sortable: false }
      ]
    }
  }
})
