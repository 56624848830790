<template>
  <v-data-table
    :headers="headers"
    :items="computedallItems"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <!-- <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider> -->
        <v-spacer></v-spacer>
        <v-dialog
          v-model="dialog"
          max-width="500px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
            >
              Добавить
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.limitFrom"
                      label="От"
                      type="number"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.limitTo"
                      label="До"
                      type="number"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.price"
                      label="Цена"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="close"
              >
                Отмена
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="save"
              >
                Сохранить
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="headline">Уверены, что хотите удалить?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Отмена</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        mdi-delete
      </v-icon>
    </template>
    <!-- <template v-slot:no-data>
      <v-btn
        color="primary"
        @click="initialize"
      >
        Обновить
      </v-btn>
    </template> -->
  </v-data-table>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
export default Vue.extend({
  components: {
    //
  },
  props: {
    id: {
      type: Number,
      require: true
    },
    items: {
      type: Array,
      require: true
    },
    sendFatherUpdatedItems: {
      type: Function
    }
  },

  data () {
    return {
      visibleItems: [],
      dialog: false,
      dialogDelete: false,
      headers: [
        { text: 'От', sortable: true, value: 'limitFrom' },
        { text: 'До', sortable: true, value: 'limitTo' },
        { text: 'Цена', sortable: true, value: 'price' },
        { text: '', value: 'actions' }
      ],
      allItems: [],
      editedIndex: -1,
      editedItem: {
        limitFrom: null,
        limitTo: null,
        price: null,
        removed: null
      },
      defaultItem: {
        limitFrom: null,
        limitTo: null,
        price: null,
        removed: null
      }
    }
  },

  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'Создание' : 'Редактирование'
    },
    computedallItems () {
      return this.allItems.filter((el) => el.removed === null)
    }
  },

  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
    items () {
      this.initialize()
    }
  },

  created () {
    this.initialize()
  },

  methods: {
    initialize () {
      this.allItems = this.items
    },

    editItem (item) {
      this.editedIndex = this.allItems.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem (item) {
      this.editedIndex = this.allItems.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm () {
      this.allItems[this.editedIndex].removed = 1
      this.closeDelete()
      this.handleUpdateItems()
    },

    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save () {
      const convertedItem = Object.assign({}, this.editedItem)
      convertedItem.limitFrom = Number(convertedItem.limitFrom)
      convertedItem.limitTo = Number(convertedItem.limitTo)
      convertedItem.price = Number(convertedItem.price)

      if (this.editedIndex > -1) {
        Object.assign(this.allItems[this.editedIndex], convertedItem)
      } else {
        convertedItem.id = null
        this.allItems.push(convertedItem)
      }
      this.close()
      this.handleUpdateItems()
    },
    handleUpdateItems () {
      this.allItems = this.allItems.filter(Boolean)
      this.sendFatherUpdatedItems(this.allItems)
      console.log(this.allItems)
    }
  },
  mounted () {
    //
  }
})
</script>
