
















































import Vue from 'vue'
import { password as getPassword } from '@/store/locker/api-requests'
export default Vue.extend({
  components: {
  },
  data () {
    return {
      loading: false,
      lockerPassword: null
    }
  },
  methods: {
    async loadPassword () {
      this.loading = true
      this.lockerPassword = null
      try {
        this.lockerPassword = await getPassword()
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    }
  },
  mounted () {
    this.loadPassword()
  }
})
