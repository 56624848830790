













































import Vue from 'vue'
import GoBackButton from '@/components/partials/components/GoBackButton.vue'
import { savefileNew as saveFileNew } from '@/services/fileUpload'
import Swal from 'sweetalert2'

export type BaseData = {
    loading: any;
    imageUrl: string;
    readyFileToUpload: boolean;
    uploadedFile: any;
    fileUploadButtonColor: string;
    postForm: any;
}

export default Vue.extend({
  components: {
    GoBackButton
  },
  props: {
    id: {
      type: Number
    }
  },
  data (): BaseData {
    return {
      loading: false,
      imageUrl: '',
      readyFileToUpload: false,
      uploadedFile: null,
      fileUploadButtonColor: 'normal',
      postForm: {
        imageId: null
      }
    }
  },
  methods: {
    onFileChange (file: any) {
      if (!file) {
        return
      }
      const reader = new FileReader()
      reader.onload = (e: any) => {
        this.imageUrl = e.target.result
      }
      reader.readAsDataURL(file)
      this.fileUploadButtonColor = 'error'
      this.readyFileToUpload = true
    },
    saveFile (item: any, imageType: number) {
      if (item.type.indexOf('image') === -1) {
        Swal.fire('ОШИБКА', 'Не изображение', 'error')
      } else {
        if (item.size > 5242880) {
          Swal.fire('ОШИБКА', 'Размер изображения не должен превышать 5Мб', 'error')
        }
        Swal.fire({
          title: 'Уверены, что хотите загрузить новое изображение ?',
          text: 'После загрузки изображения не забудьте сохранить изменения.',
          showDenyButton: true,
          confirmButtonText: 'Сохранить',
          denyButtonText: 'Отменить'
        }).then((result: any) => {
          if (result.isConfirmed) {
            this.saveImage(item, imageType)
          } else if (result.isDenied) {
            Swal.fire('Отменено', '', 'info')
          }
        })
      }
    },
    async saveImage (file: any, imageType: any) {
      this.loading = true
      try {
        const formData = new FormData()
        formData.append('name', file.name)
        formData.append('image', file)
        formData.append('fileTypeId', imageType)
        const result = await saveFileNew(formData)
        this.postForm.imageId = result.id

        Swal.fire('Успешно сохранено!', '', 'success')
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    }
  }
})
