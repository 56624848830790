import axios, { setAuthorizationHeader, setCustomErrorHandling } from '@/services/axios'
import { AxiosResponse } from 'axios'
import { Tag } from './types'
import { CreateTag, RemoveTag, TagItemsDelete, TagItemsCreate } from './api-types'

const getItems = 'tags'
const tagCreate = 'tags/create'
const tagRemove = 'tags/delete'
const tagItemsCreateRequest = 'items/tags/create'
const tagItemsDeleteRequest = 'items/tags/delete'
const getProductsOfTagRequest = 'items/tags/search'

export const getitems = async (): Promise<Tag[]> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: Tag[];
  }> = await axios.post(getItems)
  return result.data.data
}

export const tagcreate = async (createTag: CreateTag): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(tagCreate, createTag)
  return result.data.data
}

// export const tagupdate = async (updateTag: UpdateTag): Promise<any> => {
//   setAuthorizationHeader(axios)
//   const result: AxiosResponse<{
//     data: any;
//   }> = await axios.post(tagUpdate, updateTag)
//   return result.data.data
// }

export const tagremove = async (removeTag: RemoveTag): Promise<any> => {
  setAuthorizationHeader(axios)
  setCustomErrorHandling(axios)
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(tagRemove, removeTag)
  return result.data.data
}

export const tagitemscreate = async (tagItemsCreate: TagItemsCreate): Promise<any> => {
  setAuthorizationHeader(axios)
  setCustomErrorHandling(axios)
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(tagItemsCreateRequest, tagItemsCreate)
  return result.data.data
}

export const tagitemsdelete = async (tagItemsDelete: TagItemsDelete): Promise<any> => {
  setAuthorizationHeader(axios)
  setCustomErrorHandling(axios)
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(tagItemsDeleteRequest, tagItemsDelete)
  return result.data.data
}

export const getProductsOfTag = async (tagId: number): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(getProductsOfTagRequest, { tagId: tagId })
  return result.data.data
}
