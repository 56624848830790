/* eslint-disable @typescript-eslint/no-unused-vars */

import { ActionContext, ActionTree, GetterTree, MutationTree } from 'vuex'
import { ADD_LOCKERS_TO_STACK } from './mutations'
import { RootState } from '@/store/state'
import { LockersState, LockersShowResponse, Locker } from './types'
import { show as LockersShow } from './api-requests'
import { parseAxiosError } from '@/services/api'

type LockersContext = ActionContext<LockersState, RootState>

const initialState: LockersState = {
  lockers: [],
  serverTime: null
}

const getters: GetterTree<LockersState, RootState> = {
  lockers (state: LockersState): Locker[] {
    return state.lockers
  },
  serverTime (state: LockersState): any {
    return state.serverTime
  }
}

const mutations: MutationTree<LockersState> = {
  [ADD_LOCKERS_TO_STACK] (state: LockersState, lockersResponse: LockersShowResponse) {
    state.lockers = lockersResponse.data
    state.serverTime = lockersResponse.dateTime
  }
}

const actions: ActionTree<LockersState, RootState> = {
  async show ({ commit, dispatch, state }: LockersContext): Promise<LockersShowResponse> {
    try {
      const result = await LockersShow()
      commit(ADD_LOCKERS_TO_STACK, result)
      return result
    } catch (error) {
      return Promise.reject(parseAxiosError(error))
    }
  }
}

export const lockers = {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions
}
