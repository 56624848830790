<template>
  <v-container class="py-4">
    <LoadingBar :loading="loading"></LoadingBar>
    <v-row class="justify-content-center">
      <div>
        <v-btn @click="goBack()" class="mb-4">
          <v-icon>mdi-keyboard-backspace</v-icon>
          Назад
        </v-btn>
      </div>
      <div class="col-12">
        <h1 class="text-center"> Настройка расписания </h1>
      </div>
    </v-row>
    <v-row class="justify-content-center">
        <v-col>
          <v-checkbox v-for="i in 4" :key="i"
            :input-value="shedules.find(el => el.day == i  && el.status == 1)"
            :label="getDayName(i)" @change="chooseDay(i, $event)"
          ></v-checkbox>
        </v-col>
        <v-col>
          <template v-for="i in 7">
            <v-checkbox  v-if="i > 4" :key="i"
              :input-value="shedules.find(el => el.day == i  && el.status == 1)"
              :label="getDayName(i)" @change="chooseDay(i, $event)"
            ></v-checkbox>
          </template>
        </v-col>
    </v-row>
    <v-row class="justify-content-center">
      <v-col>
        <div class="flex-direction-column">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center">
                    День недели
                  </th>
                  <th class="text-center">
                    Доступный период
                  </th>
                  <th class="text-center">
                    Дискретность
                  </th>
                  <th class="text-center">
                    Диапазоны
                  </th>
                  <th class="text-center">
                    Действия
                  </th>
                </tr>
              </thead>
              <tbody>
                <template v-for="item in shedules">
                  <tr v-if="item.date == null && item.status == 1" :key="item.id">
                    <td>{{ getDayName(item.day) }}</td>
                    <td>{{ formatTime(item.startPeriod) }} - {{ formatTime(item.endPeriod) }}</td>
                    <td>{{ item.discreteness }}</td>
                    <td>
                      <span v-for="(sheduleItem, index) in item.productScheduleItems" :key="sheduleItem.id">
                        <label v-if="index > 0 && sheduleItem.status == 1"><br></label>
                        <label v-if="sheduleItem.status == 1">{{ formatTime(sheduleItem.startRange) }} - {{ formatTime(sheduleItem.endRange) }} (</label>
                        <label v-if="sheduleItem.status == 1 && sheduleItem.cutId == 2452">День)</label>
                        <label v-if="sheduleItem.status == 1 && sheduleItem.cutId == 2453">Ночь)</label>
                      </span>
                    </td>
                    <td><v-btn @click="routeToShedule(item)">
                      <v-icon dark>
                        {{ icons.mdiPencil }}
                      </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </template>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import LoadingBar from '@/components/partials/components/LoadingBar.vue'
import { show as getSheduleItems, dateSave } from '@/store/shedules/api-requests'
import { DaysWeek } from '@/types/vue-enums'
import { mdiPencil } from '@mdi/js'
export default Vue.extend({
  components: {
    LoadingBar
  },
  data () {
    return {
      icons: {
        mdiPencil
      },
      loading: false,
      shedules: []
    }
  },
  props: {
    id: {
      require: true
    }
  },
  computed: {
    ...mapGetters({ user: 'user/user' })
  },
  methods: {
    async sheduleLoad (productId) {
      this.loading = true
      try {
        const result = await getSheduleItems({ productId: productId })
        this.shedules = this.sortShedule(result)
      } catch (error) {
        console.log(error.message)
        this.goBack()
      } finally {
        this.loading = false
      }
    },
    async dateSave (productId, status, day) {
      this.loading = true
      try {
        this.shedules = await dateSave({ productId: productId, status: status, day: day, type: 1 })
      } catch (error) {
        console.log(error.message)
      } finally {
        this.loading = false
        this.shedules = this.sortShedule(this.shedules)
      }
    },
    sortShedule (shedules) {
      return shedules.sort(function compare (a, b) {
        if (a.day < b.day) return -1
        if (a.day > b.day) return 1
        return 0
      })
    },
    chooseDay (day, value) {
      if (value === 0) {
        value = 2
      }
      this.dateSave(this.id, value, day)
    },
    formatTime (time) {
      return time.slice(0, -3)
    },
    getDayName (day) {
      return DaysWeek[day].toString()
    },
    routeToShedule (item) {
      this.$router.push({ name: 'SheduleDiapasonAdd', params: { item: item } })
    },
    goBack () {
      console.log('goBack')
      if (this.id) {
        this.$router.push({ name: 'SlideAddPrice', params: { id: this.id } })
      } else {
        this.$router.push({ name: 'PriceList' })
      }
    }
  },
  mounted () {
    if (this.id) {
      this.sheduleLoad(this.id)
    } else {
      this.goBack()
    }
  }
})
</script>
