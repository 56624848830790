





























































import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'
import Navbar from '@/components/partials/main/Navbar.vue'
import NavbarUserType from '@/components/partials/main/NavbarUserType.vue'
import FooterMain from '@/components/partials/main/FooterMain.vue'
import BackToTop from '@/components/partials/components/BackToTop.vue'
import Snackbar from '@/components/partials/components/Snackbar.vue'
export default Vue.extend({
  name: 'app',
  computed: {
    ...mapState(['snackbar']),
    ...mapGetters({
      user: 'user/user'
    }),
    routePartialType (): string {
      return (this.$route as any).meta.partialType
    }
  },
  methods: {},
  components: {
    Navbar,
    NavbarUserType,
    FooterMain,
    BackToTop,
    Snackbar
  }
})
