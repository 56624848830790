import axios, { setAuthorizationHeader } from '@/services/axios'
import { AxiosResponse } from 'axios'
import { LockersShowResponse, LockerHistory, Session, OrderInfo, SessionTime, QRCode, VerifyAnswere } from './types'
import { LockersOpenPost, LockerHistoryPost, SessionsGetPost, CreateOrderPost, OrderInfoPost, SendQrPost, ChangeEmailPost, changeLockerRemovedPost } from './api-types'

const routeLockerShow = 'lockers/show'
const routeLockerHistory = 'lockers/history'
const routeLockersOpen = 'lockers/open'
const routeSessionsget = 'lockersessions'
const routeSessionsTime = 'lockersessions/findtime'
const routeVerifyOrder = 'order/verify'
const routeCreateOrder = 'order/create'
const routeOrderInfo = 'order'
const routeQr = 'qr'
const changrEmail = 'email/update'
const routeGetPassword = '/lockers/password'
const changeLockerRemovedUrl = '/lockers/updateremoved'

export const password = async (): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(routeGetPassword)
  return result.data.data
}

export const show = async (): Promise<LockersShowResponse> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: LockersShowResponse;
  }> = await axios.post(routeLockerShow)
  return result.data
}

export const history = async (lockerHistoryPost: LockerHistoryPost): Promise<LockerHistory[]> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: LockerHistory[];
  }> = await axios.post(routeLockerHistory, lockerHistoryPost)
  return result.data.data
}

export const open = async (lockersOpenPost: LockersOpenPost): Promise<QRCode[]> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: QRCode[];
  }> = await axios.post(routeLockersOpen, lockersOpenPost)
  return result.data.data
}

export const sessionsget = async (sessionsGetPost: SessionsGetPost): Promise<Session[]> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: Session[];
  }> = await axios.post(routeSessionsget, sessionsGetPost)
  return result.data.data
}

export const sessionsTime = async (sessionsGetPost: SessionsGetPost): Promise<SessionTime> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: SessionTime;
  }> = await axios.post(routeSessionsTime, sessionsGetPost)
  return result.data.data
}

export const verifyorder = async (createOrderPost: CreateOrderPost): Promise<VerifyAnswere> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: VerifyAnswere;
  }> = await axios.post(routeVerifyOrder, createOrderPost)
  return result.data.data
}

export const createorder = async (createOrderPost: CreateOrderPost): Promise<string> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: string;
  }> = await axios.post(routeCreateOrder, createOrderPost)
  return result.data.data
}

export const orderinfo = async (createOrderPost: OrderInfoPost): Promise<OrderInfo> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: OrderInfo;
  }> = await axios.post(routeOrderInfo, createOrderPost)
  return result.data.data
}

export const sendqr = async (createOrderPost: SendQrPost): Promise<boolean> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: boolean;
  }> = await axios.post(routeQr, createOrderPost)
  return result.data.data
}

export const changeemail = async (createOrderPost: ChangeEmailPost): Promise<boolean> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: boolean;
  }> = await axios.post(changrEmail, createOrderPost)
  return result.data.data
}

export const changeLockerRemovedApi = async (obj: changeLockerRemovedPost): Promise<OrderInfo> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: OrderInfo;
  }> = await axios.post(changeLockerRemovedUrl, obj)
  return result.data.data
}
