<template>
  <v-container class="py-4">
    <LoadingBar :loading="loading"></LoadingBar>
    <v-row class="justify-content-center">
      <div>
        <v-btn @click="goBack()" class="mb-4">
          <v-icon>mdi-keyboard-backspace</v-icon>
          Назад
        </v-btn>
      </div>
      <div class="col-12">
        <h1 class="text-center"> Настройка по дате </h1>
      </div>
    </v-row>
    <v-row class="justify-content-center">
        <v-col>
          <v-menu
            v-model="menu2"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date2"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date2"
              @input="menu2 = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col>
          <v-menu
            v-model="menu3"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date3"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date3"
              @input="menu3 = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col>
          <v-btn
            @click="filterShedule()">
            Показать
          </v-btn>
        </v-col>
        <v-col>
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
          <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on">
            Добавить дату
          </v-btn>
          </template>
            <v-date-picker
            v-model="date"
            no-title
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="menu = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="saveDate(date)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
        </v-col>
    </v-row>
    <v-row class="justify-content-center">
      <v-col>
        <div class="flex-direction-column">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center">
                    Дата
                  </th>
                  <th class="text-center">
                    Доступный период
                  </th>
                  <th class="text-center">
                    Дискретность
                  </th>
                  <th class="text-center">
                    Диапазоны
                  </th>
                  <th class="text-center">
                    Действия
                  </th>
                </tr>
              </thead>
              <tbody>
                <template v-for="item in filteredShedules">
                  <tr v-if="item.date!= null && item.status == 1" :key="item.id">
                    <td>{{ item.date }}</td>
                    <td>{{ formatTime(item.startPeriod) }} - {{ formatTime(item.endPeriod) }}</td>
                    <td>{{ item.discreteness }}</td>
                    <td>
                      <span v-for="(sheduleItem, index) in item.productScheduleItems" :key="sheduleItem.id">
                        <label v-if="index > 0 && sheduleItem.status == 1"><br></label>
                        <label v-if="sheduleItem.status == 1">{{ formatTime(sheduleItem.startRange) }} - {{ formatTime(sheduleItem.endRange) }} (</label>
                        <label v-if="sheduleItem.status == 1 && sheduleItem.cutId == 2452">День)</label>
                        <label v-if="sheduleItem.status == 1 && sheduleItem.cutId == 2453">Ночь)</label>
                      </span>
                    </td>
                    <td>
                      <v-btn @click="editDateShedule(item)">
                      <v-icon dark>
                        {{ icons.mdiPencil }}
                      </v-icon>
                      </v-btn>
                      <v-btn @click="delDateShedule(item)">
                        <v-icon dark>
                          {{ icons.mdiCloseCircleOutline }}
                        </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </template>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import LoadingBar from '@/components/partials/components/LoadingBar.vue'
import { show as getSheduleItems, dateSave } from '@/store/shedules/api-requests'
import { DaysWeek } from '@/types/vue-enums'
import { mdiPencil, mdiCloseCircleOutline } from '@mdi/js'
export default Vue.extend({
  components: {
    LoadingBar
  },
  data () {
    return {
      icons: {
        mdiPencil,
        mdiCloseCircleOutline
      },
      loading: false,
      shedules: [],
      filteredShedules: [],
      menu: false,
      menu2: false,
      menu3: false,
      date: null,
      date2: null,
      date3: null
    }
  },
  props: {
    item: {
      require: true
    }
  },
  computed: {
    ...mapGetters({ user: 'user/user' })
  },
  methods: {
    async sheduleLoad (productId) {
      this.loading = true
      try {
        const result = await getSheduleItems({ productId: productId })
        this.shedules = this.sortShedule(result)
        this.filterShedule()
        console.log(this.shedules)
      } catch (error) {
        console.log(error.message)
        this.goBack()
      } finally {
        this.loading = false
      }
    },
    async dateSave (productId, status, day, date) {
      this.loading = true
      try {
        this.shedules = await dateSave({ productId: productId, status: status, day: day, type: 2, date: date })
      } catch (error) {
        console.log(error.message)
      } finally {
        this.loading = false
        this.shedules = this.sortShedule(this.shedules)
      }
    },
    filterShedule () {
      this.filteredShedules = this.shedules.filter(el => el.date != null && new Date(el.date) >= new Date(this.date2) && new Date(el.date) <= new Date(this.date3))
    },
    sortShedule (shedules) {
      return shedules.sort(function compare (a, b) {
        if (a.day < b.day) return -1
        if (a.day > b.day) return 1
        return 0
      })
    },
    chooseDay (day, value) {
      if (value === 0) {
        value = 2
      }
      this.dateSave(this.id, value, day)
    },
    saveDate (date) {
      this.menu = false
      let dateDay = new Date(date).getDay()
      if (dateDay === 0) dateDay = 7
      this.dateSave(this.item.productId, 1, dateDay, date)
    },
    formatTime (time) {
      return time.slice(0, -3)
    },
    getDayName (day) {
      console.log(day)
      return DaysWeek[day].toString()
    },
    editDateShedule (item) {
      this.$router.push({ name: 'SheduleDateDiapasonAdd', params: { item: item } })
    },
    delDateShedule (item) {
      if (item) {
        this.dateSave(item.productId, 2, item.day, item.date)
      } else {
        this.goBack()
      }
    },
    goBack () {
      console.log('goBack')
      if (this.item) {
        this.$router.push({ name: 'SheduleDiapasonAdd', params: { item: this.item } })
      } else {
        this.$router.push({ name: 'PriceList' })
      }
    }
  },
  mounted () {
    const date = new Date()
    const date2 = new Date(date.getFullYear(), (date.getMonth()), date.getDate())
    const date3 = new Date(date.getFullYear(), (date.getMonth() + 1), date.getDate())
    this.date2 = date2.toISOString().substr(0, 10)
    this.date3 = date3.toISOString().substr(0, 10)

    if (this.item) {
      this.sheduleLoad(this.item.productId)
    } else {
      this.goBack()
    }
  }
})
</script>
