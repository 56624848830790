/* eslint-disable @typescript-eslint/no-unused-vars */

import { ActionContext, ActionTree, GetterTree, MutationTree } from 'vuex'
import { ADD_SHEDULES_TO_STACK } from './mutations'
import { RootState } from '@/store/state'
import { SheduleState } from './types'
import { show as InformationShow } from './api-requests'
import { parseAxiosError } from '@/services/api'

type InformationContext = ActionContext<SheduleState, RootState>

const initialState: SheduleState = {
  shedules: []
}

const getters: GetterTree<SheduleState, RootState> = {
  //
}

const mutations: MutationTree<SheduleState> = {
  [ADD_SHEDULES_TO_STACK] (state: SheduleState, informationResponse) {
    state.shedules = informationResponse
  }
}

const actions: ActionTree<SheduleState, RootState> = {
  async show ({ commit, dispatch, state }: InformationContext) {
    try {
      const result = await InformationShow({ productId: 81160 })
      commit(ADD_SHEDULES_TO_STACK, result)
      return result
    } catch (error) {
      return Promise.reject(parseAxiosError(error))
    }
  }
}

export const shedules = {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions
}
