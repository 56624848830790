<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5" style="margin: 0 auto;">Выбор даты</span>
          <v-icon @click="close()">mdi-close</v-icon>
        </v-card-title>
        <v-card-text class="pb-0">
          <v-container>
            <v-row>
              <v-col class="pickerTitle">Год</v-col>
              <v-col><DateTimeInput :unit="'YEAR'" :width="'150px'" :min="'2020'" :max="'2031'" @changeFromChild="changeValue($event)"></DateTimeInput></v-col>
            </v-row>
            <v-row>
              <v-col class="pickerTitle">Месяц</v-col>
              <v-col><DateTimeInput :unit="'MONTH'" :type="'month'" :width="'300'" @changeFromChild="changeValue($event)"></DateTimeInput></v-col>
            </v-row>
            <v-row>
              <v-col class="pickerTitle">Число</v-col>
              <v-col><DateTimeInput :unit="'DAY'" :width="'300'" :year="year" :month="month" @changeFromChild="changeValue($event)"></DateTimeInput></v-col>
            </v-row>
            <!-- <v-row>
              <v-col class="pickerTitle">Часы</v-col>
              <v-col><DateTimeInput :unit="'HOUR'" :width="'300'" @changeFromChild="changeValue($event)"></DateTimeInput></v-col>
            </v-row>
            <v-row>
              <v-col class="pickerTitle">Минуты</v-col>
              <v-col><DateTimeInput :unit="'MINUTE'" :width="'300'" @changeFromChild="changeValue($event)"></DateTimeInput></v-col>
            </v-row> -->
            <v-row>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
            <v-col v-if="showResult" cols="8" class="text-center mb-1" style="font-size: 20px;">
                  {{ visibleDatetime }}
            </v-col>
            <v-col cols="4">
              <v-btn
                class="pa-4 mb-1"
                color="darken-1"
                @click="onOk()"
              >
                Выбрать
              </v-btn>
            </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Vue from 'vue'
import DateTimeInput from '@/components/food/foodOrdersArchive/DateTimeInput.vue'
export default Vue.extend({
  props: {
    dialogShow: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    showResult: true,
    dialog: false,
    visibleDatetime: null,
    date: new Date().toISOString(),
    year: new Date().toISOString().substr(0, 4),
    month: new Date().toISOString().substr(5, 2),
    day: new Date().toISOString().substr(8, 2),
    hour: '00',
    minute: '00'
  }),
  watch: {
    dialogShow (val) {
      this.dialog = val
    }
  },
  mounted () {
    //
  },
  components: {
    DateTimeInput
  },
  computed: {
    //
  },
  methods: {
    onOk () {
      this.$emit('changeFromChild2', this.date)
      this.close()
    },
    close () {
      this.dialog = false
      this.$emit('changeFromChild', this.dialog)
    },
    changeValue (dateItem) {
      this.showResult = false
      if (dateItem.unit === 'YEAR') {
        this.year = dateItem.val
      }
      if (dateItem.unit === 'MONTH') {
        this.month = dateItem.val
      }
      if (dateItem.unit === 'DAY') {
        this.day = dateItem.val
      }
      if (dateItem.unit === 'HOUR') {
        this.hour = dateItem.val
      }
      if (dateItem.unit === 'MINUTE') {
        this.minute = dateItem.val
      }
      this.date = this.year + '-' + this.month + '-' + this.day + 'T' + this.hour + ':' + this.minute + ':00'
      // this.visibleDatetime = this.date.replace('T', ' ').substr(0, 16)
      this.visibleDatetime = this.date.substr(0, 10)
      this.showResult = true
    }
  }
})
</script>
<style scoped lang="scss">
  .pickerTitle{
    display: flex;
    align-items: center;
    font-size: 20px;
  }
</style>
<style>
  #pickerMONTH .v-date-picker-header,
  #pickerDAY .v-date-picker-header{
    display: none;
  }
  #pickerInputYEAR,
  #pickerInputMONTH,
  #pickerInputDAY,
  #pickerInputHOUR,
  #pickerInputMINUTE{
    text-align:center!important;
  }
</style>
