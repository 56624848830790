<template>
  <v-container class="py-4">
    <LoadingBar :loading="loading"></LoadingBar>
    <v-row class="justify-content-center">
      <v-col>
        <GoBackButton :loading="loading"></GoBackButton>
        <h1 class="text-center"> История цен </h1>
      </v-col>
    </v-row>
    <v-row>
      <h2>День</h2>
    </v-row>
    <v-row class="justify-content-center">
      <v-col>
        <v-data-table
          dense
          :headers="priceListHistoryHeaders"
          :items="itemsDay"
          item-key="id"
          class="elevation-1"
        >
          <template v-slot:item="{ item }">
            <tr :class="itemsDay.indexOf(item) === 0 ? 'font-weight-bold' : ''">
                <td>{{item.name}}</td>
                <td>{{item.price === 0 ? '-' : item.price}}</td>
                <td>{{item.dateCreation}}</td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row><h2>Ночь</h2></v-row>
    <v-row class="justify-content-center">
      <v-col>
        <v-data-table
          dense
          :headers="priceListHistoryHeaders"
          :items="itemsNight"
          item-key="id"
          class="elevation-1"
        >
          <template v-slot:item="{ item }">
            <tr :class="itemsNight.indexOf(item) === 0 ? 'font-weight-bold' : ''">
                <td>{{item.name}}</td>
                <td>{{item.price === 0 ? '-' : item.price}}</td>
                <td>{{item.dateCreation}}</td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import LoadingBar from '@/components/partials/components/LoadingBar.vue'
import GoBackButton from '@/components/partials/components/GoBackButton.vue'
import { getCategoryItemApi } from '@/store/pricelist/api-requests'
export default Vue.extend({
  components: {
    GoBackButton,
    LoadingBar
  },
  data () {
    return {
      loading: false,
      itemsDay: [],
      itemsNight: [],
      currentDayPriceInfo: {}
    }
  },
  props: {
    id: {
      type: [Number, String],
      require: true
    }
  },
  computed: {
    ...mapGetters({ user: 'user/user' }),
    priceListHistoryHeaders () {
      return [
        { text: 'Наименование', value: 'name', sortable: false },
        // { text: 'ID IKKO', value: 'idikko', sortable: false },
        { text: 'Цена', value: 'price', sortable: false },
        { text: 'Дата создания', value: 'dateCreation', sortable: false }
      ]
    }
  },
  methods: {
    async getItem (id) {
      this.loading = true
      let result
      try {
        result = await getCategoryItemApi({ id: id, history: true })
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
        const cutDay = result[0].productCuts.find(el => el.cutId === 2452)
        if (cutDay) {
          const currentPrice = cutDay.marketProductCutPrices[0]
          if (cutDay.priceHistory) {
            const cutDayPriceHistory = cutDay.priceHistory.map(el => {
              el.name = result[0].name
              return el
            })
            cutDayPriceHistory.unshift({ name: result[0].name, price: currentPrice.price, dateCreation: currentPrice.timestamp })
            this.itemsDay = cutDayPriceHistory
          }
        }
        const cutNight = result[0].productCuts.find(el => el.cutId === 2453)
        if (cutNight) {
          const currentPrice = cutNight.marketProductCutPrices[0]
          if (cutNight.priceHistory) {
            const cutNightPriceHistory = cutNight.priceHistory.map(el => {
              el.name = result[0].name
              return el
            })
            cutNightPriceHistory.unshift({ name: result[0].name, price: currentPrice.price, dateCreation: currentPrice.timestamp })
            this.itemsNight = cutNightPriceHistory
          }
        }
      }
    }
  },
  mounted () {
    if (this.id) {
      this.getItem(this.id)
    }
  }
})
</script>
