import axios, { setAuthorizationHeader } from '@/services/axios'
import { AxiosResponse } from 'axios'

const getItemsUrl = 'discounttemplate'
const createItemUrl = 'discounttemplate/create'
const updateItemUrl = 'discounttemplate/update'
const deleteItemUrl = 'discounttemplate/delete'
const getDiscountTemplateItemsUrl = 'discounttemplate/items'
const createDiscountTemplateItemUrl = 'discounttemplate/items/create'
const updateDiscountTemplateItemUrl = 'discounttemplate/items/update'
const deleteDiscountTemplateItemUrl = 'discounttemplate/items/delete'
const getTemplateByIdUrl = 'discounttemplate/getbyid'

export const show = async () => {
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(getItemsUrl)
  return result.data.data
}

export const create = async (item: any): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(createItemUrl, item)
  return result.data.data
}

export const update = async (item: any): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(updateItemUrl, item)
  return result.data.data
}

export const deleteItem = async (item: any): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(deleteItemUrl, item)
  return result.data.data
}

export const getDiscountTemplateItems = async (searchParams: any) => {
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(getDiscountTemplateItemsUrl, searchParams)
  return result.data.data
}

export const createDiscountTemplateItem = async (item: any): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(createDiscountTemplateItemUrl, item)
  return result.data.data
}

export const updateDiscountTemplateItem = async (item: any): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(updateDiscountTemplateItemUrl, item)
  return result.data.data
}

export const deleteDiscountTemplateItem = async (item: any): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(deleteDiscountTemplateItemUrl, item)
  return result.data.data
}

export const getTemplateByIdApi = async (idObj: any) => {
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(getTemplateByIdUrl, idObj)
  return result.data.data
}
