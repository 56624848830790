import { DateSavePost, SheduleGetPost } from './api-types'
import { Shedule } from './types'
import axios, { setAuthorizationHeader } from '@/services/axios'
import { AxiosResponse } from 'axios'

const getItems = 'product/schedule/search'
const saveItems = 'product/schedule/update'
const saveDate = 'product/schedule/choose'

export const show = async (sheduleGetPost: SheduleGetPost) => {
  const result: AxiosResponse<{
    data: Shedule[];
  }> = await axios.post(getItems, sheduleGetPost)
  return result.data.data
}

export const sheduleSave = async (item: Shedule): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(saveItems, item)
  return result.data.data
}

export const dateSave = async (dateSavePost: DateSavePost): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(saveDate, dateSavePost)
  return result.data.data
}
