





































































































import Vue from 'vue'
import { orderinfo as orderInfo, sendqr as sendQr, changeemail as changeEmail } from '@/store/locker/api-requests'
import { OrderInfoPost, SendQrPost, ChangeEmailPost } from '@/store/locker/api-types'
export default Vue.extend({
  data () {
    return {
      rulesNumber: [
        (value: any) => !!value || 'Не должно быть пустым.',
        (value: any) => (!isNaN(value)) || 'Должно быть число'
      ],
      rulesEmail: [
        (value: any) => !!value || 'Не должно быть пустым.',
        (value: any) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Некорректный e-mail.'
        }
      ],
      rulesPhone: [
        (value: any) => !!value || 'Не должно быть пустым.',
        (value: any) => {
          const pattern = /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)?$/
          return pattern.test(value) || 'Некорректный телефон.'
        }
      ],
      searchByType: 'orderNumber',
      validSearch: false,
      loading: false,
      emailString: '',
      orderId: 0,
      valid: false,
      validEmail: false,
      status: -1,
      sendEmailFlag: false,
      searchValue: null,
      found: false
    }
  },
  components: {
  },
  methods: {
    refresh () {
      this.validSearch = true
      this.searchValue = null
      this.searchByType = 'orderNumber'
      this.found = false
      this.status = -1
    },
    resetValidation () {
      // this.$refs.form.resetValidation()
      this.searchValue = null
    },
    async orderSearch () {
      const orderInfoPost: OrderInfoPost = { [this.searchByType]: this.searchValue }
      this.loading = true
      try {
        const result = await orderInfo(orderInfoPost)
        this.status = result.status
        this.orderId = result.id
        this.emailString = result.email ? result.email : ''
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
        this.found = true
      }
    },
    async changeEmail () {
      if (this.validEmail) {
        const changeEmailPost: ChangeEmailPost = {
          id: this.orderId,
          email: this.emailString
        }
        this.loading = true
        try {
          await changeEmail(changeEmailPost)
        } catch (error) {
          console.log(error)
        } finally {
          this.loading = false
        }
      }
    },
    async sendEmail () {
      const sendQrPost: SendQrPost = { id: this.orderId }
      this.loading = true
      try {
        const result = await sendQr(sendQrPost)
        this.sendEmailFlag = result
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    }
  }
})
