import axios, { setAuthorizationHeader } from '@/services/axios'
import { AxiosResponse } from 'axios'

const getItems = 'categories/topics'
const saveItems = 'categories/topics/save'
const checkForDeleteTopic = 'categories/topics/checkfordelete'

export const show = async () => {
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(getItems)
  return result.data.data
}

export const catsave = async (catSave: any): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(saveItems, catSave)
  return result.data.data
}

export const checkfordeletetopic = async (id: number): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(checkForDeleteTopic, { id: id })
  return result.data.data
}
