



























































































































































































































































































































import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import Swal from 'sweetalert2'
import LoadingBar from '@/components/partials/components/LoadingBar.vue'
import { savefileNew as saveFileNew } from '@/services/fileUpload'
import { UpdateOrCreateAdvertisement } from '@/store/advertisement/types'
import { create as AdvertisementCreate, getItemsWithFilter as GetItemsWithFilter, updateRemoved as UpdateRemoved, update as AdvertisementUpdate } from '@/store/advertisement/api-requests'
export type BaseData = {
    loading: any;
    allAdvItems: any;
    selected: any;
    headers: any;
    sortBy: string;
    sortDesc: boolean;
    imageUrl: string;
    readyFileToUpload: boolean;
    uploadedFile: any;
    fileUploadButtonColor: string;
    menuDateFrom: boolean;
    menuDateTo: boolean;
    postForm: UpdateOrCreateAdvertisement;
    filterButtons: any;
    filterState: number;
    toggleForm: boolean;
    formTitle: string;
}

export default Vue.extend({
  components: {
    LoadingBar
  },
  props: {
  },
  data (): BaseData {
    return {
      loading: false,
      allAdvItems: [],
      selected: [],
      headers: [
        { text: 'Наименование', align: 'name', sortable: true, value: 'name' },
        { text: 'Старт', value: 'timestampFrom', sortable: true },
        { text: 'Конец', value: 'timestampTo', sortable: true },
        { text: 'Активно', value: 'status', sortable: true },
        { text: 'Картинка', align: 'start', sortable: false, value: 'advertisementFile.slug' },
        { text: 'Приоритет', value: 'priority', sortable: true },
        { text: 'В архиве', value: 'removed', sortable: true }
      ],
      sortBy: 'status',
      sortDesc: true,
      imageUrl: '',
      readyFileToUpload: false,
      uploadedFile: null,
      fileUploadButtonColor: 'normal',
      menuDateFrom: false,
      menuDateTo: false,
      postForm: {
        id: null,
        name: '',
        timestampFrom: new Date().toISOString().substr(0, 10),
        timeStampTo: new Date().toISOString().substr(0, 10),
        status: false,
        imageId: 0
      },
      filterButtons: [
        { title: 'Действующие', queryParams: { removed: 0 }, state: 0 },
        { title: 'Архив', queryParams: { removed: 1 }, state: 1 },
        { title: 'Все', queryParams: { all: 1 }, state: 2 }
      ],
      filterState: 0,
      toggleForm: false,
      formTitle: 'Добавление'
    }
  },
  computed: {
    computedHeaders (): any {
      return this.filterState !== 2 ? this.headers.filter((th: any) => th.text !== 'В архиве') : this.headers
    },
    ...mapGetters({ user: 'user/user' })
  },
  methods: {
    async getAdvItemsFilter (filterVal: any) {
      this.loading = true
      let result
      try {
        result = await GetItemsWithFilter(filterVal)
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
        if (result) {
          const allAdvItems = JSON.parse(JSON.stringify(result))
          allAdvItems.map((el: any) => {
            if (el.removed === 1) {
              el.removed = true
            }
            return el
          })
          this.allAdvItems = allAdvItems
          this.selected = []
          console.log(this.allAdvItems)
        } else {
          this.allAdvItems = []
        }
      }
    },
    async toggleSelection (id: number) {
      await this.$nextTick()
      // console.log(this.selected)
    },
    async checkSelectAll (el: any) {
      await this.$nextTick()
      // console.log(this.selected)
    },
    async updateRemoved (removed: number) {
      const itemsForApi: any = []
      this.selected.map((el: any) => {
        itemsForApi.push(el.id)
      })
      const obj = {
        ids: itemsForApi,
        removed: removed
      }
      const result = await UpdateRemoved(obj)
      if (result) {
        this.getAdvItemsFilter(this.filterButtons[this.filterState].queryParams)
        this.selected = []
      }
    },
    clearForm () {
      this.postForm = {
        id: null,
        name: '',
        timestampFrom: new Date().toISOString().substr(0, 10),
        timeStampTo: new Date().toISOString().substr(0, 10),
        status: false,
        imageId: 0
      }
      this.imageUrl = ''
      this.readyFileToUpload = false
    },
    editRow (item: any) {
      this.toggleForm = true
      // console.log(item.advertisementFile)
      this.postForm = {
        id: item.id,
        name: item.name,
        timestampFrom: item.timestampFrom,
        timeStampTo: item.timeStampTo,
        status: item.status,
        priority: item.priority,
        imageId: item.imageId
      }
      this.imageUrl = item.advertisementFile.slug
      this.formTitle = 'Редактирование'
      document.documentElement.scrollTop = 0
    },
    onFileChange2 (file: any) {
      if (!file) {
        return
      }
      const reader = new FileReader()
      reader.onload = (e: any) => {
        this.imageUrl = e.target.result
      }
      reader.readAsDataURL(file)
      this.fileUploadButtonColor = 'error'
      this.readyFileToUpload = true
    },
    saveFile (item: any, imageType: number) {
      if (item.type.indexOf('image') === -1) {
        Swal.fire('ОШИБКА', 'Не изображение', 'error')
      } else {
        if (item.size > 5242880) {
          Swal.fire('ОШИБКА', 'Размер изображения не должен превышать 5Мб', 'error')
        }
        Swal.fire({
          title: 'Уверены, что хотите загрузить новое изображение ?',
          text: 'После загрузки изображения не забудьте сохранить изменения.',
          showDenyButton: true,
          confirmButtonText: 'Сохранить',
          denyButtonText: 'Отменить'
        }).then((result) => {
          if (result.isConfirmed) {
            this.saveImage(item, imageType)
          } else if (result.isDenied) {
            Swal.fire('Отменено', '', 'info')
          }
        })
      }
    },
    async saveImage (file: any, imageType: any) {
      this.loading = true
      try {
        const formData = new FormData()
        formData.append('name', file.name)
        formData.append('image', file)
        formData.append('fileTypeId', imageType)
        const result = await saveFileNew(formData)
        this.postForm.imageId = result.id

        Swal.fire('Успешно сохранено!', '', 'success')
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
        this.readyFileToUpload = false
      }
    },
    confirmNewAdvertisementForm () {
      this.postForm.priority = this.postForm.status ? this.postForm.priority : 0
      if (!this.postForm.id) {
        this.createAdvertisementItem()
      } else {
        this.updateAdvertisementItem(this.postForm)
      }
      // todo validate inputs
    },
    async createAdvertisementItem () {
      this.loading = true
      let result
      try {
        result = await AdvertisementCreate(this.postForm)
      } catch (error) {
        // this.openPopup(error, 'warning')
      } finally {
        // this.openPopup('Добавлен новый элемент!', 'success', false)
        // this.allAdvItems.push(result)
        this.getAdvItemsFilter(this.filterButtons[0].queryParams)
        this.toggleForm = false
      }
    },
    async updateAdvertisementItem (advObj: any) {
      this.loading = true
      advObj.priority = advObj.status ? advObj.priority : 0
      let result
      try {
        result = await AdvertisementUpdate(advObj)
      } catch (error) {
        console.log(error)
      } finally {
        this.getAdvItemsFilter(this.filterButtons[this.filterState].queryParams)
        this.toggleForm = false
      }
    }
  },
  mounted () {
    this.getAdvItemsFilter(this.filterButtons[0].queryParams)
    // console.log(this.$store.state)
  }
})
