<template>
  <v-container class="py-4">
    <GoBackButton></GoBackButton>
    <v-row class="justify-content-center">
      <v-col>
        <v-progress-linear
          indeterminate
          color="green"
          v-show="loading"
        ></v-progress-linear>
        <h1 class="text-center"> Остатки </h1>
      </v-col>
    </v-row>
    <v-row class="justify-content-center">
      <v-col>
        <v-data-table
          dense
          :headers="priceListBallanceHeaders"
          :items="priceListBallance"
          item-key="id"
          class="elevation-1"
        >
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import GoBackButton from '@/components/partials/components/GoBackButton.vue'
export default Vue.extend({
  components: {
    GoBackButton
  },
  data () {
    return {
      loading: false,
      priceListBallance: [
        { id: 1, foodcort: '1', availability: 20 }
      ]
    }
  },
  props: {
    id: {
      type: Number,
      require: true
    }
  },
  computed: {
    ...mapGetters({ user: 'user/user' }),
    priceListBallanceHeaders () {
      return [
        { text: 'Фудкорт', value: 'foodcort', sortable: false },
        { text: 'Наличие', value: 'availability', sortable: false }
      ]
    }
  }
})
</script>
