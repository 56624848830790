<template>
  <v-container class="py-4">
    <GoBackButton></GoBackButton>
    <v-row class="justify-content-center">
      <v-col>
        <v-progress-linear
          indeterminate
          color="green"
          v-show="loading"
        ></v-progress-linear>
        <h1 class="text-center"> Допы </h1>
      </v-col>
    </v-row>
    <v-row class="justify-content-center">
      <v-col>
        <v-data-table
          dense
          :headers="priceListDopHeaders"
          :items="priceListDop"
          item-key="id"
          class="elevation-1"
        >
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import GoBackButton from '@/components/partials/components/GoBackButton.vue'
export default Vue.extend({
  components: {
    GoBackButton
  },
  data () {
    return {
      loading: false,
      priceListDop: [
        { id: 1, name: 'Соус', weight: 20, price: 200, idikko: 12000 }
      ]
    }
  },
  props: {
    id: {
      type: Number,
      require: true
    }
  },
  computed: {
    ...mapGetters({ user: 'user/user' }),
    priceListDopHeaders () {
      return [
        { text: '', value: 'action', sortable: false },
        { text: 'Наименование', value: 'name', sortable: false },
        { text: 'Выход', value: 'weight', sortable: false },
        { text: 'Цена', value: 'price', sortable: false },
        { text: 'ID IKKO', value: 'idikko', sortable: false }
      ]
    }
  }
})
</script>
