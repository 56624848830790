var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('vue-progress-bar'),(_vm.routePartialType === 'full')?_c('div',[_c('div',{staticClass:"page-wrapper"},[((_vm.$route.name != 'UserOrder' &&
                      _vm.$route.name != 'UserOrderStateRedirect' &&
                      _vm.$route.name != 'UserOrderStateRedirectFail' &&
                      _vm.$route.name != 'EmailConfirmPage' &&
                      _vm.$route.name != 'UserFindOrder'))?[(_vm.user.person==null)?_c('div',[_c('navbar')],1):_c('div',[_c('navbar-user-type')],1)]:_vm._e(),_c('v-main',[_c('div',{staticClass:"main-content-wrapper",attrs:{"role":"main","aria-label":"Main"}},[_c('router-view')],1)]),((_vm.$route.name != 'UserOrder' &&
              _vm.$route.name != 'UserOrderStateRedirect' &&
              _vm.$route.name != 'UserOrderStateRedirectFail' &&
              _vm.$route.name != 'EmailConfirmPage' &&
              _vm.$route.name != 'UserFindOrder'))?[_c('footer-main')]:_vm._e()],2)]):_vm._e(),(_vm.routePartialType === 'account')?_c('div',[_c('div',{staticClass:"page-wrapper"},[_c('navbar-account'),_c('v-main',[_c('div',{staticClass:"main-content-wrapper main-content-wrapper--with-padding main-content-wrapper--grey-background",attrs:{"role":"main","aria-label":"Main"}},[_c('router-view')],1)]),_c('footer-main')],1)]):_vm._e(),(_vm.routePartialType === 'meta')?_c('div',{attrs:{"role":"main","aria-label":"Main"}},[_c('div',{staticClass:"main-content-wrapper"},[_c('router-view')],1)]):_vm._e(),_c('snackbar'),_c('back-to-top')],1)}
var staticRenderFns = []

export { render, staticRenderFns }