


















import Vue from 'vue'
import { mapGetters } from 'vuex'
import { OrderState } from '@/types/vue-enums'
export default Vue.extend({
  data () {
    return {
      reference: '',
      code: 1000,
      operation: 0
    }
  },
  components: {
  },
  computed: {
    ...mapGetters({
      user: 'user/user'
    })
  },
  methods: {
    getOrderState (item: number) {
      return OrderState[item].toString()
    }
  },
  mounted () {
    this.operation = parseInt(this.$route.query.operation.toString())
    this.reference = this.$route.query.reference.toString()
    if (this.operation === 0) {
      this.code = parseInt(this.$route.query.error.toString())
    } else {
      this.code = parseInt(this.$route.query.code.toString())
    }
  }
})
