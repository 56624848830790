<template>
  <v-container class="py-0">
    <LoadingBar :loading="loading"></LoadingBar>
    <v-row class="d-flex justify-content-center">
      <div style="width:100%">
        <CardsContainer v-if="settings !== []"
        :componentId="'new1_'"
        :itemsContent="'new'"
        :maxCardRows="5"
        :data="data"
        :itemAction="'applyItem'"
        :inputTimeColors="settings.timeColors"
        @changeFromChild="changeValue($event)"
        ></CardsContainer>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import LoadingBar from '@/components/partials/components/LoadingBar.vue'
import CardsContainer from '@/components/food/foodOrders/CardsContainer.vue'
import Swal from 'sweetalert2'
import { getFoodOrdersByPeriodAndStatusApi, updateFoodOrderStatusUrlApi } from '@/store/orders/api-requests'
import { mapSettings } from '@/store/food/settingsHelper'

export default Vue.extend({
  components: {
    LoadingBar,
    CardsContainer
  },
  data () {
    return {
      data: [],
      loading: false,
      dateFrom: new Date().getFullYear() + '-01-01',
      dateTo: new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().substr(0, 10),
      settings: [],
      refreshIntervalId: null
    }
  },
  methods: {
    async getData () {
      if (this.$route.name !== 'FoodOrderStateNew') {
        clearInterval(this.refreshIntervalId)
        return false
      }
      // console.log('get data new')
      this.loading = true
      let result
      try {
        result = await getFoodOrdersByPeriodAndStatusApi({ dateFrom: this.dateFrom, dateTo: this.dateTo, status: 1 })
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
        if (result) {
          this.data = []
          const orders = JSON.parse(JSON.stringify(result))
          orders.map(order => {
            if (order.orderProducts.length) {
              order.data = order.orderProducts.map(el => ({ name: el.product.name, quantity: (+el.quantity) }))
            }
            return order
          })
          orders.map(el => {
            el.data = el.data.filter(subEl => Number(subEl.quantity) !== 0)
            el.isSelected = false
            el.footer = el.creationDate
            return el
          })
          this.data = orders.filter(el => Number(el.sum) !== 0)
        }
      }
    },
    async applyOrder (id) {
      await Swal.fire({
        title: 'Начать сборку?',
        showDenyButton: true,
        confirmButtonText: 'Да',
        denyButtonText: 'Нет'
      }).then((result) => {
        if (result.isConfirmed) {
          // console.log('Сборка заказа начата')
          let result
          try {
            result = updateFoodOrderStatusUrlApi({ orders: [{ id: id, status: 2 }] })
          } catch (error) {
            console.log(error)
          } finally {
            this.removeItem(id)
            this.loading = false
          }
        } else if (result.isDenied) {
          // console.log('Отмена начала сборки заказа')
        }
      })
    },
    removeItem (id) {
      this.data = this.data.filter(el => el.id !== id)
    },
    changeValue (evnt) {
      if (evnt.action === 'applyItem') {
        this.applyOrder(evnt.val)
      }
    },
    ...mapActions('foodSettings', { getSettingsStore: 'show' }),
    async getSettings () {
      let result
      try {
        result = await this.getSettingsStore()
      } catch (error) {
        console.log(error)
      } finally {
        this.settings = mapSettings(result)
        this.getData()
        this.refreshIntervalId = setInterval(() => this.getData(), Number(this.settings.refreshTime))
      }
    }
  },
  mounted () {
    this.getSettings()
  },
  destroyed () {
    clearInterval(this.refreshIntervalId)
  },
  computed: {
    ...mapGetters({ user: 'user/user' })
  }
})
</script>
