import axios from '@/services/axios'
import createAuthRefreshInterceptor from 'axios-auth-refresh'
import { UserTokens } from '@/store/user/types'
import { AxiosResponse } from 'axios'
import store from '@/store'

const getToken = (type: 'accessToken' | 'refreshToken') =>
  localStorage.getItem(type)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const refreshAuthLogic = async (failedRequest: any) => {
  try {
    const tokens: AxiosResponse<{
        access_token: UserTokens['access_token'];
        refresh_token: UserTokens['refresh_token'];
    }> = await axios.post('user/refreshAccessToken', {
      username: store.getters['user/user'].username
        ? store.getters['user/user'].username
        : '',
      refreshToken: getToken('refreshToken')
    })

    localStorage.setItem('accessToken', tokens.data.access_token)
    localStorage.setItem('refreshToken', tokens.data.refresh_token)
    // functional/immutable-data
    // eslint-disable-next-line
    failedRequest.response.config.headers.Authorization = `Bearer ${getToken(
      'accessToken'
    )}`
    return Promise.resolve()
  } catch (error) {
    Promise.reject(new Error('Failed'))
  }
}

export const runAxiosAuthInterceptor = () => {
  createAuthRefreshInterceptor(axios, refreshAuthLogic)
}
