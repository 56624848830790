



























































import Vue from 'vue'
import GoBackButton from '@/components/partials/components/GoBackButton.vue'
import { getOrderProductsApi } from '@/store/orders/api-requests'

export default Vue.extend({
  components: {
    GoBackButton
  },
  data () {
    return {
      loading: false,
      idProp: 0,
      phone: null,
      orderConsist: []
    }
  },
  props: {
    id: [Number, String]
  },
  methods: {
    async show () {
      this.loading = true
      let result
      try {
        result = await getOrderProductsApi({ orderId: this.idProp })
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
        if (result) {
          console.log(result)
          this.orderConsist = JSON.parse(JSON.stringify(result))
          if (result.length) {
            this.phone = result[0].order
          }
        } else {
          this.orderConsist = []
        }
      }
    }
  },
  mounted () {
    this.idProp = Number(this.id)
    this.show()
  }
})
