import axios, { setAuthorizationHeader } from '@/services/axios'
import { AxiosResponse } from 'axios'

const getSectorsUrl = 'sectors'
const getSectorByIdUrl = 'sectors/rows/delivery/info'
const createDeliveryUrl = 'sectors/rows/delivery/create'
const deleteDeliveryUrl = 'sectors/rows/delivery/delete'

export const getsectors = async () => {
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(getSectorsUrl)
  return result.data.data
}

export const getsectorbyid = async (id: number) => {
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(getSectorByIdUrl, { sectorId: id })
  return result.data.data
}

export const createdelivery = async (createDeliveryObject: any) => {
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(createDeliveryUrl, createDeliveryObject)
  return result.data.data
}

export const deletedelivery = async (idsArr: any) => {
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(deleteDeliveryUrl, idsArr)
  return result.data.data
}
