import { render, staticRenderFns } from "./FoodOrdersArchive.vue?vue&type=template&id=b32a7790&scoped=true&"
import script from "./FoodOrdersArchive.vue?vue&type=script&lang=ts&"
export * from "./FoodOrdersArchive.vue?vue&type=script&lang=ts&"
import style0 from "./FoodOrdersArchive.vue?vue&type=style&index=0&lang=css&"
import style1 from "./FoodOrdersArchive.vue?vue&type=style&index=1&id=b32a7790&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b32a7790",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCol,VContainer,VDataTable,VIcon,VRow})
