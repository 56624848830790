/* eslint-disable @typescript-eslint/no-unused-vars */

import { ActionContext, ActionTree, GetterTree, MutationTree } from 'vuex'
import { ADD_FOODSETTINGS_TO_STACK } from './mutations'
import { RootState } from '@/store/state'
import { FoodSettingsState, FoodSetting } from './types'
import { getFoodSettingsApi as InformationShow, updateOrCreateFoodSettingsApi as InformationSave } from './api-requests'
import { parseAxiosError } from '@/services/api'

type InformationContext = ActionContext<FoodSettingsState, RootState>

const initialState: FoodSettingsState = {
  foodSettings: []
}

const getters: GetterTree<FoodSettingsState, RootState> = {
  foodSettings (state: FoodSettingsState, rootState: RootState): FoodSetting[] {
    return state.foodSettings
  }
}

const mutations: MutationTree<FoodSettingsState> = {
  [ADD_FOODSETTINGS_TO_STACK] (state: FoodSettingsState, informationResponse: FoodSetting[]) {
    state.foodSettings = informationResponse
  }
}

const actions: ActionTree<FoodSettingsState, RootState> = {
  async show ({ commit, dispatch, state }: InformationContext): Promise<FoodSetting[]> {
    try {
      const result = await InformationShow()
      commit(ADD_FOODSETTINGS_TO_STACK, result)
      return result
    } catch (error) {
      return Promise.reject(parseAxiosError(error))
    }
  },
  async save ({ commit, dispatch, state }: InformationContext, data: any): Promise<FoodSetting[]> {
    try {
      const result = await InformationSave(data)
      commit(ADD_FOODSETTINGS_TO_STACK, result)
      return result
    } catch (error) {
      return Promise.reject(parseAxiosError(error))
    }
  }
}

export const foodSettings = {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions
}
