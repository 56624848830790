<template>
  <v-container class="py-4">
    <LoadingBar :loading="loading"></LoadingBar>
    <v-row class="justify-content-center">
      <v-col>
        <GoBackButton></GoBackButton>
      </v-col>
      <v-col>
        <h1 class="text-center"> Заказ № {{id}}</h1>
      </v-col>
      <v-col>
        <ul>
          <li>{{ email }}</li>
          <li>{{ phone }}</li>
        </ul>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h2>Состав:</h2>
        <v-data-table
        hide-default-footer
          :headers="orderListHeaders"
          :items="items"
          item-key="id"
          class="elevation-1"
        >
          <template v-slot:[`item.quantity`]="{item}">
            {{ Number(item.quantity) }}
          </template>
          <template v-slot:[`body.append`]>
            <tr>
              <td></td>
              <td>Итого:</td>
              <td>{{ computedGroupSum }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
      <v-col>
        <h2>История</h2>
          <v-data-table
          hide-default-footer
            :headers="orderHistoryListHeaders"
            :items="historyItems"
            item-key="id"
            class="elevation-1"
          >
            <template v-slot:[`item.status`]="{item}">
              {{ item.status }}
            </template>
          </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import LoadingBar from '@/components/partials/components/LoadingBar.vue'
import GoBackButton from '@/components/partials/components/GoBackButton.vue'
import { getOrderHistoryApi, getOrderProductsApi } from '@/store/orders/api-requests'
export default Vue.extend({
  components: {
    GoBackButton,
    LoadingBar
  },
  data () {
    return {
      idProp: null,
      loading: false,
      email: null,
      phone: null,
      items: [],
      historyItems: []
    }
  },
  props: {
    id: [Number, String]
  },
  computed: {
    ...mapGetters({ user: 'user/user' }),
    orderListHeaders () {
      return [
        { text: 'Наименование', value: 'product', sortable: false },
        { text: 'Количество', value: 'quantity', sortable: false },
        { text: 'Цена', value: 'price', sortable: false }
      ]
    },
    orderHistoryListHeaders () {
      return [
        { text: 'Время', value: 'timeCreated', sortable: false },
        { text: 'Статус', value: 'status', sortable: false },
        { text: 'Исполнитель', value: 'user', sortable: false }
      ]
    },
    computedGroupSum () {
      const sum = this.items.reduce((sum, eachSum) => sum + Number(eachSum.price), 0)
      return sum
    }
  },
  methods: {
    async show () {
      this.loading = true
      let result1
      let result2
      try {
        result1 = await getOrderProductsApi({ orderId: this.idProp })
        result2 = await getOrderHistoryApi({ orderId: this.idProp })
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
        if (result1) {
          this.items = result1
        }
        if (result2) {
          const historyItems = JSON.parse(JSON.stringify(result2[0].orderHistory))
          this.historyItems = historyItems.map((el) => {
            if (el.status === 0) el.status = 'Новый'
            else if (el.status === 1) el.status = 'Оплачен'
            else if (el.status === 2) el.status = 'Собирается'
            else if (el.status === 4) el.status = 'Готов к доставке'
            else if (el.status === 5) el.status = 'Доставляется'
            else if (el.status === 6) el.status = 'Отменен'
            else if (el.status === 7) el.status = 'Доставлено'
            else if (el.status === 8) el.status = 'Не доставлено'
            else if (el.status === 10) el.status = 'Ошибка'
            else el.status = '-'
            el.user = '-'
            return el
          })
          this.email = historyItems[0].email ? historyItems[0].email : 'Не указан Email'
          this.phone = historyItems[0].phone ? historyItems[0].phone : 'Не указан телефон'
        }
      }
    }
  },
  mounted () {
    this.idProp = Number(this.id)
    this.show()
  }
})
</script>
