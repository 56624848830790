









import Vue from 'vue'
export default Vue.extend({
  data () {
    return {
      loading: false
    }
  },
  methods: {
    goBack () {
      this.$router.go(-1)
    }
  }
})
