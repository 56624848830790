import axios, { setAuthorizationHeader } from '@/services/axios'
import { AxiosResponse } from 'axios'
import { OrderFoodDeliverySetting } from '@/store/food/api-types'

const getOrderFoodDeliverySettingUrl = '/order/food/delivery'
const setOrderFoodDeliverySettingUrl = '/order/food/delivery/add'

export const getOrderFoodDeliverySettingApi = async () => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(getOrderFoodDeliverySettingUrl)
  return result.data.data
}

export const setOrderFoodDeliverySettingApi = async (params: OrderFoodDeliverySetting) => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(setOrderFoodDeliverySettingUrl, params)
  return result.data.data
}
