












































































































import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'

export default Vue.extend({
  name: 'navbar',
  data () {
    return {
      appTitle: '',
      sidebar: false,
      menuItems: [
        { title: 'Главная', path: '/', icon: 'home', rights: [17, 15, 4] },
        { title: 'Терминал', path: '/food', icon: 'mdi-food', rights: [17, 18, 20], hideTop: [18] },
        {
          title: 'Управление МП',
          icon: 'home',
          subitems: [
            { title: 'Мероприятия', path: '/affiches', icon: 'mdi-apps', rights: [17, 15, 20] },
            { title: 'Разделы', path: '/productsections', icon: 'mdi-animation', rights: [17, 15, 20] },
            { title: 'Теги', path: '/tags', icon: 'tag', rights: [17, 15] },
            { title: 'Реклама', path: '/advertisement', icon: 'mdi-image-filter-center-focus-weak', rights: [17, 15] },
            { title: 'Цены', path: '/pricelist', icon: 'mdi-currency-rub', rights: [17, 15, 20] }
          ]
        },
        {
          title: 'Отчёты',
          icon: 'home',
          subitems: [
            { title: 'Заказы', path: '/orders', icon: 'mdi-chart-areaspline', rights: [17, 15, 20] },
            { title: 'Уведомления (СМС, Push)', path: '/orderevents', icon: 'mdi-message-processing', rights: [17, 15] },
            { title: 'Рассылки e-mail', path: '/orderemails', icon: 'mdi-email-outline', rights: [17, 15] }
          ]
        },
        {
          title: 'Шкафчики',
          icon: 'home',
          subitems: [
            { title: 'Восстановление ЛК', path: '/user/reset', icon: 'mdi-key-variant', rights: [17, 4] },
            { title: 'Управление шкафчиками', path: '/locker/control', icon: 'mdi-cube-outline', rights: [17, 4] },
            { title: 'Поиск заказа', path: '/locker/findorder', icon: 'search', rights: [17, 4] },
            { title: 'Пароль администратора', path: '/locker/password', icon: 'mdi-numeric', rights: [17, 4] }
          ]
        },
        {
          title: 'Настройки',
          icon: 'home',
          subitems: [
            { title: 'Информация', path: '/information', icon: 'info', rights: [17, 15] },
            { title: 'Еда', path: '/foodsettings', icon: 'mdi-food', rights: [17, 15, 20] },
            { title: 'Доставка', path: '/delivery', icon: 'mdi-crosshairs-gps', rights: [17, 15, 20] },
            { title: 'Шаблоны дисконта', path: '/discount', icon: 'mdi-sale', rights: [17, 15] }
          ]
        }
      ]
    }
  },
  methods: {
    ...mapActions('user', { userLogout: 'logout' }),
    async logout () {
      // As you can see, with Vuex we we need to fire logout methods
      // for each of our modules.
      await this.userLogout()
      this.$router.push({ name: 'home' })
      // After logging the user out we can optionally reload the page, to make
      // sure everything is reset nicely.
      document.location.href = '/'
    },
    getNavigationClass (item: any) {
      let result = false
      item.subitems.forEach((element: any) => {
        if (this.$route.path === element.path) {
          result = true
        }
      })
      if (result) {
        return 'navtopbutton'
      } else {
        return ''
      }
    },
    getAccess (rights: any) {
      let result = false
      rights.forEach((element: any) => {
        if (Number(element) === Number(this.user.person.userType)) result = true
      })
      return result
    },
    getAccessList (subitems: any) {
      let result = false
      subitems.forEach((element: any) => {
        if (this.getAccess(element.rights)) result = true
      })
      return result
    },
    showDesktopNavigation () {
      const path = '/' + this.$route.path.split('/')[1]
      const routeItem = this.menuItems.find((el: any) => el.path === path)
      if (routeItem && routeItem.hideTop && routeItem.hideTop.some((uid: any) => Number(uid) === Number(this.user.person.userType))) {
        return false
      }
      return true
    }
  },
  computed: {
    ...mapGetters({ user: 'user/user' }),
    routePartialType (): string {
      return (this.$route.meta as any).partialType
    }
  },
  components: {
  }
})
