import axios, { AxiosInstance } from 'axios'

const defaultOptions = {
  baseURL: `${process.env.VUE_APP_API_URL}/api`
}

const instance = axios.create(defaultOptions)

export default instance

export const setAuthorizationHeader = (a: AxiosInstance) => {
  // functional/immutable-data
  // eslint-disable-next-line
  a.defaults.headers.common.Authorization = `Bearer ${
    localStorage.getItem('accessToken') || ''
  }`
}

export const setCustomErrorHandling = (a: AxiosInstance) => {
  a.interceptors.response.use((response) => {
    if (response.data.errorCode > 0) {
      return Promise.reject(response.data.errorMessage)
    }
    return response
  })
}
