































import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
export default Vue.extend({
  components: {
  },
  computed: {
    ...mapGetters({ user: 'user/user' })
  },
  methods: {
    ...mapActions('user', { userLogout: 'logout' }),
    async logout () {
      await this.userLogout()
      this.$router.push({ name: 'home' })
      document.location.href = '/'
    },
    showFoodInFooter () {
      if (this.user.person) {
        return Number(this.user.person.userType) === 18
      }
      return false
    }
  }
})
