










import Vue from 'vue'
import ResetForm from '@/components/user/forms/ResetForm.vue'
export default Vue.extend({
  components: {
    ResetForm
  }
})
