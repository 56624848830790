




















import Vue from 'vue'
import { mapGetters } from 'vuex'
export default Vue.extend({
  components: {
  },
  computed: {
    ...mapGetters({
      user: 'user/user'
    })
  },
  mounted () {
    const usr = JSON.parse(JSON.stringify(this.$store.state.user))
    if (usr) {
      if (Number(usr.user.sub) === 393) {
        this.$router.push({ name: 'FoodOrderStateNew' })
      }
    }
  }
})
