




























































































































































































import Vue from 'vue'
import { mapGetters } from 'vuex'
import { sessionsget as sessionsGet, createorder as createOrder, sessionsTime, verifyorder as verifyOrder } from '@/store/locker/api-requests'
import { SessionsGetPost, CreateOrderPost } from '@/store/locker/api-types'
import { Session, SessionTime } from '@/store/locker/types'
import Swal from 'sweetalert2'
type BaseData = {
  loading: boolean;
  sessions: Session[];
  sessionsTime: SessionTime;
  date: string;
  menu: boolean;
  selected: number[];
  rules: any[];
  rulesEmail: any[];
  quantity: number;
  emailString: string;
  modal2: boolean;
  time: any;
  minTime: string;
  maxTime: string;
  hours: number;
  allowedHours: number[];
  allowedMinutes: number[];
  order: CreateOrderPost;
  sessionExist: boolean;
}
export default Vue.extend({
  data (): BaseData {
    return {
      loading: false,
      sessionExist: false,
      emailString: '',
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      sessions: [],
      selected: [],
      quantity: 1,
      hours: 1,
      modal2: false,
      allowedHours: [18, 19, 20],
      allowedMinutes: [],
      time: null,
      minTime: '',
      maxTime: '',
      order: {
        timestamp: '',
        countHour: 0,
        quantity: 0,
        email: ''
      },
      sessionsTime: {
        startTime: '',
        endTime: ''
      },
      rules: [
        (value: any) => !!value || 'Не должно быть пустым.',
        (value: any) => (value && value.length < 3) || 'Максимум 2 числа',
        (value: any) => (!isNaN(value)) || 'Должно быть число'
      ],
      rulesEmail: [
        (value: any) => !!value || 'Не должно быть пустым.',
        (value: any) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Не корректный e-mail.'
        }
      ]
    }
  },
  components: {
  },
  methods: {
    async sessionsGet (dateSelect: string) {
      const sessionsGetPost: SessionsGetPost = { date: dateSelect }
      this.loading = true
      try {
        this.sessions = await sessionsGet(sessionsGetPost)
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    async sessionsTimeGet (dateSelect: string) {
      const sessionsGetPost: SessionsGetPost = { date: dateSelect }
      this.loading = true
      try {
        this.sessionsTime = await sessionsTime(sessionsGetPost)
        if (this.sessionsTime.endTime !== null) {
          this.sessionExist = true
          const dateNow = new Date()
          const dateStart = new Date(this.sessionsTime.startTime)
          if (dateNow > dateStart) {
            if (dateNow.getMinutes() < 40) {
              this.time = (dateNow.getHours()).toString() + ':' + (dateNow.getMinutes() + 20 - (dateNow.getMinutes() % 5)).toString()
            } else {
              this.time = (dateNow.getHours() + 1).toString() + ':'
              const tempTime = 20 - ((60 - dateNow.getMinutes()) + (dateNow.getMinutes() % 5))
              if (tempTime < 10) this.time += '0'
              this.time += (20 - ((60 - dateNow.getMinutes()) + (dateNow.getMinutes() % 5))).toString()
            }
            this.minTime = this.sessionsTime.startTime.substr(11, 5)
            this.maxTime = this.sessionsTime.endTime.substr(11, 5)
          } else {
            if (dateStart.getMinutes() < 40) {
              this.time = (dateStart.getHours()).toString() + ':' + (dateStart.getMinutes() + 20 - (dateStart.getMinutes() % 5)).toString()
            } else {
              this.time = (dateStart.getHours() + 1).toString() + ':'
              this.time += (20 - ((60 - dateStart.getMinutes()) - (dateStart.getMinutes() % 5) - 1)).toString()
            }
            this.maxTime = this.sessionsTime.endTime.substr(11, 5)
            this.minTime = this.sessionsTime.startTime.substr(11, 5)
          }
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    changeDate () {
      this.menu = false
      this.sessionsGet(this.date)
    },
    async createOrder () {
      this.loading = true
      try {
        const result = await createOrder(this.order)
        window.location.href = result
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    async verifyOrder () {
      const createOrderPost: CreateOrderPost = {
        timestamp: this.date + ' ' + this.time + ':00',
        countHour: this.hours,
        email: this.emailString,
        quantity: this.quantity
      }
      this.loading = true
      try {
        const result = await verifyOrder(createOrderPost)
        this.order.timestamp = result.timestampFrom
        this.order.countHour = result.countHour
        this.order.quantity = result.quantity
        this.order.email = this.emailString
        if (result.cutter) {
          const textError = '<strong>Ваш e-mail:</strong> ' + this.order.email + '<br><strong>Время начала бронирования:</strong> ' + this.order.timestamp + '<br><strong>Время окончания бронирования:</strong> ' + result.timestampTo +
          '<br><strong>Количество часов:</strong> ' + this.order.countHour + '<br><strong>Количество шкафчиков:</strong> ' + this.order.quantity + '<br><strong>Сумма:</strong> ' + (result.sum / 100).toString()
          Swal.fire({
            title: 'Заказ изменён. Время заказа превышает время мероприятий.',
            html: textError,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Подтверждаю!',
            cancelButtonText: 'Отказываюсь'
          }).then((result) => {
            if (result.isConfirmed) {
              this.createOrder()
            } else {
              this.order = {
                timestamp: '',
                countHour: 0,
                quantity: 0,
                email: ''
              }
              this.sessionsTimeGet(this.date)
              this.time = '00:00'
            }
          })
        } else {
          const textError = '<strong>Ваш e-mail:</strong> ' + this.order.email + '<br><strong>Время начала бронирования:</strong> ' + this.order.timestamp + '<br><strong>Время окончания бронирования:</strong> ' + result.timestampTo +
          '<br><strong>Количество часов:</strong> ' + this.order.countHour + '<br><strong>Количество шкафчиков:</strong> ' + this.order.quantity + '<br><strong>Сумма:</strong> ' + (result.sum / 100).toString()
          Swal.fire({
            title: 'Проверьте заказ перед оплатой',
            html: textError,
            icon: 'success',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Подтверждаю!',
            cancelButtonText: 'Отказываюсь'
          }).then((result) => {
            if (result.isConfirmed) {
              this.createOrder()
            } else {
              this.order = {
                timestamp: '',
                countHour: 0,
                quantity: 0,
                email: ''
              }
              this.quantity = 1
              this.hours = 1
              this.sessionsTimeGet(this.date)
              this.time = '00:00'
            }
          })
        }
      } catch (error) {
        this.order = {
          timestamp: '',
          countHour: 0,
          quantity: 0,
          email: ''
        }
        this.sessionsTimeGet(this.date)
        this.time = '00:00'
        this.quantity = 1
        this.hours = 1
        this.sessionsTimeGet(this.date)
        this.time = '00:00'
        Swal.fire({
          icon: 'error',
          title: 'Ошибка оформления заказа',
          text: error.response.data.errorMessage
        })
      } finally {
        this.loading = false
      }
    },
    orderSearch () {
      this.$router.push({ name: 'UserFindOrder' })
    },
    selectItem (event: any) {
      let complex = false
      let complexId = null
      event.forEach((element: any) => {
        this.sessions.forEach(session => {
          if (session.id === element && session.complex === 1) {
            complex = true
            complexId = element
          }
        })
      })
      if (complex) {
        this.selected = []
        if (complexId) {
          this.selected.push(complexId)
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/user'
    })
  },
  mounted () {
    for (let index = 0; index < 60; index += 5) {
      this.allowedMinutes.push(index)
    }
    this.menu = false
    this.sessionsTimeGet(this.date)
    this.time = '00:00'
  }
})
