export enum DaysWeek {
  'Понедельник' = 1,
  'Вторник' = 2,
  'Среда' = 3,
  'Четверг' = 4,
  'Пятница' = 5,
  'Суббота' = 6,
  'Воскресенье' = 7
}

export enum LockerState {
  'Заказ' = 1,
  'Открытие' = 2,
  'Закрытие' = 3,
  'Освобождение' = 4,
  'смена e-mail' = 5
}

export enum OrderState {
  'Операция отклонена по другим причинам. Требуется уточнение у ПЦ. ' = 0,
  'Удачное выполнение операции' = 1,
  'Неверный срок действия Банковской карты' = 2,
  'Неверный статус Банковской карты на стороне Эмитента' = 3,
  'Операция отклонена Эмитентом' = 4,
  'Операция недопустима для Эмитента' = 5,
  'Недостаточно средств на счёте Банковской карты' = 6,
  'Превышен установленный для ТСП лимит на сумму операций (дневной, недельный, месячный) или сумма операции выходит за пределы установленных границ' = 7,
  'Операция отклонена по причине срабатывания системы предотвращения мошенничества' = 8,
  'Заказ уже находится в процессе оплаты. Операция, возможно, задублировалась' = 9,
  'Системная ошибка' = 10,
  'Ошибка 3DS аутентификации' = 11,
  'Указано неверное значение секретного кода карты ' = 12,
  'Операция отклонена по причине недоступности Эмитента и/или Банка-эквайрера.' = 13,
  'Операция отклонена оператором электронных денег.' = 14,
  'BIN платёжной карты присутствует в черных списках' = 15,
  'BIN 2 платёжной карты присутствует в черных списках' = 16,
  'Неизвестная ошибка 17' = 17,
  'Неизвестная ошибка 18' = 18,
  'Неизвестная ошибка 19' = 19,
  'Неизвестная ошибка 20' = 20,
}

export enum Errors {
  privilegeGranted1 = 1,
  privilegeGranted2 = 2,
  rationMarkError1 = 3,
  rationMarkError2 = 4,
  recalculationRequired = 5,
  notTakenIntoAccount = 6,
  cancellationRequired = 7,
  accountingIsPaidByCard = 8,
  notAPupil1 = 9,
  notAPupil2 = 10,
  isNotABeneficiary1 = 11,
  isNotABeneficiary2 = 12,
  noRegid1 = 13,
  noRegid2 = 14,
  noSNILS1 = 15,
  noSNILS2 = 16,
  restored = 17,
  ignore = 18,
  waitingForReturn = 19,
  waitingForCancellation = 20
}

export enum ErrorGroups {
  'privilegeGranted1' = 1,
  'privilegeGranted2' = 2,
  'rationMarkError1' = 1,
  'rationMarkError2' = 2,
  'recalculationRequired' = 4,
  'notTakenIntoAccount' = 4,
  'cancellationRequired' = 4,
  'accountingIsPaidByCard' = 4,
  'notAPupil1' = 1,
  'notAPupil2' = 2,
  'isNotABeneficiary1' = 1,
  'isNotABeneficiary2' = 2,
  'noRegid1' = 1,
  'noRegid2' = 2,
  'noSNILS1' = 1,
  'noSNILS2' = 2,
  'restored' = 4,
  'ignore' = 3,
  'waitingForReturn' = 4,
  'waitingForCancellation' = 4
}

export enum ErrorMessages {
  'Льгота предоставлена ранее (нт)' = 1,
  'Льгота предоставлена ранее' = 2,
  'Ошибка отметки рациона (нт)' = 3,
  'Ошибка отметки рациона' = 4,
  'Требуется перерасчет' = 5,
  'Не учтено' = 6,
  'Требуется отмена' = 7,
  'Учет платно по карте' = 8,
  'Не является учеником (нт)' = 9,
  'Не является учеником' = 10,
  'Не является льготником (нт)' = 11,
  'Не является льготником' = 12,
  'Нет регистрационного идентификатора (нт)' = 13,
  'Нет регистрационного идентификатора' = 14,
  'Отсутствует СНИЛС (нт)' = 15,
  'Отсутствует СНИЛС' = 16,
  'Восстановлено' = 17,
  'Не учитывать' = 18,
  'Ожидание возврата' = 19,
  'Ожидание отмены' = 20
}

export enum ErrorMessages2 {
  privilegeGranted1 = 'Льгота предоставлена ранее',
  privilegeGranted2 = 'Льгота предоставлена ранее',
  rationMarkError1 = 'Ошибка отметки рациона',
  rationMarkError2 = 'Ошибка отметки рациона',
  recalculationRequired = 'Требуется перерасчет',
  notTakenIntoAccount = 'Не учтено',
  cancellationRequired = 'Требуется отмена',
  accountingIsPaidByCard = 'Учет платно по карте',
  notAPupil1 = 'Не является учеником',
  notAPupil2 = 'Не является учеником',
  isNotABeneficiary1 = 'Не является льготником',
  isNotABeneficiary2 = 'Не является льготником',
  noRegid1 = 'Нет регистрационного идентификатора',
  noRegid2 = 'Нет регистрационного идентификатора',
  noSNILS1 = 'Отсутствует СНИЛС',
  noSNILS2 = 'Отсутствует СНИЛС',
  restored = 'Восстановлено',
  ignore = 'Не учитывать',
  waitingForReturn = 'Ожидание возврата',
  waitingForCancellation = 'Ожидание отмены'
}
