import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import jwtDecode from 'jwt-decode'
import store from '@/store'
import Home from '@/components/layouts/pages/Home.vue'
// User Routes
import Login from '@/components/user/components/Login.vue'
import Reset from '@/components/user/components/Reset.vue'
// Other
import NotFound from '@/components/layouts/main/NotFound.vue'
import { JwtDecodeData } from '@/store/user/types'
// Lockers
import FindOrder from '@/components/locker/pages/FindOrder.vue'
import LockerControl from '@/components/locker/pages/LockerControl.vue'
import LockerHistory from '@/components/locker/pages/LockerHistory.vue'
import UserOrder from '@/components/locker/pages/UserOrder.vue'
import UserOrderStateRedirect from '@/components/locker/pages/UserOrderStateRedirect.vue'
import UserOrderStateRedirectFail from '@/components/locker/pages/UserOrderStateRedirectFail.vue'
import UserFindOrder from '@/components/locker/pages/UserFindOrder.vue'
import LockerPassword from '@/components/locker/pages/LockerPassword.vue'
// Affiches
import AffichesCatalog from '@/components/affiches/AffichesCatalog.vue'
import Affiche from '@/components/affiches/Affiche.vue'
// Information
import Information from '@/components/Information/Information.vue'
import Tags from '@/components/tags/Tags.vue'
// Advertisement
import Advertisement from '@/components/advertisement/AdvertisementList.vue'
//
// EmailConfirmPage
import EmailConfirmPage from '@/components/redirect/EmailConfirmPage.vue'
// Orders
import Orders from '@/components/orders/Orders.vue'
import OrderConsist from '@/components/orders/OrderConsist.vue'
import OrderHistory from '@/components/orders/OrderHistory.vue'
import OrderEvents from '@/components/orders/OrderEvents.vue'
import OrderEmails from '@/components/orders/OrderEmails.vue'
// Delivery
import Delivery from '@/components/settings/delivery/Delivery.vue'
import DeliveryMessage from '@/components/settings/delivery/DeliveryMessage.vue'
// PriceList
import PriceList from '@/components/pricelist/PriceList.vue'
import PriceHistory from '@/components/pricelist/PriceHistory.vue'
import PriceEdit from '@/components/pricelist/PriceEdit.vue'
import PriceBallance from '@/components/pricelist/PriceBallance.vue'
import PriceDop from '@/components/pricelist/PriceDop.vue'
import PriceFoto from '@/components/pricelist/PriceFoto.vue'
import SlideHistory from '@/components/pricelist/SlideHistory.vue'
import SlideAddPrice from '@/components/pricelist/SlideAddPrice.vue'
// Sections
import ProductSections from '@/components/productSections/ProductSections.vue'
// Settings
import Discount from '@/components/settings/Discount.vue'
import FoodSettings from '@/components/settings/FoodSettings.vue'
// Food
import FoodEnter from '@/components/food/FoodEnter.vue'
import FoodOrdersArchive from '@/components/food/foodOrdersArchive/FoodOrdersArchive.vue'
import FoodOrder from '@/components/food/FoodOrder.vue'
import FoodOrders from '@/components/food/foodOrders/FoodOrders.vue'
import OrderStateNew from '@/components/food/foodOrders/OrderStateNew.vue'
import OrderStatePrepairing from '@/components/food/foodOrders/OrderStatePrepairing.vue'
import OrderStateReadyForDelivery from '@/components/food/foodOrders/OrderStateReadyForDelivery.vue'
import OrderStateDelivery from '@/components/food/foodOrders/OrderStateDelivery.vue'
// Shedule
import Shedule from '@/components/shedule/Shedule.vue'
import SheduleDiapasonAdd from '@/components/shedule/SheduleDiapasonAdd.vue'
import SheduleDate from '@/components/shedule/SheduleDate.vue'
import SheduleDateDiapasonAdd from '@/components/shedule/SheduleDateDiapasonAdd.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '*',
    name: 'NotFound',
    component: NotFound,
    meta: { title: 'Страница не найдена', partialType: 'meta' }
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { title: 'Главная', partialType: 'full' }
  },
  {
    path: '/user/login',
    name: 'Login',
    component: Login,
    meta: { title: 'Вход', partialType: 'full' }
  },
  {
    path: '/user/reset',
    name: 'Reset',
    component: Reset,
    meta: { title: 'Восстановление ЛК', partialType: 'full' }
  },
  {
    path: '/locker/control',
    name: 'LockerControl',
    component: LockerControl,
    meta: { title: 'Управление шкафчиками', requiresAuth: true, partialType: 'full' }
  },
  {
    path: '/locker/history/:id',
    name: 'LockerHistory',
    component: LockerHistory,
    meta: { title: 'История шкафчика', requiresAuth: true, partialType: 'full' },
    props: true
  },
  {
    path: '/locker/findorder',
    name: 'FindOrder',
    component: FindOrder,
    meta: { title: 'Поиск по номеру заказа', requiresAuth: true, partialType: 'full' }
  },
  {
    path: '/locker/userorder',
    name: 'UserOrder',
    component: UserOrder,
    meta: { title: 'Заказ пользователя', partialType: 'full' }
  },
  {
    path: '/locker/userorder/find',
    name: 'UserFindOrder',
    component: UserFindOrder,
    meta: { title: 'Заказ пользователя', partialType: 'full' }
  },
  {
    path: '/locker/userorderstateredirect',
    name: 'UserOrderStateRedirect',
    component: UserOrderStateRedirect,
    meta: { title: 'Ответ на заказ', partialType: 'full' }
  },
  {
    path: '/locker/userorderstateredirectfail',
    name: 'UserOrderStateRedirectFail',
    component: UserOrderStateRedirectFail,
    meta: { title: 'Ответ на заказ', partialType: 'full' }
  },
  {
    path: '/locker/password',
    name: 'LockerPassword',
    component: LockerPassword,
    meta: { title: 'Пароль администратора', requiresAuth: true, partialType: 'full' }
  },
  {
    path: '/affiches',
    name: 'AffichesCatalog',
    component: AffichesCatalog,
    meta: { title: 'Рубрики', requiresAuth: true, partialType: 'full' },
    props: true
  },
  {
    path: '/affiche',
    name: 'Affiche',
    component: Affiche,
    meta: { title: 'Рубрики', requiresAuth: true, partialType: 'full' },
    props: true
  },
  {
    path: '/information',
    name: 'Information',
    component: Information,
    meta: { title: 'Информация', requiresAuth: true, partialType: 'full' }
  },
  {
    path: '/email/confirm/:message',
    name: 'EmailConfirmPage',
    component: EmailConfirmPage,
    meta: { title: 'Подтверждение e-mail', requiresAuth: false, partialType: 'full' },
    props: true
  },
  {
    path: '/tags',
    name: 'Tags',
    component: Tags,
    meta: { title: 'Теги', requiresAuth: true, partialType: 'full' }
  },
  {
    path: '/advertisement',
    name: 'Advertisement',
    component: Advertisement,
    meta: { title: 'Реклама', requiresAuth: true, partialType: 'full' }
  },
  {
    path: '/orders',
    name: 'Orders',
    component: Orders,
    meta: { title: 'Заказы', requiresAuth: true, partialType: 'full' }
  },
  {
    path: '/orders/consist/:id',
    name: 'OrderConsist',
    component: OrderConsist,
    meta: { title: 'Заказы состав', requiresAuth: true, partialType: 'full' },
    props: true
  },
  {
    path: '/orders/history/:id',
    name: 'OrderHistory',
    component: OrderHistory,
    meta: { title: 'Заказы история', requiresAuth: true, partialType: 'full' },
    props: true
  },
  {
    path: '/orderevents',
    name: 'OrderEvents',
    component: OrderEvents,
    meta: { title: 'Отчет по сообщениям', requiresAuth: true, partialType: 'full' }
  },
  {
    path: '/orderemails',
    name: 'OrderEmails',
    component: OrderEmails,
    meta: { title: 'Email рассылки', requiresAuth: true, partialType: 'full' }
  },
  {
    path: '/delivery',
    name: 'Delivery',
    component: Delivery,
    meta: { title: 'Доставка', requiresAuth: true, partialType: 'full' }
  },
  {
    path: '/deliverymessage',
    name: 'DeliveryMessage',
    component: DeliveryMessage,
    meta: { title: 'Доставка', requiresAuth: true, partialType: 'full' }
  },
  {
    path: '/pricelist',
    name: 'PriceList',
    component: PriceList,
    meta: { title: 'Цены', requiresAuth: true, partialType: 'full' },
    props: true
  },
  {
    path: '/pricelist/editprice/:id',
    name: 'PriceEdit',
    component: PriceEdit,
    meta: { title: 'Изменение цены', requiresAuth: true, partialType: 'full' },
    props: true
  },
  {
    path: '/pricelist/history/:id',
    name: 'PriceHistory',
    component: PriceHistory,
    meta: { title: 'История цен', requiresAuth: true, partialType: 'full' },
    props: true
  },
  {
    path: '/pricelist/ballance/:id',
    name: 'PriceBallance',
    component: PriceBallance,
    meta: { title: 'Остатки', requiresAuth: true, partialType: 'full' },
    props: true
  },
  {
    path: '/pricelist/dop/:id',
    name: 'PriceDop',
    component: PriceDop,
    meta: { title: 'Допы', requiresAuth: true, partialType: 'full' },
    props: true
  },
  {
    path: '/pricelist/foto/:id',
    name: 'PriceFoto',
    component: PriceFoto,
    meta: { title: 'Фото', requiresAuth: true, partialType: 'full' },
    props: true
  },
  {
    path: '/pricelist/slidehistory/:id',
    name: 'SlideHistory',
    component: SlideHistory,
    meta: { title: 'История катка', requiresAuth: true, partialType: 'full' },
    props: true
  },
  {
    path: '/pricelist/slideaddprice/:id?',
    name: 'SlideAddPrice',
    component: SlideAddPrice,
    meta: { title: 'Добавление цены', requiresAuth: true, partialType: 'full' },
    props: true
  },
  {
    path: '/productsections',
    name: 'ProductSections',
    component: ProductSections,
    meta: { title: 'Разделы', requiresAuth: true, partialType: 'full' }
  },
  {
    path: '/discount',
    name: 'Discount',
    component: Discount,
    meta: { title: 'Шаблоны дисконта', requiresAuth: true, partialType: 'full' }
  },
  {
    path: '/foodsettings',
    name: 'FoodSettings',
    component: FoodSettings,
    meta: { title: 'Настройки раздела "Еда"', requiresAuth: true, partialType: 'full' }
  },
  {
    path: '/food',
    name: 'FoodEnter',
    component: FoodEnter,
    meta: { title: 'Еда: выбор раздела', requiresAuth: true, partialType: 'full' }
  },
  {
    path: '/food/orders',
    name: 'FoodOrders',
    component: FoodOrders,
    meta: { title: 'Заказы еды', requiresAuth: true, partialType: 'full' },
    props: true,
    children: [
      {
        path: 'new',
        name: 'FoodOrderStateNew',
        component: OrderStateNew,
        meta: { title: 'Заказы еды - новые', requiresAuth: true, partialType: 'full' },
        props: true
      },
      {
        path: 'prepairing',
        name: 'FoodOrderStatePrepairing',
        component: OrderStatePrepairing,
        meta: { title: 'Заказы еды - Собираются', requiresAuth: true, partialType: 'full' },
        props: true
      },
      {
        path: 'readyfordelivery',
        name: 'FoodOrderStateReadyForDelivery',
        component: OrderStateReadyForDelivery,
        meta: { title: 'Заказы еды - Готовые к доставке', requiresAuth: true, partialType: 'full' },
        props: true
      },
      {
        path: 'delivery',
        name: 'FoodOrderStateDelivery',
        component: OrderStateDelivery,
        meta: { title: 'Заказы еды - Доставка', requiresAuth: true, partialType: 'full' },
        props: true
      }
    ]
  },
  {
    path: '/food/orders/archive',
    name: 'FoodOrdersArchive',
    component: FoodOrdersArchive,
    meta: { title: 'Архив заказов еды', requiresAuth: true, partialType: 'full' }
  },
  {
    path: '/food/order/:id',
    name: 'FoodOrder',
    component: FoodOrder,
    meta: { title: 'Заказ', requiresAuth: true, partialType: 'full' },
    props: true
  },
  {
    path: '/shedule/:id',
    name: 'Shedule',
    component: Shedule,
    meta: { title: 'Настройка расписания', requiresAuth: true, partialType: 'full' },
    props: true
  },
  {
    path: '/shedule/item',
    name: 'SheduleDiapasonAdd',
    component: SheduleDiapasonAdd,
    meta: { title: 'Настроить диапазоны', requiresAuth: true, partialType: 'full' },
    props: true
  },
  {
    path: '/shedule/date',
    name: 'SheduleDate',
    component: SheduleDate,
    meta: { title: 'Настройка по дате', requiresAuth: true, partialType: 'full' },
    props: true
  },
  {
    path: '/shedule/date/item',
    name: 'SheduleDateDiapasonAdd',
    component: SheduleDateDiapasonAdd,
    meta: { title: 'Добавление \\ изменения даты', requiresAuth: true, partialType: 'full' },
    props: true
  }
]

const router = new VueRouter({
  routes,
  mode: 'history',
  scrollBehavior (to, _, savedPosition) {
    if (to.hash) {
      return { selector: to.hash }
    } else if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  // Start our vue-progressbar
  router.app.$Progress.start()
  // To set the title of each route
  document.title = (to as any).meta.title
  let accessToken = localStorage.getItem('accessToken')
  let refreshToken = localStorage.getItem('refreshToken')
  let toUrl = '/'

  if (to.name === 'LoginByToken') {
    if (to.query.accessToken !== undefined && to.query.refreshToken !== undefined) {
      accessToken = to.query.accessToken.toString()
      refreshToken = to.query.refreshToken.toString()
      localStorage.setItem('accessToken', accessToken)
      localStorage.setItem('refreshToken', refreshToken)
      if (to.query.redirectTo !== undefined) {
        toUrl = to.query.redirectTo.toString()
      }
    }
  }

  if (accessToken != null && refreshToken != null) {
    const decoded: JwtDecodeData = jwtDecode(accessToken)
    store.commit('user/SET_USER', decoded)
    if (to.name === 'Login') {
      next({ path: '/' })
      router.app.$Progress.finish()
    }
    if (to.name === 'LoginByToken') {
      store.dispatch('user/getPerson').then(() => {
        next({ name: toUrl })
      })
    }
    if (to.name === 'Home') {
      store.dispatch('user/getPerson')
    }
  }

  // If the user's not logged in do not allow into protected pages.
  if (to.name !== toUrl && to.name !== 'LoginByToken' && (to as any).meta.requiresAuth && !store.getters['user/user'].person) {
    if (accessToken == null && refreshToken == null) {
      next({ name: 'Home' })
    } else {
      store.dispatch('user/getPerson').then(() => {
        next({ path: to.path })
      })
    }
  }

  next()
})

router.afterEach(() => {
  // End our vue-progressbar
  router.app.$Progress.finish()
})

export default router
