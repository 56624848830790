// https://stackoverflow.com/a/11957822/8014660

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toISOString#Polyfill
if (!Date.prototype.toISOString) {
  (function () {
    function pad (number: number) {
      if (number < 10) {
        return '0' + number
      }
      return number
    }

    // eslint-disable-next-line
    Date.prototype.toISOString = function () {
      return (
        this.getUTCFullYear() +
        '-' +
        pad(this.getUTCMonth() + 1) +
        '-' +
        pad(this.getUTCDate()) +
        'T' +
        pad(this.getUTCHours()) +
        ':' +
        pad(this.getUTCMinutes()) +
        ':' +
        pad(this.getUTCSeconds()) +
        '.' +
        (this.getUTCMilliseconds() / 1000).toFixed(3).slice(2, 5) +
        'Z'
      )
    }
  })()
}
