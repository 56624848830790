







































import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
export default Vue.extend({
  components: {
    //
  },
  data () {
    return {
      filter: {
        datetimeFrom: new Date().toISOString().substr(0, 10),
        datetimeTo: new Date('2021/06/25').toISOString().substr(0, 10)
      },
      menuDateFrom: false,
      menuDateTo: false
    }
  },
  methods: {
    show () {
      console.log('скачать файл')
    }
  },
  mounted () {
    //
  },
  computed: {
    ...mapGetters({ user: 'user/user' })
  }
})
