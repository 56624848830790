import axios, { setAuthorizationHeader } from '@/services/axios'
import { AxiosResponse } from 'axios'

export const savefileNew = async (filePost: any): Promise<any> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post('files/create', filePost)
  return result.data.data
}
