









































import Vue from 'vue'
import { mapGetters } from 'vuex'

export default Vue.extend({
  name: 'navbar',
  data () {
    return {
      appTitle: '',
      sidebar: false,
      menuItems: [
        { title: 'Вход', path: '/user/login', icon: 'face' }
      ]
    }
  },
  methods: {
  },
  computed: {
    ...mapGetters({
      user: 'user/user'
    }),
    routePartialType (): string {
      return (this.$route as any).meta.partialType
    }
  },
  components: {
  }
})
