import axios, { setAuthorizationHeader } from '@/services/axios'
import { AxiosResponse } from 'axios'
import { getPeriodByOrderRequestType, getOrderHitoryRequestType, getOrderProductsRequestType, Orders } from '@/store/orders/api-types'
import { OrderHistoryType } from '@/store/orders/types'

const getPeriodByOrderTypeUrl = 'order/period'
const getOrderHitoryUrl = 'order/history'
const getOrderProductsUrl = 'order/products'

// FOOD
const getFoodOrdersByPeriodAndStatusUrl = 'order/food/get'
const updateFoodOrderStatusUrl = 'order/food/updatestatus'

export const getPeriodByOrderTypeApi = async (params: getPeriodByOrderRequestType) => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: Orders[];
  }> = await axios.post(getPeriodByOrderTypeUrl, params)
  return result.data.data
}

export const getOrderHistoryApi = async (params: getOrderHitoryRequestType) => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: OrderHistoryType[];
  }> = await axios.post(getOrderHitoryUrl, params)
  return result.data.data
}

export const getOrderProductsApi = async (params: getOrderProductsRequestType) => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: any[];
  }> = await axios.post(getOrderProductsUrl, params)
  return result.data.data
}

// FOOD ORDERS

export const getFoodOrdersByPeriodAndStatusApi = async (params: any) => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: Orders[];
  }> = await axios.post(getFoodOrdersByPeriodAndStatusUrl, params)
  return result.data.data
}

export const updateFoodOrderStatusUrlApi = async (params: any) => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: any[];
  }> = await axios.post(updateFoodOrderStatusUrl, params)
  return result.data.data
}
