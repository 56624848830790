












































import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import VueSimplemde from 'vue-simplemde'
import { InformationPost } from '@/store/information/api-types'
import { update as InformationsUpdate } from '@/store/information/api-requests'
import Swal from 'sweetalert2'

type BaseData = {
  loading: boolean;
  info: any;
  tab: any;
  informationPost: InformationPost;
}

export default Vue.extend({
  name: 'information',
  components: {
    VueSimplemde
  },
  data (): BaseData {
    return {
      loading: false,
      info: [],
      tab: null,
      informationPost: {
        informations: []
      }
    }
  },
  computed: {
    ...mapGetters({ user: 'user/user' }),
    ...mapGetters({ informations: 'informations/informations' })
  },
  methods: {
    ...mapActions('informations', { informationsShow: 'show' }),
    async loadInformations () {
      this.loading = true
      try {
        await this.informationsShow()
        this.info = JSON.parse(JSON.stringify(this.informations))
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    saveInformations () {
      Swal.fire({
        title: 'Уверены, что хотите сохранить изменения ?',
        showDenyButton: true,
        confirmButtonText: 'Сохранить',
        denyButtonText: 'Отменить'
      }).then((result) => {
        if (result.isConfirmed) {
          this.saveInformationsPost()
        } else if (result.isDenied) {
          Swal.fire('Отменено', '', 'info')
        }
      })
    },
    async saveInformationsPost () {
      this.loading = true
      try {
        this.informationPost.informations = this.info
        const result = await InformationsUpdate(this.informationPost)
        Swal.fire('Сохранено!', '', 'success')
      } catch (error) {
        Swal.fire('Ошибка', error, 'error')
        console.log(error)
      } finally {
        this.loading = false
      }
    }
  },
  mounted () {
    this.loadInformations()
  }
})
