<template>
    <v-snackbar
    :timeout="snackbar.timeout"
    :multi-line="snackbar.multiline === true"
    top
    v-model="snackbar.showing">
        {{ snackbar.text }}
        <v-btn text dark @click.native="closeSnackbar">Закрыть</v-btn>
    </v-snackbar>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  computed: {
    ...mapGetters({
      snackbar: 'snackbar/snackbar'
    })
  },
  methods: {
    ...mapActions('snackbar', { closeSnackbar: 'closeSnackbar' })
  }
}
</script>
