import axios, { setAuthorizationHeader } from '@/services/axios'
import { AxiosResponse } from 'axios'

const getSum = 'messages/search'
const getMore = 'messages/search/bymessagetype'

export const getSumInPeriodByType = async (searchObj: any) => {
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(getSum, searchObj)
  return result.data.data
}

export const getMoreInPeriodByType = async (searchObj: any) => {
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(getMore, searchObj)
  return result.data.data
}
