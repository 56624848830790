/* eslint-disable @typescript-eslint/no-unused-vars */

import { ActionContext, ActionTree, GetterTree, MutationTree } from 'vuex'
import { SET_USER, CLEAR_USER, SET_USERPERSON } from '@/store/user/mutations'
import { RootState } from '@/store/state'
import {
  UserState,
  UserShort,
  Person,
  UserTokens,
  JwtDecodeData
} from '@/store/user/types'
import {
  UserLoginPost,
  UserSignupPost,
  UserForgotPost,
  UserResetPost
} from './api-types'
import {
  login as userLogin,
  signup as userSignup,
  forgot as userForgot,
  reset as userReset,
  getPerson as userPersonGet
} from '@/store/user/api-requests'
import { parseAxiosError } from '@/services/api'
import jwtDecode from 'jwt-decode'

type UserContext = ActionContext<UserState, RootState>

const initialState: UserState = {
  user: {
    id: 0,
    tokens: null,
    username: ''
  },
  person: null
}

const getters: GetterTree<UserState, RootState> = {
  user (state: UserState): UserState {
    return state
  }
}

const mutations: MutationTree<UserState> = {
  [SET_USER] (state: UserState, user: UserShort) {
    state.user = user
  },
  [CLEAR_USER] (state: UserState) {
    state.user = initialState.user
    state.person = initialState.person
  },
  [SET_USERPERSON] (state: UserState, person: Person) {
    state.person = person
  }
}

const actions: ActionTree<UserState, RootState> = {
  async getPerson (
    { commit, dispatch, state }: UserContext
  ): Promise<Person> {
    try {
      const result = await userPersonGet()
      const person: Person = result
      commit(SET_USERPERSON, person)
      return result
    } catch (error) {
      return Promise.reject(parseAxiosError(error))
    }
  },
  async login (
    { commit, dispatch, state }: UserContext,
    data: UserLoginPost
  ): Promise<UserTokens> {
    try {
      const result = await userLogin(data)
      const decoded: JwtDecodeData = jwtDecode(result.access_token)
      const user: UserShort = {
        id: decoded.sub,
        // eslint-disable-next-line
        tokens: { access_token: result.access_token, refresh_token: result.refresh_token },
        username: data.username
      }
      commit(SET_USER, user)

      localStorage.setItem('accessToken', result.access_token)
      localStorage.setItem('refreshToken', result.refresh_token)
      return result
    } catch (error) {
      return Promise.reject(parseAxiosError(error))
    }
  },
  async logout ({ commit, dispatch, state }: UserContext): Promise<void> {
    try {
      commit(CLEAR_USER)
      localStorage.removeItem('accessToken')
      localStorage.removeItem('refreshToken')
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(parseAxiosError(error))
    }
  }
  // todo
  /*
  async signup (
    { commit, dispatch, state }: UserContext,
    data: UserSignupPost
  ): Promise<number> {
    try {
      const result = await userSignup(data)
      return result
    } catch (error) {
      return Promise.reject(parseAxiosError(error))
    }
  },
  async forgot (
    { commit, dispatch, state }: UserContext,
    data: UserForgotPost
  ): Promise<string> {
    try {
      const result = await userForgot(data)
      return result
    } catch (error) {
      return Promise.reject(parseAxiosError(error))
    }
  },
  async reset (
    { commit, dispatch, state }: UserContext,
    data: UserResetPost
  ): Promise<void> {
    try {
      const result = await userReset(data)
      return result
    } catch (error) {
      return Promise.reject(parseAxiosError(error))
    }
  }
  */
}

export const user = {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions
}
