

















import Vue from 'vue'
export default Vue.extend({
  data () {
    return {
      headers: [
        { text: 'Дата / Время', align: 'center', filterable: false, value: 'creationDate' },
        { text: 'Назначение', align: 'center', filterable: true, value: 'type' },
        { text: 'Телефон', align: 'center', filterable: false, value: 'phone' }
      ]
    }
  },
  props: {
    data: {
      type: Array,
      required: true
    }
  },
  mounted () {
    //
  },
  computed: {
    //
  }
})
