<template>
  <v-container class="py-4 pt-2">
    <v-container class="py-4 pt-2">
      <v-row class="d-flex justify-space-between">
        <v-col style="max-width:86px">
          <v-btn class="block" @click="back()"><v-icon>mdi-keyboard-backspace</v-icon></v-btn>
        </v-col>
        <v-col>
          <v-btn block class="block" :to="{ name: 'FoodOrderStateNew' }">Новые</v-btn>
        </v-col>
        <v-col>
          <v-btn class="block" :to="{ name: 'FoodOrderStatePrepairing' }">Собираются</v-btn>
        </v-col>
        <v-col v-if="issetReadyForDelivery">
          <v-btn  class="block" :to="{ name: 'FoodOrderStateReadyForDelivery' }">Готов<br>к доставке</v-btn>
        </v-col>
        <v-col>
          <v-btn class="block" :to="{ name: 'FoodOrderStateDelivery' }">Доставка</v-btn>
        </v-col>
      </v-row>
    </v-container>
    <router-view></router-view>
  </v-container>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import { mapSettings } from '@/store/food/settingsHelper'

export default Vue.extend({
  data () {
    return {
      issetReadyForDelivery: false,
      settings: []
    }
  },
  methods: {
    back () {
      this.$router.push({ name: 'FoodEnter' })
    },
    ...mapActions('foodSettings', { getSettingsStore: 'show' }),
    async getSettings () {
      let result
      try {
        result = await this.getSettingsStore()
      } catch (error) {
        console.log(error)
      } finally {
        this.settings = mapSettings(result)
        this.issetReadyForDelivery = this.settings.prepairForDeliveryTabVisibility
      }
    }
  },
  mounted () {
    this.getSettings()
  },
  computed: {
    ...mapGetters({ user: 'user/user' })
  }
})
</script>

<style scoped lang="scss">
  .block{
    background: #e4e4e4!important;
    text-transform: uppercase!important;
    display: flex!important;
    justify-content: center!important;
    align-items: center!important;
    // width: 24%!important;
    font-size: 20px;
    height:80px!important;
    text-align:center;
    @media (max-width: 980px) {
      font-size: 20px;
      height:80px!important;
    }
    @media (max-width: 680px) {
      font-size: 16px;
      height:60px!important;
    }
    @media (max-width: 610px) {
      font-size: 14px;
      height:40px!important;
    }
  }
</style>
