





































import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import LoadingBar from '@/components/partials/components/LoadingBar.vue'
import { getOrderFoodDeliverySettingApi, setOrderFoodDeliverySettingApi } from '@/store/food/api-requests'

type allData = {
  loading: boolean;
  deliveryValue: boolean;
  deliveryValueLoaded: boolean;
  showDeliverySetting: boolean;
}

export default Vue.extend({
  components: {
    LoadingBar
  },
  data () {
    return {
      loading: false,
      deliveryValue: true,
      deliveryValueLoaded: false,
      showDeliverySetting: true
    }
  },
  methods: {
    async getDeliverySetting () {
      let result
      try {
        result = await getOrderFoodDeliverySettingApi()
      } catch (error) {
        console.log(error)
      } finally {
        if (result === false) {
          this.showDeliverySetting = false
        } else {
          result.forEach((el: any) => {
            if (el.deliveryValue) {
              this.deliveryValue = Boolean(el.deliveryValue)
            }
          })
          this.deliveryValue = !this.deliveryValue
        }
        this.deliveryValueLoaded = true
      }
    },
    changeDeliveryValue () {
      this.deliveryValue = !this.deliveryValue
      this.setDeliverySetting()
    },
    async setDeliverySetting () {
      let result
      try {
        result = await setOrderFoodDeliverySettingApi({ deliveryvalue: Number(this.deliveryValue) })
      } catch (error) {
        console.log(error)
      }
    }
  },
  computed: {
    ...mapGetters({ user: 'user/user' })
  },
  mounted () {
    this.getDeliverySetting()
  }
})
