/* eslint-disable @typescript-eslint/no-unused-vars */

import { ActionContext, ActionTree, GetterTree, MutationTree } from 'vuex'
import { SET_SNACKBAR, CLOSE_SNACKBAR } from '@/store/snackbar/mutations'
import { RootState } from '@/store/state'
import {
  SnackbarState,
  Snackbar
} from '@/store/snackbar/types'

type SnackbarContext = ActionContext<SnackbarState, RootState>

const initialState: SnackbarState = {
  snackbar: {
    showing: false,
    color: 'red',
    timeout: 6000,
    text: null,
    multiline: false
  }
}

const getters: GetterTree<SnackbarState, RootState> = {
  snackbar (state: SnackbarState): Snackbar {
    return state.snackbar
  }
}

const mutations: MutationTree<SnackbarState> = {
  [SET_SNACKBAR] (state: SnackbarState, snackbar: Snackbar) {
    state.snackbar = snackbar
  },
  [CLOSE_SNACKBAR] (state: SnackbarState) {
    state.snackbar.showing = false
  }
}

const actions: ActionTree<SnackbarState, RootState> = {
  setSnackbar (
    { commit }: SnackbarContext,
    snackbar: Snackbar
  ) {
    snackbar.showing = true
    snackbar.color = snackbar.color || 'success'
    snackbar.timeout = snackbar.timeout || 0
    commit('SET_SNACKBAR', snackbar)
  },
  closeSnackbar (
    { commit }: SnackbarContext,
    snackbar: Snackbar
  ) {
    snackbar.showing = false
    snackbar.color = ''
    snackbar.timeout = 0
    commit('CLOSE_SNACKBAR', snackbar)
  }
}

export const snackbar = {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions
}
