/* eslint-disable @typescript-eslint/no-unused-vars */

import { ActionContext, ActionTree, GetterTree, MutationTree } from 'vuex'
import { ADD_INFORMATIONS_TO_STACK } from './mutations'
import { RootState } from '@/store/state'
import { Information, InformationsState } from './types'
import { show as InformationShow } from './api-requests'
import { parseAxiosError } from '@/services/api'

type InformationContext = ActionContext<InformationsState, RootState>

const initialState: InformationsState = {
  informations: []
}

const getters: GetterTree<InformationsState, RootState> = {
  informations (state: InformationsState, rootState: RootState): Information[] {
    return state.informations
  }
}

const mutations: MutationTree<InformationsState> = {
  [ADD_INFORMATIONS_TO_STACK] (state: InformationsState, informationResponse: Information[]) {
    state.informations = informationResponse
  }
}

const actions: ActionTree<InformationsState, RootState> = {
  async show ({ commit, dispatch, state }: InformationContext): Promise<Information[]> {
    try {
      const result = await InformationShow()
      commit(ADD_INFORMATIONS_TO_STACK, result)
      return result
    } catch (error) {
      return Promise.reject(parseAxiosError(error))
    }
  }
}

export const informations = {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions
}
