import axios, { setAuthorizationHeader } from '@/services/axios'
import { AxiosResponse } from 'axios'
import { Category } from './types'

const сategoryShow = 'categories'

export const show = async (): Promise<Category[]> => {
  setAuthorizationHeader(axios)
  const result: AxiosResponse<{
    data: Category[];
  }> = await axios.post(сategoryShow)
  return result.data.data
}
