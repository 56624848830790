<template>
  <v-container class="py-4">
      <LoadingBar :loading="loading"></LoadingBar>
      <h1 class="text-center my-4">Шаблоны дисконта</h1>
      <v-row>
        <v-col>
          <v-btn @click="openModal(null)">Новый шаблон</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            :v-model="selectedRows"
            :headers="templatesHeader"
            :items="templates"
            :items-per-page="10"
            class="elevation-1"
            item-key="id"
          >
            <template v-slot:[`item`]="{ item }">
              <tr
                @click="selectTemplate(item.id)"
              >
                <td class="text-xs-right">{{ item.name }}</td>
                <td class="text-xs-right">{{ item.type }}</td>
                <td class="text-xs-right">
                  <v-btn @click="openModal(item)">Изменить</v-btn>
                  <v-btn class="ml-4" @click="deleteOneItem(item.id)">x</v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

<!-- MODAL -->
    <v-dialog
      v-model="toggleModal"
      max-width="800px"
      persistent
    >
      <v-card>
        <v-card-title>
          {{ objectForApi.id ? 'Редактировать шаблон' : 'Новый шаблон'}}
        </v-card-title>
        <v-container class="p-5">
          <v-row>
            <div class="col-12">
              <v-text-field v-model="objectForApi.name" label="Название шаблона"></v-text-field>
            </div>
            <div class="col-6">
              <div class="d-inline-flex align-center">
                Вид:
                <v-radio-group v-model="objectForApi.type" row class="ml-5">
                  <v-radio label="%" :value="1"></v-radio>
                  <v-radio label="По кол-ву" :value="2"></v-radio>
                </v-radio-group>
              </div>
            </div>
            <div class="col-6" align="right">
              <div class="d-inline-flex align-center">
                <v-select
                  v-model="objectForApi.applyTo"
                  :items="applyToVariants"
                  style="width: 120px;margin-left:20px"
                  item-value="id"
                  item-text="name"
                  :value="0"
                >
                </v-select>
              </div>
            </div>
          </v-row>
          <v-row v-if="toggleModal">
            <v-col>
              <DiscountTemplateItems
              :id="objectForApi.id"
              :items="objectForApi.templateItems"
              :sendFatherUpdatedItems="handleUpdateItems"
              ></DiscountTemplateItems>
            </v-col>
          </v-row>
        </v-container>

        <v-card-actions style="justify-content: flex-end;">
          <v-btn
            color="primary"
            @click="beforeSaveValidate()"
          >
            Сохранить изменения
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="closeModal()"
          >
            Отмена
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
<!-- MODAL -->

  </v-container>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import LoadingBar from '@/components/partials/components/LoadingBar.vue'
import DiscountTemplateItems from '@/components/settings/DiscountTemplateItems.vue'
import { show, update, deleteItem, create, getTemplateByIdApi } from '@/store/discountTemplates/api-requests'
import Swal from 'sweetalert2'
export default Vue.extend({
  components: {
    DiscountTemplateItems,
    LoadingBar
  },
  data () {
    return {
      templatesHeader: [
        { text: 'Название', value: 'name' },
        { text: 'Вид', value: 'type' },
        { text: 'Действия', value: 'id' }
      ],
      templates: [],
      toggleModal: false,
      selectedRows: [],
      objectForApi: {
        id: null,
        name: null,
        type: null,
        applyTo: null,
        templateItems: []
      },
      initialObjectForApi: { templateItems: [] },
      typeVariants: [
        { id: 1, name: 'Процент' },
        { id: 2, name: 'По количеству' }
      ],
      applyToVariants: [
        { id: 0, name: 'ко всем' },
        { id: 1, name: 'ко всем кроме первого' },
        { id: 2, name: 'отдельно по диапазону' }
      ],
      loading: false
    }
  },
  methods: {
    async openModal (item) {
      if (item) {
        let result
        try {
          result = await getTemplateByIdApi({ id: item.id })
        } catch (error) {
          console.log(error)
        } finally {
          this.objectForApi = Object.assign({}, result[0])
        }
      } else {
        this.objectForApi.templateItems = []
        this.objectForApi = Object.assign({}, this.initialObjectForApi)
      }

      this.toggleModal = true
    },
    closeModal () {
      this.toggleModal = false
      this.objectForApi = Object.assign({}, this.initialObjectForApi)
    },
    selectTemplate (id) {
      this.toggleSelection(id)
    },
    toggleSelection (itemId) {
      if (this.selectedRows.includes(itemId)) {
        this.selectedRows = this.selectedRows.filter(
          selectedItemId => selectedItemId !== itemId
        )
      } else {
        this.selectedRows.push(itemId)
      }
      // console.log(this.selectedRows)
    },
    async deleteOneItem (id) {
      let result
      try {
        result = await deleteItem({ id: id, removed: 1 })
      } catch (error) {
        if (error.response) {
          Swal.fire('ОШИБКА', error.response.data.errorMessage, 'error')
        }
      } finally {
        if (result) {
          this.templates = this.templates.filter(
            item => item.id !== id
          )
        }
      }
    },
    beforeSaveValidateItems (items) {
      let danger = false
      if (items.length) {
        const issetUndeleted = items.find(x => x.removed === null)
        if (!issetUndeleted) {
          danger = true
        }
      } else {
        danger = true
      }
      return danger
    },
    beforeSaveValidate () {
      let dangerState = false
      const dangerTexts = []
      if (!this.objectForApi.name || !this.objectForApi.type || !this.objectForApi.applyTo) {
        dangerState = true
        dangerTexts.push('Заполните все поля<br>')
      }

      if (this.beforeSaveValidateItems(this.objectForApi.templateItems)) {
        dangerState = true
        dangerTexts.push('Добавьте скидки')
      }

      if (this.objectForApi.type === 1) {
        const issetOverPercent = this.objectForApi.templateItems.find(el => el.price > 100)
        if (issetOverPercent) {
          dangerState = true
          dangerTexts.push('Скидка не может быть больше 100%')
        }
      }

      if (dangerState) {
        Swal.fire({
          title: 'Ошибка!',
          html: dangerTexts.join(''),
          confirmButtonText: 'Ок'
        })
      } else {
        this.saveItem()
      }
    },
    async saveItem () {
      this.loading = true
      this.toggleModal = false
      let result
      if (!this.objectForApi.id) {
        // create api
        try {
          result = await create(this.objectForApi)
        } catch (error) {
          console.log(error)
        } finally {
          if (result) {
            this.getDiscountTemplates()
            this.objectForApi = Object.assign({}, this.initialObjectForApi)
          }
        }
      } else {
        // update api
        try {
          result = await update(this.objectForApi)
        } catch (error) {
          console.log(error)
        } finally {
          if (result) {
            this.templates = this.templates.map(el => {
              if (el.id === this.objectForApi.id) {
                el = Object.assign({}, this.objectForApi)
              }
              return el
            })
            this.objectForApi = Object.assign({}, this.initialObjectForApi)
            this.loading = false
          }
        }
        // update api END
      }
    },
    handleUpdateItems (itemsFromChild) {
      const obj = this.objectForApi
      obj.templateItems = itemsFromChild
      this.objectForApi = Object.assign({}, obj)
    },
    async getDiscountTemplates () {
      let result
      try {
        result = await show()
      } catch (error) {
        console.log(error)
      } finally {
        if (result) {
          const allItems = JSON.parse(JSON.stringify(result))
          this.templates = allItems
        } else {
          this.templates = []
        }
        this.loading = false
      }
    }
  },
  mounted () {
    this.initialObjectForApi = Object.assign({}, this.objectForApi)
    this.getDiscountTemplates()
  },
  computed: {
    ...mapGetters({ user: 'user/user' })
  }
})
</script>
