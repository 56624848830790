<template>
  <v-container class="py-4">
      <LoadingBar :loading="loading"></LoadingBar>
      <h1 class="text-center my-4">Настройки раздела Еда/Заказы</h1>
      <v-row>
        <v-col>
          <span class="font-weight-bold font-h3">Автообновление заказов через </span>
          <span
            class="font-weight-bold font-h3"
            v-text="refreshTime.min"
          ></span>
          <span class="font-weight-bold font-h3"> мин. </span>
          <span
            class="font-weight-bold font-h3"
            v-text="refreshTime.sec"
          ></span>
          <span class="font-weight-bold font-h3"> сек. </span>
          <br><span>Минуты:</span>
          <v-slider
          hide-details
          v-model="refreshTime.min"
          min="0"
          max="10"
          step="1"
          ticks
          >
          <template v-slot:prepend>
              <v-icon
              @click="refreshTime.min--"
              >
              mdi-minus
              </v-icon>
          </template>

          <template v-slot:append>
              <v-icon
              @click="refreshTime.min++"
              >
              mdi-plus
              </v-icon>
          </template>
          </v-slider>
          <span>Секунды:</span>
          <v-slider
          hide-details
          v-model="refreshTime.sec"
          min="0"
          max="59"
          step="1"
          ticks
          >
          <template v-slot:prepend>
              <v-icon
              @click="refreshTime.sec--"
              >
              mdi-minus
              </v-icon>
          </template>

          <template v-slot:append>
              <v-icon
              @click="refreshTime.sec++"
              >
              mdi-plus
              </v-icon>
          </template>
          </v-slider>
        </v-col>
        <v-col>
           <!-- fe  -->
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <span class="font-weight-bold font-h3">Цвета заказов в зависимости от времени</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            :headers="headers"
            :items="timeColors"
            :items-per-page="5"
            class="elevation-1"
            hide-default-footer
          >
            <template v-slot:[`item.value`]="{item}">
              <v-select
                hide-details
                :items="numbers"
                v-model="item.value"
                item-text="title"
                item-value="value"
              ></v-select>
            </template>
            <template v-slot:[`item.value2`]="{item}">
              <v-select
                hide-details
                :items="numbers"
                v-model="item.value2"
                item-text="title"
                item-value="value"
              ></v-select>
            </template>
            <template v-slot:[`item.value3`]="{item}">
              <v-select
                hide-details
                :items="colors"
                v-model="item.value3"
                item-text="title"
                item-value="value"
              ></v-select>
            </template>
          </v-data-table>
        </v-col>
        <v-col></v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-checkbox class="font-weight-bold font-h3"
            v-model="prepairForDeliveryTabVisibility"
            label='Отображать вкладку "Готово к доставке"'
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn @click="saveSettings()">Сохранить</v-btn>
        </v-col>
      </v-row>
  </v-container>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import LoadingBar from '@/components/partials/components/LoadingBar.vue'
import Swal from 'sweetalert2'
export default Vue.extend({
  components: {
    LoadingBar
  },
  data () {
    return {
      numbers: [],
      refreshTime: {
        min: 1,
        sec: 0
      },
      timeColors: [],
      prepairForDeliveryTabVisibility: false,
      settingsToSave: [],
      loading: false,
      headers: [
        { text: 'От (мин)', value: 'value', sortable: false, width: '200px' },
        { text: 'До (мин)', value: 'value2', sortable: false, width: '200px' },
        { text: 'Цвет', value: 'value3', sortable: false, width: '200px' }
      ],
      colors: [
        { value: 1, title: 'Красный' },
        { value: 2, title: 'Синий' },
        { value: 3, title: 'Зеленый' }
      ]
    }
  },
  methods: {
    ...mapActions('foodSettings', { getSettingsStore: 'show' }),
    ...mapActions('foodSettings', { saveSettingsStore: 'save' }),
    /**
     * settingType
     * value - обновление страницы
     * value2 - цвета
     * value3 - отображение вкладки
     */
    async getSettings () {
      let result
      try {
        result = await this.getSettingsStore()
      } catch (error) {
        console.log(error)
      } finally {
        this.settingsToSave = JSON.parse(JSON.stringify(result))
        this.settingsToSave.forEach(el => {
          if (el.settingType === 1) {
            this.refreshTime.min = el.value
            this.refreshTime.sec = el.value2
          }
          if (el.settingType === 2) {
            this.timeColors.push(el)
          }
          if (el.settingType === 3) {
            this.prepairForDeliveryTabVisibility = Boolean(el.value)
          }
        })
      }
    },
    async saveSettings () {
      this.settingsToSave.map(el => {
        if (el.settingType === 1) {
          el.value = this.refreshTime.min
          el.value2 = this.refreshTime.sec
        }
        if (el.settingType === 2) {
          const timeColorSetting = this.timeColors.find(tc => tc.id === el.id)
          el.value = timeColorSetting.value
          el.value2 = timeColorSetting.value2
          el.value3 = timeColorSetting.value3
        }
        if (el.settingType === 3) {
          el.value = Number(this.prepairForDeliveryTabVisibility)
        }
        return el
      })
      let result
      try {
        result = await this.saveSettingsStore(this.settingsToSave)
      } catch (error) {
        console.log(error)
      }
    }
  },
  mounted () {
    this.getSettings()
    for (let i = 0; i < 60; i++) {
      if (i === 0) {
        this.numbers.push({ value: i, title: 'Нет' })
      } else {
        this.numbers.push({ value: i, title: i })
      }
    }
  },
  computed: {
    ...mapGetters({ user: 'user/user' }),
    ...mapGetters({ foodSettings: 'foodSettings/foodSettings' })
  }
})
</script>
