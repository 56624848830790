










import Vue from 'vue'
import LoginForm from '@/components/user/forms/LoginForm.vue'
export default Vue.extend({
  components: {
    LoginForm
  }
})
