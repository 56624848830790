/* eslint-disable @typescript-eslint/no-unused-vars */

import { ActionContext, ActionTree, GetterTree, MutationTree } from 'vuex'
import { ADD_CATEGORY_TO_STACK } from './mutations'
import { RootState } from '@/store/state'
import { Category, AfficheCategoryState } from './types'
import { show as InformationShow } from './api-requests'
import { parseAxiosError } from '@/services/api'

type InformationContext = ActionContext<AfficheCategoryState, RootState>

const initialState: AfficheCategoryState = {
  categories: []
}

const getters: GetterTree<AfficheCategoryState, RootState> = {
  categories (state: AfficheCategoryState, rootState: RootState): Category[] {
    return state.categories
  }
}

const mutations: MutationTree<AfficheCategoryState> = {
  [ADD_CATEGORY_TO_STACK] (state: AfficheCategoryState, informationResponse: Category[]) {
    state.categories = informationResponse
  }
}

const actions: ActionTree<AfficheCategoryState, RootState> = {
  async show ({ commit, dispatch, state }: InformationContext): Promise<Category[]> {
    try {
      const result = await InformationShow()
      commit(ADD_CATEGORY_TO_STACK, result)
      return result
    } catch (error) {
      return Promise.reject(parseAxiosError(error))
    }
  }
}

export const categories = {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions
}
