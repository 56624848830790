<template>
  <v-container class="py-0">
    <LoadingBar :loading="loading"></LoadingBar>
    <div id="topActions">
      <v-row>
        <v-col>
          <v-btn :class="filterDelivery === false ? 'active' : ''" large @click="changeFilter(false)" >Заберет сам</v-btn>
          <v-btn :class="filterDelivery === true ? 'active ml-4' : 'ml-4'" large @click="changeFilter(true)">Доставка</v-btn>
        </v-col>
        <v-col style="text-align:right">
          <v-btn large @click="clickDelivered()" style="background: #9dec9d">Доставлено</v-btn>
          <v-btn v-if="filterDelivery === true"  class="ml-4" large @click="clickNotDelivered()">Доставить не удалось</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <CardsContainer
          v-if="settings.length !== []"
          :half="false"
          :componentId="'deli_self1_'"
          :itemsContent="'delivery'"
          :maxCardRows="5"
          :inputTimeColors="settings.timeColors"
          :data="filteredItems"
          :itemAction="'changeSelected'"
          @changeFromChild="changeValue($event)">
        </CardsContainer>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import LoadingBar from '@/components/partials/components/LoadingBar.vue'
import CardsContainer from '@/components/food/foodOrders/CardsContainer.vue'
import { getFoodOrdersByPeriodAndStatusApi, updateFoodOrderStatusUrlApi } from '@/store/orders/api-requests'
import { mapSettings } from '@/store/food/settingsHelper'

export default Vue.extend({
  components: {
    LoadingBar,
    CardsContainer
  },
  data () {
    return {
      tab: null,
      loading: false,
      data1: [],
      data2: [],
      selected: [],
      data: [],
      dateFrom: new Date().getFullYear() + '-01-01',
      dateTo: new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().substr(0, 10),
      settings: [],
      settings2: [],
      filterDelivery: true,
      filteredItems: [],
      refreshIntervalId: null
    }
  },
  methods: {
    changeFilter (value) {
      this.filterDelivery = value
      this.filterAll()
    },
    filterAll () {
      const filteredItems = JSON.parse(JSON.stringify(this.data1))
      this.filteredItems = filteredItems.filter((el) => el.delivery === this.filterDelivery)
    },
    init (val) {
      val.forEach(el => {
        this.data1.push(el)
      })
      this.filterAll()
    },
    async getData () {
      if (this.$route.name !== 'FoodOrderStateDelivery') {
        clearInterval(this.refreshIntervalId)
        return false
      }
      // console.log('get data delivery')
      this.loading = true
      let result
      try {
        result = await getFoodOrdersByPeriodAndStatusApi({ dateFrom: this.dateFrom, dateTo: this.dateTo, status: 5 })
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
        if (result) {
          this.selected = []
          this.data = []
          this.data1 = []
          this.orders = []
          const orders = JSON.parse(JSON.stringify(result))
          orders.map(order => {
            if (order.orderProducts.length) {
              order.data = order.orderProducts.map(el => ({ name: el.product.name, quantity: (+el.quantity) }))
            }
            return order
          })
          this.data = orders
          this.data.map(el => {
            el.data = el.data.filter(subEl => Number(subEl.quantity) !== 0)
            el.isSelected = false
            el.footer = el.phone ? el.phone : 'Нет тел. номера'
            if (el.orderDetail.length > 0) {
              if (Number(el.orderDetail[0].deliveryTypeId) === 1) {
                el.subtitle = el.orderDetail[0].deliveryPlace
                el.delivery = true
              } else {
                el.subtitle = 'Заберет сам '
                el.delivery = false
              }
            } else {
              el.subtitle = 'Заберет сам '
              el.delivery = false
            }
            if (el.status === 8) {
              el.subtitle = 'Заберет сам '
              el.delivery = false
            }
            return el
          })
          this.data = this.data.filter(el => Number(el.sum) !== 0)
          this.init(this.data)
        }
      }
    },
    clickNotDelivered () {
      const dataForApi = this.selected.map(el => {
        el = { id: el, status: 8 }
        return el
      })
      this.selected.forEach(el => {
        this.data1 = this.data1.map(el2 => {
          if (el2.id === el) {
            el2.status = 8
            el2.delivery = false
            el2.isSelected = false
          }
          return el2
        })
      })
      let result
      try {
        result = updateFoodOrderStatusUrlApi({ orders: dataForApi })
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
      this.selected = []
      this.filterAll()
    },
    clickDelivered () {
      const dataForApi = this.selected.map(el => {
        el = { id: el, status: 7 }
        return el
      })
      // this.data1 = this.data1.map(el2 => {
      //   el2.isSelected = false
      //   return el2
      // })
      this.selected.forEach(el => {
        this.data1 = this.data1.filter(el2 => el2.id !== el)
        this.filteredItems = this.filteredItems.filter(el2 => el2.id !== el)
      })
      this.selected = []
      let result
      try {
        result = updateFoodOrderStatusUrlApi({ orders: dataForApi })
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    changeValue (evnt) {
      if (evnt.action === 'changeSelected') {
        this.changeSelected(evnt.val)
      }
    },
    changeSelected (id) {
      const index = this.selected.indexOf(id)
      if (index > -1) {
        this.selected.splice(index, 1)
      } else {
        this.selected.push(id)
      }

      this.data1 = this.data1.map(el => {
        if (el.id === id) {
          el.isSelected = !el.isSelected
        }
        return el
      })
      this.filterAll()
    },
    ...mapActions('foodSettings', { getSettingsStore: 'show' }),
    async getSettings () {
      let result
      try {
        result = await this.getSettingsStore()
      } catch (error) {
        console.log(error)
      } finally {
        if (result) {
          this.settings = mapSettings(result)
          this.getData()
          this.refreshIntervalId = setInterval(() => this.getData(), Number(this.settings.refreshTime))
        }
      }
    }
  },
  mounted () {
    this.getSettings()
  },
  destroyed () {
    clearInterval(this.refreshIntervalId)
  },
  computed: {
    ...mapGetters({ user: 'user/user' })
  }
})
</script>
<style scoped>
.v-btn.active{
  background: #cacaca!important;
}
</style>
