var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"py-4"},[_c('h1',{staticClass:"text-center my-4"},[_vm._v("Отчет об уведомлениях")]),_c('v-row',{staticClass:"justify-content-center"},[_c('v-col',{staticStyle:{"flex-wrap":"nowrap","display":"flex"}},[_c('div',{staticStyle:{"padding-top":"20px","margin-right":"10px"}},[_vm._v("Тип:")]),_c('v-select',{attrs:{"items":_vm.messagesTypes,"item-value":"id","item-text":"name"},on:{"change":_vm.selectMessagesType},model:{value:(_vm.filter.messageType),callback:function ($$v) {_vm.$set(_vm.filter, "messageType", $$v)},expression:"filter.messageType"}})],1),_c('v-col',{staticStyle:{"flex-wrap":"nowrap","display":"flex"}},[_c('div',{staticStyle:{"padding-top":"20px","margin-right":"10px"}},[_vm._v("C: ")]),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"reverse":"","readonly":""},model:{value:(_vm.filter.dateFrom),callback:function ($$v) {_vm.$set(_vm.filter, "dateFrom", $$v)},expression:"filter.dateFrom"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuDateFrom),callback:function ($$v) {_vm.menuDateFrom=$$v},expression:"menuDateFrom"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menuDateFrom = false}},model:{value:(_vm.filter.dateFrom),callback:function ($$v) {_vm.$set(_vm.filter, "dateFrom", $$v)},expression:"filter.dateFrom"}})],1)],1),_c('v-col',{staticStyle:{"flex-wrap":"nowrap","display":"flex"}},[_c('div',{staticStyle:{"padding-top":"20px","margin-right":"10px"}},[_vm._v("По:")]),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"reverse":"","readonly":""},model:{value:(_vm.filter.dateTo),callback:function ($$v) {_vm.$set(_vm.filter, "dateTo", $$v)},expression:"filter.dateTo"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuDateTo),callback:function ($$v) {_vm.menuDateTo=$$v},expression:"menuDateTo"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menuDateTo = false}},model:{value:(_vm.filter.dateTo),callback:function ($$v) {_vm.$set(_vm.filter, "dateTo", $$v)},expression:"filter.dateTo"}})],1)],1),_c('v-col',{staticStyle:{"display":"flex","justify-content":"flex-end","align-items":"center"}},[_c('v-btn',{on:{"click":function($event){return _vm.show()}}},[_vm._v(" Показать ")])],1)],1),(_vm.messagesFiltered.length)?_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.messagesHeaders,"items":_vm.messagesFiltered,"item-key":"id","group-by":"type"},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var items = ref.items;
var isOpen = ref.isOpen;
var toggle = ref.toggle;
return [_c('td',[_c('v-icon',{on:{"click":toggle}},[_vm._v(" "+_vm._s(isOpen ? 'mdi-minus' : 'mdi-plus')+" ")]),_vm._v(" SMS ")],1),_c('td',[_vm._v(_vm._s(_vm.filter.dateFrom))]),_c('td',[_vm._v(_vm._s(_vm.filter.dateTo))]),_c('td',[_vm._v(_vm._s(_vm.computedGroupSum(items)))]),_c('td',[_c('v-btn',{on:{"click":function($event){return _vm.moreInfoGroup()}}},[_vm._v("Подробнее")])],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{on:{"click":function($event){return _vm.moreInfo(item)}}},[_vm._v("Подробнее")])]}},{key:"item.messageType",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.messagesTypes[item.messageType-1].name)+" ")]}}],null,true)})],1)],1):_vm._e(),_c('v-row',[_c('v-col',[(_vm.orderEventsMoreData)?_c('OrderEventsMore',{attrs:{"data":_vm.orderEventsMoreData}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }