import Vue from 'vue'
import Vuetify, { VSnackbar } from 'vuetify/lib'
import VuetifyToast from 'vuetify-toast-snackbar'
import ru from 'vuetify/es5/locale/ru'

Vue.use(Vuetify, {
  components: {
    VSnackbar
  }
})

Vue.use(VuetifyToast)

export default new Vuetify({
  lang: {
    locales: { ru },
    current: 'ru'
  }
})
